var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: { "header-tag": "header", "footer-tag": "footer" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("h4", { staticClass: "d-block" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$route.meta.is_preset
                        ? "Create Preset"
                        : "Create Invoice"
                    ) +
                    "\n        "
                ),
                _c("i", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value:
                        "<p>1. Select a company</p><p>2. Select a project</p><p>3. Select a well</p><p>4. Add your service line items</p><p>5. Assign AFE or Cost Center / Well and codes to each line items (This is optional for vendors)</p>",
                      expression:
                        "\n          '<p>1. Select a company</p><p>2. Select a project</p><p>3. Select a well</p><p>4. Add your service line items</p><p>5. Assign AFE or Cost Center / Well and codes to each line items (This is optional for vendors)</p>'\n        "
                    }
                  ],
                  staticClass: "ml-2 fa fa fa-question-circle"
                })
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        { attrs: { id: "border" } },
        [
          _c("b-col", { staticStyle: { "margin-right": "10px" } }, [
            _c("h4", { staticClass: "mb-3" }, [_vm._v("Billing From :")]),
            _vm.hasVendorData
              ? _c("div", [
                  _c("div", { staticClass: "mt-3 mb-3 d-flex" }, [
                    _vm.vendorAvatar
                      ? _c("img", {
                          staticClass: "companylogo mr-3",
                          attrs: { src: _vm.vendorAvatar }
                        })
                      : _vm._e(),
                    _c("div", [
                      _c("span", [
                        _c("i", { staticClass: "fa fa-building" }),
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.vendorDetails.company_name)
                        )
                      ]),
                      _c("br"),
                      _c("span", [
                        _c("i", { staticClass: "fa fa-user" }),
                        _vm._v(" " + _vm._s(_vm.usersName) + " ")
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c("div", [
                      _c("b", [_vm._v("Address: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.vendorDetails.addressline1) +
                            ",\n                " +
                            _vm._s(_vm.vendorDetails.addressline2)
                        )
                      ])
                    ]),
                    _c("div", [
                      _c("b", [_vm._v(" City " + _vm._s("&") + " Province: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.vendorDetails.city) +
                            ", " +
                            _vm._s(_vm.vendorDetails.province)
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "text-uppercase" }, [
                      _c("b", [_vm._v(" Postal Code: ")]),
                      _vm._v(
                        _vm._s(_vm.vendorDetails.postalcode) + "\n            "
                      )
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("Phone Num: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.phone_num))
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("Fax Num: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.fax_num))
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("GST: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.gst))
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("RCN: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.rcn))
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("Bank Account: ")]),
                      _vm._v(_vm._s(_vm.vendorDetails.bankaccount))
                    ])
                  ])
                ])
              : _c(
                  "div",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    })
                  ],
                  1
                )
          ]),
          _c("b-col", [
            _c("h4", { staticClass: "mb-3" }, [_vm._v("Bill To :")]),
            _vm.hasCompanyData
              ? _c("div", { staticClass: "mt-3 mb-4" }, [
                  _vm.avatar
                    ? _c("img", {
                        staticClass: "companylogo",
                        attrs: { src: _vm.avatar }
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "mb-4 mt-2" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.companyId,
                            expression: "companyId"
                          }
                        ],
                        staticClass: "rt-select w-75",
                        staticStyle: {
                          "border-radius": "5px",
                          "border-color": "lightgrey",
                          height: "38px"
                        },
                        attrs: { disabled: _vm.serviceData.length > 0 },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.companyId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.get_project_managers
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { value: "0", disabled: "" } }, [
                          _vm._v("Select Company")
                        ]),
                        _vm._l(_vm.companyData, function(company) {
                          return _c(
                            "option",
                            { domProps: { value: company.companyid } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(company.cn) +
                                  "\n              "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  !isNaN(Number(_vm.companyId))
                    ? _c("div", [
                        _c("div", [
                          _c("b", [_vm._v("Company Address: ")]),
                          _vm._v(
                            _vm._s(_vm.companyInfo.addressline1) +
                              ",\n              " +
                              _vm._s(_vm.companyInfo.addressline2) +
                              "\n            "
                          )
                        ]),
                        _c("div", [
                          _c("b", [
                            _vm._v(" City " + _vm._s("&") + " Province: ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.companyInfo.city) +
                              ",\n              " +
                              _vm._s(_vm.companyInfo.province) +
                              "\n            "
                          )
                        ]),
                        _c("div", [
                          _c("b", [_vm._v(" Postal code: ")]),
                          _vm._v(" " + _vm._s(_vm.companyInfo.postalcode))
                        ])
                      ])
                    : _vm._e()
                ])
              : _c(
                  "div",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    }),
                    _c("b-skeleton", {
                      attrs: { animation: "throb", width: "55%" }
                    })
                  ],
                  1
                )
          ])
        ],
        1
      ),
      !isNaN(Number(_vm.companyId)) ? _c("hr") : _vm._e(),
      !isNaN(Number(_vm.companyId))
        ? _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("h5", { staticClass: "mb-3" }, [
              _vm._v(
                "\n        Fill " +
                  _vm._s(_vm.$route.meta.is_preset ? "Preset" : "Invoice") +
                  " Details:\n      "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-end",
                staticStyle: { width: "80%" }
              },
              [
                _c("h5", { staticStyle: { "margin-right": "15px" } }, [
                  _vm._v(
                    "\n          Date: " +
                      _vm._s(_vm._f("moment")(new Date(), "MM/DD/YYYY")) +
                      "\n        "
                  )
                ]),
                _vm.projectid && _vm.presetData.length > 0 && false
                  ? _c(
                      "b-button",
                      {
                        staticStyle: { height: "min-content" },
                        attrs: {
                          variant: "success",
                          size: "sm",
                          disabled: _vm.serviceData.length > 0
                        },
                        on: { click: _vm.showImportModal }
                      },
                      [_vm._v("Import Preset")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      !isNaN(Number(_vm.companyId))
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _vm.projectid && !_vm.$route.meta.is_preset
                ? _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: "vwref_data" } }, [
                        _c("b", [_vm._v("Select Approver :")])
                      ]),
                      _c("multiselect", {
                        staticStyle: { "z-index": "50", "margin-top": "-2px" },
                        attrs: {
                          placeholder: "Select Customer",
                          "hide-selected": false,
                          "tag-placeholder": "Select Customer",
                          "custom-label": _vm.customPmLable,
                          "track-by": "email",
                          options: _vm.uniqueWtsData,
                          multiple: true,
                          "close-on-select": true,
                          "clear-on-select": false,
                          "preserve-search": true,
                          taggable: false,
                          label: "email",
                          "preselect-first": false
                        },
                        on: {
                          input: function($event) {
                            return _vm.selectPm()
                          }
                        },
                        model: {
                          value: _vm.invoiceTo,
                          callback: function($$v) {
                            _vm.invoiceTo = $$v
                          },
                          expression: "invoiceTo"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.projectid && _vm.$route.meta.is_preset
                ? _c("b-col", [
                    _c("label", { attrs: { for: "vwref_data" } }, [
                      _c("b", [
                        _vm._v("Preset Template Name :\n            "),
                        _c(
                          "i",
                          {
                            staticStyle: { color: "red", "font-size": "large" }
                          },
                          [_vm._v("*")]
                        )
                      ])
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.it_name,
                          expression: "it_name"
                        }
                      ],
                      staticClass: "form-control",
                      staticStyle: { "margin-top": "6px", height: "39px" },
                      attrs: { type: "text", placeholder: "Fill Preset Name" },
                      domProps: { value: _vm.it_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.it_name = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _c(
                "b-modal",
                {
                  ref: "import-modal",
                  staticClass: "import-modal",
                  attrs: {
                    "hide-footer": "",
                    title: "Import Preset: " + _vm.preset.it_name
                  }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.preset,
                          expression: "preset"
                        }
                      ],
                      staticClass: "rt-select w-100",
                      staticStyle: { "border-radius": "5px" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.preset = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "0", disabled: "" } }, [
                        _vm._v("Select Preset")
                      ]),
                      _vm._l(_vm.presetData, function(preset) {
                        return _c("option", { domProps: { value: preset } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(preset.it_name) +
                              "\n          "
                          )
                        ])
                      })
                    ],
                    2
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { value: "1", "unchecked-value": "0" },
                      model: {
                        value: _vm.changeDates,
                        callback: function($$v) {
                          _vm.changeDates = $$v
                        },
                        expression: "changeDates"
                      }
                    },
                    [
                      _vm._v(
                        "\n          Change All Dates During Import\n        "
                      )
                    ]
                  ),
                  _vm.changeDates == 1
                    ? _c("div", { staticClass: "row my-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("h5", [_vm._v("Start - End")]),
                            _c("v-date-picker", {
                              staticClass: "vc-calendar",
                              attrs: {
                                formats: _vm.formats,
                                mode: "range",
                                "show-caps": ""
                              },
                              model: {
                                value: _vm.preset_date,
                                callback: function($$v) {
                                  _vm.preset_date = $$v
                                },
                                expression: "preset_date"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "success", block: "" },
                      on: { click: _vm.importPreset }
                    },
                    [_vm._v("Import")]
                  )
                ],
                1
              ),
              _vm.companyId
                ? _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: "vwref_data" } }, [
                        _c("b", [
                          _vm._v("Select Cost Centre/Well\n            "),
                          _c(
                            "i",
                            {
                              staticStyle: {
                                color: "red",
                                "font-size": "large"
                              }
                            },
                            [_vm._v("*")]
                          )
                        ])
                      ]),
                      _vm.unassigned.length === 0 && _vm.merged.length === 0
                        ? _c("v-select", {
                            staticClass: "mt-2",
                            attrs: {
                              clearable: false,
                              options: _vm.filter_uwi,
                              label: "uwi_4",
                              filterable: true
                            },
                            on: {
                              input: function($event) {
                                return _vm.selectWell()
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "option",
                                  fn: function(option) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            !option.uwi_2
                                              ? option.uwi
                                              : !option.uwi_3
                                              ? option.uwi_2
                                              : option.uwi_3
                                          ) +
                                            "-" +
                                            _vm._s(option.projectname)
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "selected-option",
                                  fn: function(ref) {
                                    var uwi = ref.uwi
                                    var uwi_2 = ref.uwi_2
                                    var uwi_3 = ref.uwi_3
                                    var projectname = ref.projectname
                                    var option = ref.option
                                    var deselect = ref.deselect
                                    var multiple = ref.multiple
                                    var disabled = ref.disabled
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            !uwi_2
                                              ? uwi
                                              : !uwi_3
                                              ? uwi_2
                                              : uwi_3
                                          ) +
                                            "-" +
                                            _vm._s(projectname)
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4241005680
                            ),
                            model: {
                              value: _vm.vwref,
                              callback: function($$v) {
                                _vm.vwref = $$v
                              },
                              expression: "vwref"
                            }
                          })
                        : _c("input", {
                            staticClass: "form-control",
                            staticStyle: {
                              "margin-top": "-3px",
                              height: "41px"
                            },
                            attrs: {
                              type: "text",
                              placeholder: "Cost Centre/Well",
                              readonly: ""
                            },
                            domProps: { value: _vm.vwref.uwi }
                          })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !isNaN(Number(_vm.companyId))
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-3",
                      attrs: { variant: "success", show: "" }
                    },
                    [
                      _c("b", [_vm._v("(Site/Area/Project):")]),
                      _vm._v(" " + _vm._s(_vm.projectname))
                    ]
                  ),
                  _vm.projectHasPm.length > 0
                    ? _c(
                        "b-alert",
                        {
                          staticClass: "mb-3",
                          attrs: { variant: "danger", show: "" }
                        },
                        [
                          _c("b", [
                            _vm._v(
                              " Warning: Below Customers not assigned to a selected project. "
                            )
                          ]),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.projectHasPm.join(", ")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !isNaN(Number(_vm.companyId)) && !_vm.$route.meta.is_preset
        ? _c(
            "b-row",
            [
              _c("b-col", { staticClass: "form-group col-md-6 col-lg-4" }, [
                _c(
                  "label",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { for: "service-order-number" }
                  },
                  [_vm._v("Service Order Number")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceOrderNumber,
                      expression: "serviceOrderNumber"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Enter Service Order Number",
                    id: "service-order-number"
                  },
                  domProps: { value: _vm.serviceOrderNumber },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.serviceOrderNumber = $event.target.value
                    }
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.serviceOrderNumber.length > 45,
                        expression: "serviceOrderNumber.length > 45"
                      }
                    ],
                    staticClass: "help is-danger mt-4"
                  },
                  [
                    _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                    _vm._v("Max character is 45.")
                  ]
                )
              ]),
              _c("b-col", { staticClass: "form-group col-md-6 col-lg-4" }, [
                _c(
                  "label",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { for: "program-number" }
                  },
                  [_vm._v("Program Number")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.programNumber,
                      expression: "programNumber"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Enter Program Number",
                    id: "program-number"
                  },
                  domProps: { value: _vm.programNumber },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.programNumber = $event.target.value
                    }
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.programNumber.length > 45,
                        expression: "programNumber.length > 45"
                      }
                    ],
                    staticClass: "help is-danger mt-4"
                  },
                  [
                    _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                    _vm._v("Max character is 45.")
                  ]
                )
              ]),
              _c(
                "b-col",
                { staticClass: "form-group col-md-6 col-lg-4" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-bold",
                      attrs: { for: "vendor-invoice-date" }
                    },
                    [_vm._v("Vendor Invoice Date")]
                  ),
                  _c("v-date-picker", {
                    staticStyle: { "z-index": "100" },
                    attrs: {
                      id: "vendor-invoice-date",
                      placeholder: "Click to choose",
                      "input-props": {
                        class: "form-control",
                        placeholder: "Choose a date or leave blank"
                      },
                      "available-dates": { start: null, end: new Date() },
                      "show-caps": ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.CheckDates()
                      }
                    },
                    model: {
                      value: _vm.vendorInvoiceDate,
                      callback: function($$v) {
                        _vm.vendorInvoiceDate = $$v
                      },
                      expression: "vendorInvoiceDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !isNaN(Number(_vm.companyId)) && !_vm.$route.meta.is_preset
        ? _c(
            "b-row",
            [
              _c("b-col", { staticClass: "form-group col-6" }, [
                _c(
                  "label",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { for: "vendor-invoice-number" }
                  },
                  [
                    _vm._v("Vendor Invoice Number "),
                    _c(
                      "i",
                      { staticStyle: { color: "red", "font-size": "large" } },
                      [_vm._v(" *")]
                    )
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.vendorInvoiceNumber,
                      expression: "vendorInvoiceNumber"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Enter Vendor Invoice Number",
                    id: "vendor-invoice-number"
                  },
                  domProps: { value: _vm.vendorInvoiceNumber },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.vendorInvoiceNumber = $event.target.value
                    }
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.vendorInvoiceNumber.length > 20,
                        expression: "vendorInvoiceNumber.length > 20"
                      }
                    ],
                    staticClass: "help is-danger mt-4"
                  },
                  [
                    _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                    _vm._v("Max character is 20.")
                  ]
                )
              ]),
              _c("b-col", { staticClass: "form-group col-6" }, [
                _c(
                  "label",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { for: "treatment-type" }
                  },
                  [_vm._v("Treatment Type")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.treatmentType,
                      expression: "treatmentType"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "Enter Treatment Type",
                    id: "treatment-type"
                  },
                  domProps: { value: _vm.treatmentType },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.treatmentType = $event.target.value
                    }
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.treatmentType.length > 45,
                        expression: "treatmentType.length > 45"
                      }
                    ],
                    staticClass: "help is-danger mt-4"
                  },
                  [
                    _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                    _vm._v("Max character is 45.")
                  ]
                )
              ]),
              _c(
                "b-col",
                { staticClass: "form-group col-12" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "font-weight-bold",
                      attrs: { for: "field-ticket-number" }
                    },
                    [_vm._v("Field Ticket Number")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "lightgray",
                        height: "83px",
                        "border-radius": "5px",
                        "overflow-y": "auto"
                      }
                    },
                    _vm._l(_vm.fieldTicketNumber.split(","), function(val) {
                      return _vm.fieldTicketNumber.length > 0
                        ? _c(
                            "span",
                            {
                              staticStyle: { "margin-left": "5px" },
                              style: {
                                color: _vm.existedTickets.includes(val)
                                  ? "red"
                                  : "black"
                              }
                            },
                            [_vm._v(_vm._s(val) + "\n          ")]
                          )
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                padding: "10px",
                                color: "grey",
                                display: "block"
                              }
                            },
                            [_vm._v("Add Field Ticket Numbers")]
                          )
                    }),
                    0
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "help is-danger mt-4",
                      style: {
                        display:
                          _vm.fieldTicketNumber.length > 300 ? "block" : "none"
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                      _vm._v("Max character is 300.")
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.addFieldTicket",
                          modifiers: { addFieldTicket: true }
                        }
                      ],
                      staticClass: "mt-3",
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function($event) {
                          return _vm.addDeafultFieldTicketLines()
                        }
                      }
                    },
                    [_vm._v("Add Field Ticket Numbers +")]
                  ),
                  _vm.fieldTicketNumber.length > 0
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.addFieldTicket",
                              modifiers: { addFieldTicket: true }
                            }
                          ],
                          staticClass: "mt-3 ml-3",
                          attrs: { size: "sm", variant: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.EditFieldTickets()
                            }
                          }
                        },
                        [_vm._v("Edit Field Ticket Numbers\n        ")]
                      )
                    : _vm._e(),
                  _c(
                    "b-modal",
                    {
                      ref: "field-ticket-modal",
                      staticClass: "field-ticket-modal",
                      attrs: {
                        title: "Duplicate Field Ticket Number",
                        "ok-only": ""
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Please remove duplicate field ticket numbers and resubmit."
                        )
                      ])
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "addFieldTicket",
                        size: "lg",
                        title: "Add Field Ticket Numbers"
                      },
                      on: {
                        ok: function($event) {
                          return _vm.addFiledsValue()
                        }
                      }
                    },
                    [
                      _vm._l(_vm.fieldTicketArray, function(val, i) {
                        return _c("div", { staticClass: "d-flex mt-3" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: val.value,
                                expression: "val.value"
                              }
                            ],
                            staticClass: "form-control mb-2",
                            attrs: {
                              type: "text",
                              placeholder: "Enter Field Ticket Number",
                              id: "field-ticket-number"
                            },
                            domProps: { value: val.value },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(val, "value", $event.target.value)
                              }
                            }
                          }),
                          _c("i", {
                            staticClass: "fas fa-minus-circle ml-2",
                            staticStyle: {
                              "margin-top": "3px",
                              cursor: "pointer",
                              color: "red",
                              "font-size": "red"
                            },
                            attrs: { variant: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.removeTicket(val.i)
                              }
                            }
                          })
                        ])
                      }),
                      _vm.editTicket !== 1
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mt-3",
                              attrs: { size: "sm", variant: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.addFieldtickets()
                                }
                              }
                            },
                            [_vm._v("Add\n            Lines +")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "service-modal",
          staticClass: "service-modal",
          attrs: {
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Select Your Service From The Following",
            id: "services-modal"
          }
        },
        [
          _c("v-client-table", {
            staticClass: "table vendor-service-select-table",
            attrs: {
              columns: _vm.columns,
              data: _vm.vendorServices,
              options: _vm.options,
              id: "dataTable"
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(props) {
                  return _c("div", {}, [
                    _c("span", [
                      !_vm.vendoreService_toggler.includes(props.row.serviceid)
                        ? _c("input", {
                            staticClass: "mr-2",
                            attrs: { type: "checkbox" },
                            on: {
                              click: function($event) {
                                return _vm.selectService(props.row)
                              }
                            }
                          })
                        : _c("i", {
                            staticClass: "fa fa-minus-circle mr-2",
                            staticStyle: {
                              cursor: "pointer",
                              "font-size": "medium",
                              color: "red"
                            },
                            on: {
                              click: function($event) {
                                return _vm.deselectService(props.row)
                              }
                            }
                          }),
                      _vm._v(
                        "\n            " +
                          _vm._s(props.row.name) +
                          "\n          "
                      )
                    ])
                  ])
                }
              },
              {
                key: "type",
                fn: function(props) {
                  return _c("div", {}, [
                    _c("span", [_vm._v(_vm._s(props.row.type))])
                  ])
                }
              },
              {
                key: "uom1",
                fn: function(props) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          props.row.uom1 !== null
                            ? "$" + props.row.pu1 + "/" + props.row.uom1
                            : "-"
                        )
                      )
                    ])
                  ])
                }
              },
              {
                key: "uom2",
                fn: function(props) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          props.row.uom2 !== null
                            ? "$" + props.row.pu2 + "/" + props.row.uom2
                            : "-"
                        )
                      )
                    ])
                  ])
                }
              },
              {
                key: "uom3",
                fn: function(props) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          props.row.uom3 !== null
                            ? "$" + props.row.pu3 + "/" + props.row.uom3
                            : "-"
                        )
                      )
                    ])
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "service-tax-modal",
          staticClass: "service-tax-modal",
          attrs: {
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Edit Taxes",
            id: "service-tax-modal"
          }
        },
        [
          _c("table", { staticClass: "tax-table" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "tax-action-th" }),
                _c("th", [_vm._v("Tax Name")]),
                _c("th", [_vm._v("Tax Percentage")]),
                _c("th", [_vm._v("Tax Amount")])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.selectedServiceTax.serviceTaxes, function(val, i) {
                return _c("tr", [
                  _c("td", { staticClass: "tax-action" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: val.isApplied,
                          expression: "val.isApplied"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: val.isApplied,
                        checked: Array.isArray(val.isApplied)
                          ? _vm._i(val.isApplied, null) > -1
                          : val.isApplied
                      },
                      on: {
                        change: function($event) {
                          var $$a = val.isApplied,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(val, "isApplied", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  val,
                                  "isApplied",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(val, "isApplied", $$c)
                          }
                        }
                      }
                    })
                  ]),
                  _c("td", [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", readonly: "" },
                      domProps: { value: val.tax_name }
                    })
                  ]),
                  _c("td", [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "number", readonly: "" },
                      domProps: { value: val.percentage }
                    })
                  ]),
                  _c("td", [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", readonly: "" },
                      domProps: {
                        value: _vm.formatPrice(
                          Number(
                            ("" + _vm.selectedServiceTax.total).replaceAll(
                              ",",
                              ""
                            )
                          ) *
                            (Number(val.percentage) / 100)
                        )
                      }
                    })
                  ])
                ])
              }),
              0
            )
          ])
        ]
      ),
      _vm.projectid
        ? _c("multiselect", {
            staticStyle: { "z-index": "50", "margin-top": "-2px" },
            attrs: {
              disabled:
                _vm.serviceData.length > 0 || _vm.preServiceData.length > 0,
              placeholder: "Select Tax",
              "hide-selected": false,
              "tag-placeholder": "Select Tax",
              "track-by": "tax_name",
              options: _vm.taxData,
              multiple: true,
              "close-on-select": false,
              "clear-on-select": false,
              "preserve-search": true,
              taggable: false,
              label: "tax_name",
              "preselect-first": true
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function(props) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(props.option.tax_name) +
                            " (" +
                            _vm._s(props.option.percentage) +
                            ")"
                        )
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              2893193751
            ),
            model: {
              value: _vm.invoiceTaxes,
              callback: function($$v) {
                _vm.invoiceTaxes = $$v
              },
              expression: "invoiceTaxes"
            }
          })
        : _vm._e(),
      _vm.projectid
        ? _c("div", { staticClass: "d-flex justify-content-between mt-3" }, [
            _c(
              "label",
              {
                staticClass: "font-weight-bold",
                attrs: { for: "field-ticket-number" }
              },
              [_vm._v("Selected Taxes:")]
            ),
            _c("span", { staticClass: "mt-3" }, [
              _vm.toggleInvoiceTax
                ? _c("i", {
                    staticClass: "fas fa-minus-circle danger",
                    on: {
                      click: function($event) {
                        _vm.toggleInvoiceTax = !_vm.toggleInvoiceTax
                      }
                    }
                  })
                : _c("i", {
                    staticClass: "fas fa-plus-circle success",
                    on: {
                      click: function($event) {
                        _vm.toggleInvoiceTax = !_vm.toggleInvoiceTax
                      }
                    }
                  })
            ])
          ])
        : _vm._e(),
      _vm.projectid
        ? _c(
            "b-collapse",
            {
              staticClass: "mt-2",
              attrs: {
                id: "invoice-tax-collapse",
                visible: _vm.toggleInvoiceTax
              }
            },
            _vm._l(_vm.invoiceTaxes, function(val, i) {
              return _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between mt-2" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-between",
                        staticStyle: { width: "100%", "margin-right": "22px" }
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "form-control mr-2",
                          attrs: { type: "text", readonly: "" },
                          model: {
                            value: val.tax_name,
                            callback: function($$v) {
                              _vm.$set(val, "tax_name", $$v)
                            },
                            expression: "val.tax_name"
                          }
                        }),
                        _c("b-form-input", {
                          staticClass: "form-control",
                          attrs: {
                            readonly: _vm.serviceData.length > 0,
                            type: "number"
                          },
                          model: {
                            value: val.percentage,
                            callback: function($$v) {
                              _vm.$set(val, "percentage", $$v)
                            },
                            expression: "val.percentage"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.projectid ? _c("hr") : _vm._e(),
      _vm.projectid
        ? _c("h5", { staticClass: "d-inline-block" }, [_vm._v("Add Services")])
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "date-picker-div" } },
        [
          _vm.preServiceData.length > 0
            ? _c("v-date-picker", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "vc-calendar",
                attrs: {
                  formats: _vm.formats,
                  mode: "range",
                  "show-caps": "",
                  "input-props": {
                    placeholder: "Apply date to all services"
                  },
                  "select-attribute": _vm.selectAttribute,
                  "available-dates": { start: null, end: _vm.vendorInvoiceDate }
                },
                on: {
                  input: function($event) {
                    return _vm.setDates()
                  }
                },
                model: {
                  value: _vm.allService_dates,
                  callback: function($$v) {
                    _vm.allService_dates = $$v
                  },
                  expression: "allService_dates"
                }
              })
            : _vm._e(),
          _vm.preServiceData.length > 0
            ? _c(
                "b-button",
                {
                  staticClass: "btn-blue mb-2 btn-sm float-right",
                  attrs: {
                    disabled:
                      _vm.beforeEditCacheCatch == 1 ||
                      _vm.beforeEditAfeCacheCatch == 1,
                    href: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.addServices(_vm.preServiceData)
                    }
                  }
                },
                [
                  _vm._v("\n        Save All To Invoice "),
                  _c("i", { staticClass: "fa fa-save" })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.projectid
        ? _c(
            "table",
            { attrs: { id: "Service-table" } },
            [
              _c("thead", [
                _c(
                  "tr",
                  {
                    staticStyle: { color: "white", "font-weight": "bolder" },
                    attrs: { id: "Service-table-tr" }
                  },
                  [
                    _c("th", [_vm._v("Service")]),
                    _c("th", [_vm._v("Code")]),
                    _c("th", [_vm._v("Start - End Date")]),
                    _c("th", { staticStyle: { width: "90px" } }, [
                      _vm._v("UOM")
                    ]),
                    _c("th", [_vm._v("Price/Unit")]),
                    _c("th", [_vm._v("Discounted Price/unit")]),
                    _c("th", { staticStyle: { width: "80px" } }, [
                      _vm._v("Quantity")
                    ]),
                    _c("th", [_vm._v("Discount(%)")]),
                    _c("th", [_vm._v("Total")]),
                    _c("th", [_vm._v("Edit Taxes")]),
                    _c("th", [_vm._v("Comments")]),
                    _c("th")
                  ]
                )
              ]),
              _vm._l(_vm.preServiceData, function(service, i) {
                return _vm.projectid
                  ? _c("tbody", [
                      _c(
                        "tr",
                        {
                          style: {
                            backgroundColor:
                              service.serviceid.isflexible !== "N"
                                ? "cornsilk"
                                : "ghostwhite"
                          },
                          attrs: { id: "Service-table-tr" }
                        },
                        [
                          _c("td", [
                            _c("i", {
                              staticClass: "fas fa-minus-circle mr-2",
                              staticStyle: {
                                "margin-right": "10px",
                                "border-color": "transparent",
                                "border-radius": "5px",
                                color: "#f74f4f",
                                "font-size": "14px",
                                cursor: "pointer"
                              },
                              attrs: {
                                disabled:
                                  _vm.beforeEditCacheCatch == 1 ||
                                  _vm.beforeEditAfeCacheCatch == 1
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deletePreLineServices(service, i)
                                }
                              }
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(service.serviceid.name) +
                                "\n          "
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(service.serviceid.type) +
                                "\n          "
                            )
                          ]),
                          _c(
                            "td",
                            [
                              service.serviceid.name
                                ? _c("v-date-picker", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    staticClass: "vc-calendar",
                                    attrs: {
                                      formats: _vm.formats,
                                      mode: "range",
                                      "show-caps": "",
                                      "select-attribute": _vm.selectAttribute,
                                      "available-dates": {
                                        start: null,
                                        end: _vm.vendorInvoiceDate
                                      }
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setQuantity(i)
                                      }
                                    },
                                    model: {
                                      value: service.dates,
                                      callback: function($$v) {
                                        _vm.$set(service, "dates", $$v)
                                      },
                                      expression: "service.dates"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("td", [
                            service.serviceid.name &&
                            service.serviceid.isflexible == "N"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'"
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: service.uom,
                                        expression: "service.uom"
                                      }
                                    ],
                                    staticClass: "rt-round",
                                    staticStyle: {
                                      width: "91%",
                                      height: "30px"
                                    },
                                    attrs: { placeholder: "Select" },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            service,
                                            "uom",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          return _vm.Pu_toggler(
                                            service,
                                            service.discount,
                                            service.uom[0],
                                            true
                                          )
                                        }
                                      ],
                                      name: service.uom
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { selected: "" },
                                        domProps: {
                                          value: [
                                            service.serviceid.pu1,
                                            service.serviceid.uom1
                                          ]
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(service.serviceid.uom1) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    service.serviceid.pu2
                                      ? _c(
                                          "option",
                                          {
                                            domProps: {
                                              value: [
                                                service.serviceid.pu2,
                                                service.serviceid.uom2
                                              ]
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(service.serviceid.uom2) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    service.serviceid.pu3
                                      ? _c(
                                          "option",
                                          {
                                            domProps: {
                                              value: [
                                                service.serviceid.pu3,
                                                service.serviceid.uom3
                                              ]
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(service.serviceid.uom3) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.serviceid.uom1,
                                      expression: "service.serviceid.uom1"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "91%", height: "30px" },
                                  attrs: { placeholder: "Fill uom1" },
                                  domProps: { value: service.serviceid.uom1 },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        service.serviceid,
                                        "uom1",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                          ]),
                          _c("td", [
                            service.serviceid.name &&
                            service.serviceid.isflexible == "N"
                              ? _c("span", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(_vm.formatPrice(service.uom[0]))
                                  )
                                ])
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.serviceid.pu1,
                                      expression: "service.serviceid.pu1"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "91%", height: "30px" },
                                  attrs: { placeholder: "Fill price per unit" },
                                  domProps: { value: service.serviceid.pu1 },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service.serviceid,
                                          "pu1",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.Pu_toggler(
                                          service,
                                          service.discount,
                                          service.serviceid.pu1,
                                          true
                                        )
                                      }
                                    ]
                                  }
                                })
                          ]),
                          _c("td", [
                            service.serviceid.name &&
                            service.serviceid.isflexible == "N"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.discounted_price_per_unit,
                                      expression:
                                        "service.discounted_price_per_unit"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "90%", height: "30px" },
                                  domProps: {
                                    value: service.discounted_price_per_unit
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service,
                                          "discounted_price_per_unit",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.Discount(
                                          service,
                                          service.discounted_price_per_unit,
                                          service.uom[0]
                                        )
                                      }
                                    ]
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.discounted_price_per_unit,
                                      expression:
                                        "service.discounted_price_per_unit"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "90%", height: "30px" },
                                  domProps: {
                                    value: service.discounted_price_per_unit
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service,
                                          "discounted_price_per_unit",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.Discount(
                                          service,
                                          service.discounted_price_per_unit,
                                          service.serviceid.pu1
                                        )
                                      }
                                    ]
                                  }
                                })
                          ]),
                          _c("td", [
                            service.serviceid.name
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.quantity,
                                      expression: "service.quantity"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  class: {
                                    warning:
                                      /day|Day/.test(service.uom) &&
                                      _vm
                                        .$moment(service.dates.end)
                                        .diff(
                                          _vm.$moment(service.dates.start),
                                          "days"
                                        ) +
                                        1 !=
                                        service.quantity
                                  },
                                  staticStyle: { width: "90%", height: "30px" },
                                  attrs: { step: "0.01" },
                                  domProps: { value: service.quantity },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        service,
                                        "quantity",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            /day|Day/.test(service.uom) &&
                            _vm
                              .$moment(service.dates.end)
                              .diff(_vm.$moment(service.dates.start), "days") +
                              1 !=
                              service.quantity
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "91%",
                                      height: "30px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true }
                                        }
                                      ],
                                      staticClass: "fa fa-exclamation-triangle",
                                      attrs: {
                                        title:
                                          "Quantity does not equal sum between start and end (" +
                                          (parseInt(
                                            _vm
                                              .$moment(service.dates.end)
                                              .diff(
                                                _vm.$moment(
                                                  service.dates.start
                                                ),
                                                "days"
                                              )
                                          ) +
                                            1) +
                                          ")!"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _c(
                            "td",
                            [
                              service.serviceid.name &&
                              service.serviceid.isflexible == "N"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: service.discount,
                                        expression: "service.discount"
                                      }
                                    ],
                                    staticClass: "rt-round",
                                    staticStyle: {
                                      width: "90%",
                                      height: "30px"
                                    },
                                    attrs: {
                                      type: "text",
                                      id:
                                        "" +
                                        (service.serviceid.serviceid +
                                          service.uom[0])
                                    },
                                    domProps: { value: service.discount },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            service,
                                            "discount",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.discounted(
                                            service,
                                            service.discount,
                                            service.uom[0]
                                          )
                                        }
                                      ]
                                    }
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: service.discount,
                                        expression: "service.discount"
                                      }
                                    ],
                                    staticClass: "rt-round",
                                    staticStyle: {
                                      width: "90%",
                                      height: "30px"
                                    },
                                    attrs: {
                                      type: "text",
                                      id:
                                        "" +
                                        (service.serviceid.serviceid +
                                          service.serviceid.pu1)
                                    },
                                    domProps: { value: service.discount },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            service,
                                            "discount",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.discounted(
                                            service,
                                            service.discount,
                                            service.serviceid.pu1
                                          )
                                        }
                                      ]
                                    }
                                  }),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    delay: "1000000",
                                    show:
                                      service.discount > 100 ||
                                      service.discount < 0,
                                    target:
                                      "" +
                                      (service.serviceid.serviceid +
                                        service.uom[0]),
                                    title: "Warning"
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v("Discount should be between 0%-100%")
                                  ]),
                                  _c("hr"),
                                  _c("span", [
                                    _vm._v(
                                      "Discounted price per unit should be between $0-price/unit"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c("td", [
                            service.serviceid.name &&
                            service.serviceid.isflexible == "N"
                              ? _c("span", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm.formatPrice(_vm.getTotal(service))
                                      )
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm.formatPrice(_vm.getTotal(service))
                                      )
                                  )
                                ])
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "b-button",
                                {
                                  staticStyle: { width: "max-content" },
                                  attrs: { size: "sm", variant: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openServiceTax(service, false)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fas fa-edit" })]
                              )
                            ],
                            1
                          ),
                          _c("td", [
                            !_vm.lineComments.includes(service.serviceid.name)
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    attrs: { id: "comment" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addComments(service, i)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-plus-circle"
                                    })
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    attrs: { id: "comment" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeComments(service)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-minus-circle"
                                    })
                                  ]
                                )
                          ]),
                          _c("td", [
                            service.quantity >= 0 &&
                            service.uom &&
                            service.discount >= 0 &&
                            service.discount <= 100
                              ? _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    staticStyle: {
                                      "background-color": "#20a8d8",
                                      color: "white",
                                      "border-color": "transparent",
                                      "border-radius": "4px"
                                    },
                                    attrs: {
                                      disabled:
                                        _vm.beforeEditCacheCatch == 1 ||
                                        _vm.beforeEditAfeCacheCatch == 1,
                                      title: "Save Service To Invoice"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addServices([service])
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-save" })]
                                )
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          style: {
                            display: _vm.lineComments.includes(
                              service.serviceid.name
                            )
                              ? "contents"
                              : "none"
                          }
                        },
                        [
                          _c("td", { attrs: { colspan: "100%" } }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: service.comment,
                                  expression: "service.comment"
                                }
                              ],
                              staticClass: "form-control mb-3 d-block mt-3",
                              attrs: { placeholder: "Add comments" },
                              domProps: { value: service.comment },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    service,
                                    "comment",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-block" },
        [
          _vm.projectid
            ? _c(
                "b-button",
                {
                  staticClass: "mt-3 btn-sm",
                  attrs: {
                    disabled:
                      _vm.beforeEditCacheCatch == 1 ||
                      _vm.beforeEditAfeCacheCatch == 1,
                    variant: "success",
                    href: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.showServiceModal(
                        _vm.preServiceData.length,
                        "name",
                        "flag"
                      )
                    }
                  }
                },
                [
                  _vm._v("\n        Add New Line"),
                  _c("i", { staticClass: "fa fa-plus" })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.unassigned.length > 0 || _vm.merged.length > 0
        ? _c(
            "div",
            { staticClass: "mb-3 mt-5 d-flex justify-content-between" },
            [
              _c("h5", [_vm._v("Selected Services")]),
              _c("div", { staticClass: "d-flex justify-content-end" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSortingEnabled,
                        expression: "isSortingEnabled"
                      }
                    ],
                    staticClass: "btn btn-sm btn-warning mr-2",
                    attrs: { type: "button", name: "button" },
                    on: {
                      click: function($event) {
                        return _vm.disableSorting()
                      }
                    }
                  },
                  [_c("strong", [_vm._v("Disable Sorting")])]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-danger",
                    attrs: { type: "button", name: "button" },
                    on: {
                      click: function($event) {
                        _vm.$route.meta.is_preset
                          ? (_vm.afes = [])
                          : _vm.removeAll()
                      }
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v("Remove All Invoice Codings "),
                      _c("i", { staticClass: "fa fa-times" })
                    ])
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.unassigned.length > 0 || _vm.merged.length > 0
        ? _c(
            "table",
            { attrs: { id: "Service-table" } },
            [
              _c("thead", [
                _c("tr", { attrs: { id: "Service-table-tr" } }, [
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            Service\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "name" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("name", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "name" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("name", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            Code\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "type" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("type", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "type" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("type", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            Start - End Date\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "dates" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("dates", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "dates" &&
                            !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("dates", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            UOM\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "uom" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("uom", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "uom" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("uom", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            Price/Unit\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "pu" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("pu", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "pu" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("pu", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v(
                        "\n            Discounted Price/unit\n            "
                      ),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] ===
                              "discounted_price_per_unit" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData(
                              "discounted_price_per_unit",
                              true
                            )
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] ===
                              "discounted_price_per_unit" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData(
                              "discounted_price_per_unit",
                              false
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            Quantity\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "quantity" &&
                            _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("quantity", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "quantity" &&
                            !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("quantity", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            Discount(%)\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "discount" &&
                            _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("discount", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "discount" &&
                            !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("discount", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            Total\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "total" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("total", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "total" &&
                            !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("total", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n            Edit Taxes\n            "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "tax" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("tax", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "tax" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("tax", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c("th", { staticStyle: { "background-color": "#464646" } }, [
                    _vm._v("Action")
                  ])
                ]),
                _c("tr")
              ]),
              _vm._l(_vm.unassigned, function(service) {
                return _c("tbody", [
                  _c(
                    "tr",
                    {
                      class: {
                        editing: service.vsid == _vm.editedLine.vsid,
                        highlightService: _vm.highlightServices.includes(
                          service.vsid
                        )
                      },
                      staticStyle: { cursor: "pointer" },
                      style: {
                        backgroundColor:
                          service.serviceid.isflexible !== "N"
                            ? "cornsilk !important"
                            : "#F8F8FF"
                      },
                      attrs: { draggable: "", id: "Service-table-tr" },
                      on: {
                        dragover: function($event) {
                          $event.preventDefault()
                          return _vm.dragOver(
                            $event,
                            service,
                            service.serviceid.isflexible
                          )
                        },
                        drop: function($event) {
                          return _vm.serviceOnDrop(service, null)
                        },
                        dragstart: function($event) {
                          return _vm.afeDragStart(null, service)
                        }
                      }
                    },
                    [
                      _c("td", [
                        _c("div", { staticClass: "view" }, [
                          _c("i", {
                            staticClass: "fas fa-minus-circle",
                            staticStyle: {
                              "margin-right": "10px",
                              "border-color": "transparent",
                              "border-radius": "5px",
                              color: "#f74f4f",
                              "font-size": "14px"
                            },
                            attrs: {
                              disabled:
                                _vm.beforeEditCacheCatch == 1 ||
                                _vm.beforeEditAfeCacheCatch == 1
                            },
                            on: {
                              click: function($event) {
                                _vm.$route.meta.is_preset
                                  ? _vm.removePresetService(service)
                                  : _vm.deleteService(service, 0)
                              }
                            }
                          }),
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  _vm.editData(service)
                                  service.toggleComment = true
                                }
                              }
                            },
                            [_vm._v(_vm._s(service.name))]
                          )
                        ]),
                        _c("div", { staticClass: "edit" }, [
                          _c("span", [_vm._v(_vm._s(service.name))])
                        ])
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [_c("span", [_vm._v(_vm._s(service.type))])]
                        ),
                        _c("div", { staticClass: "edit" }, [
                          _c("span", [_vm._v(_vm._s(service.type))])
                        ])
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    service.dates.start,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " -\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    service.dates.end,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " "
                            ),
                            _c("br"),
                            _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    parseInt(
                                      _vm
                                        .$moment(service.dates.end)
                                        .diff(
                                          _vm.$moment(service.dates.start),
                                          "days"
                                        )
                                    ) + 1
                                  ) +
                                  "\n                Days)"
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "edit" },
                          [
                            _c("v-date-picker", {
                              staticClass: "vc-calendar",
                              attrs: {
                                formats: _vm.formats,
                                mode: "range",
                                "show-caps": "",
                                "select-attribute": _vm.selectAttribute,
                                "available-dates": {
                                  start: null,
                                  end: _vm.vendorInvoiceDate
                                }
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updateEditQuantity(service)
                                }
                              },
                              model: {
                                value: service.dates,
                                callback: function($$v) {
                                  _vm.$set(service, "dates", $$v)
                                },
                                expression: "service.dates"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [_c("span", [_vm._v(_vm._s(service.uom[1]))])]
                        ),
                        _c("div", { staticClass: "edit" }, [
                          service.serviceid &&
                          service.serviceid.isflexible == "N"
                            ? _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.uom,
                                      expression: "service.uom"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "91%", height: "30px" },
                                  attrs: {
                                    name: "service.uom",
                                    placeholder: "Select"
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          service,
                                          "uom",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        return _vm.Pu_toggler(
                                          service,
                                          service.discount,
                                          service.uom[0],
                                          true
                                        )
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { selected: "" },
                                      domProps: {
                                        value: [
                                          service.serviceid.pu1,
                                          service.serviceid.uom1
                                        ]
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(service.serviceid.uom1) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  service.serviceid.pu2
                                    ? _c(
                                        "option",
                                        {
                                          domProps: {
                                            value: [
                                              service.serviceid.pu2,
                                              service.serviceid.uom2
                                            ]
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(service.serviceid.uom2) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  service.serviceid.pu3
                                    ? _c(
                                        "option",
                                        {
                                          domProps: {
                                            value: [
                                              service.serviceid.pu3,
                                              service.serviceid.uom3
                                            ]
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(service.serviceid.uom3) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: service.serviceid.uom1,
                                    expression: "service.serviceid.uom1"
                                  }
                                ],
                                staticClass: "rt-round",
                                staticStyle: { width: "91%", height: "30px" },
                                attrs: { placeholder: "Fill uom1" },
                                domProps: { value: service.serviceid.uom1 },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      service.serviceid,
                                      "uom1",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ])
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v("$" + _vm._s(_vm.formatPrice(service.pu)))
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "edit" }, [
                          service.serviceid.isflexible == "N"
                            ? _c("span", [
                                _vm._v(
                                  "$" + _vm._s(_vm.formatPrice(service.uom[0]))
                                )
                              ])
                            : _vm._e(),
                          service.serviceid.isflexible !== "N"
                            ? _c("span", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.serviceid.pu1,
                                      expression: "service.serviceid.pu1"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "91%", height: "30px" },
                                  attrs: { placeholder: "Fill uom1" },
                                  domProps: { value: service.serviceid.pu1 },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        service.serviceid,
                                        "pu1",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      service.discounted_price_per_unit
                                    )
                                  )
                              )
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "edit" }, [
                          _c("span", [
                            service.name && service.serviceid.isflexible == "N"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.discounted_price_per_unit,
                                      expression:
                                        "service.discounted_price_per_unit"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "91%", height: "30px" },
                                  domProps: {
                                    value: service.discounted_price_per_unit
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service,
                                          "discounted_price_per_unit",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.Discount(
                                          service,
                                          service.discounted_price_per_unit,
                                          service.uom[0]
                                        )
                                      }
                                    ]
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.discounted_price_per_unit,
                                      expression:
                                        "service.discounted_price_per_unit"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "91%", height: "30px" },
                                  domProps: {
                                    value: service.discounted_price_per_unit
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service,
                                          "discounted_price_per_unit",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.Discount(
                                          service,
                                          service.discounted_price_per_unit,
                                          service.serviceid.pu1
                                        )
                                      }
                                    ]
                                  }
                                })
                          ])
                        ])
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(parseFloat(service.quantity)))
                            ]),
                            /day|Day/.test(service.uom) &&
                            _vm
                              .$moment(service.dates.end)
                              .diff(_vm.$moment(service.dates.start), "days") +
                              1 !=
                              service.quantity
                              ? _c("span", [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle",
                                    attrs: {
                                      title:
                                        "Quantity does not equal sum between start and end dates (" +
                                        (parseInt(
                                          _vm
                                            .$moment(service.dates.end)
                                            .diff(
                                              _vm.$moment(service.dates.start),
                                              "days"
                                            )
                                        ) +
                                          1) +
                                        ")!"
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c("div", { staticClass: "edit" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: service.quantity,
                                expression: "service.quantity"
                              }
                            ],
                            staticClass: "rt-round",
                            class: {
                              warning:
                                /day|Day/.test(service.uom) &&
                                _vm
                                  .$moment(service.dates.end)
                                  .diff(
                                    _vm.$moment(service.dates.start),
                                    "days"
                                  ) +
                                  1 !=
                                  service.quantity
                            },
                            staticStyle: { width: "91%", height: "30px" },
                            attrs: { step: "0.01" },
                            domProps: { value: service.quantity },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  service,
                                  "quantity",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          /day|Day/.test(service.uom) &&
                          _vm
                            .$moment(service.dates.end)
                            .diff(_vm.$moment(service.dates.start), "days") +
                            1 !=
                            service.quantity
                            ? _c("span", [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass: "fa fa-exclamation-triangle",
                                  attrs: {
                                    title:
                                      "Quantity does not equal sum between start and end (" +
                                      (parseInt(
                                        _vm
                                          .$moment(service.dates.end)
                                          .diff(
                                            _vm.$moment(service.dates.start),
                                            "days"
                                          )
                                      ) +
                                        1) +
                                      ")!"
                                  }
                                })
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.formatPrice(service.discount)) + "%"
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "edit" },
                          [
                            service.name && service.serviceid.isflexible == "N"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.discount,
                                      expression: "service.discount"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "91%", height: "30px" },
                                  attrs: {
                                    type: "text",
                                    id:
                                      "" +
                                      (service.serviceid.ptn + service.uom[0])
                                  },
                                  domProps: { value: service.discount },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service,
                                          "discount",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.discounted(
                                          service,
                                          service.discount,
                                          service.uom[0]
                                        )
                                      }
                                    ]
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.discount,
                                      expression: "service.discount"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  staticStyle: { width: "91%", height: "30px" },
                                  attrs: {
                                    type: "text",
                                    id:
                                      "" +
                                      (service.serviceid.ptn +
                                        service.serviceid.pu1)
                                  },
                                  domProps: { value: service.discount },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service,
                                          "discount",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.discounted(
                                          service,
                                          service.discount,
                                          service.serviceid.pu1
                                        )
                                      }
                                    ]
                                  }
                                }),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  delay: "60000",
                                  show:
                                    service.discount > 100 ||
                                    service.discount < 0,
                                  target:
                                    "" +
                                    (service.serviceid.ptn + service.uom[0]),
                                  title: "Warning"
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v("Discount should be between 0%-100%")
                                ]),
                                _c("hr"),
                                _c("span", [
                                  _vm._v(
                                    "Discounted price per unit should be between\n                  $0-price/unit"
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      service.quantity * service.pu -
                                        (service.discount / 100) *
                                          service.quantity *
                                          service.pu
                                    )
                                  )
                              )
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "edit" }, [
                          service.name && service.serviceid.isflexible == "N"
                            ? _c("span", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        service.quantity * service.pu -
                                          (service.discount / 100) *
                                            service.quantity *
                                            service.pu
                                      )
                                    )
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        service.quantity *
                                          service.serviceid.pu1 -
                                          (service.discount / 100) *
                                            service.quantity *
                                            service.serviceid.pu1
                                      )
                                    )
                                )
                              ])
                        ])
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { width: "max-content" },
                                attrs: { size: "sm", variant: "success" }
                              },
                              [_c("i", { staticClass: "fas fa-edit" })]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "edit" },
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { width: "max-content" },
                                attrs: { size: "sm", variant: "success" },
                                on: {
                                  click: function($event) {
                                    return _vm.openServiceTax(service, true)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Edit Taxes\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("td", { staticStyle: { width: "100px" } }, [
                        _c(
                          "div",
                          { staticClass: "view" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "add-coding",
                                attrs: {
                                  variant: "success",
                                  size: "sm",
                                  disabled: _vm.disableEdits == 1
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addNewUnassignedAfeLine(
                                      service,
                                      false
                                    )
                                  }
                                }
                              },
                              [_vm._v("Add Coding")]
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "edit" }, [
                          service.quantity >= 0 &&
                          service.uom &&
                          service.discount >= 0 &&
                          service.discount <= 100
                            ? _c(
                                "button",
                                {
                                  staticClass: "edit btn btn-sm btn-primary",
                                  staticStyle: { width: "100%" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.$route.meta.is_preset
                                        ? _vm.editPreSetService(
                                            service,
                                            _vm.i,
                                            service.serviceid.isflexible
                                          )
                                        : _vm.editService(
                                            service,
                                            service.serviceid.isflexible
                                          )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-edit" })]
                              )
                            : _vm._e()
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      class: { editing: service.vsid == _vm.editedLine.vsid },
                      style: {
                        display: service.toggleComment ? "contents" : "none"
                      }
                    },
                    [
                      _c("td", { attrs: { colspan: "100%" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "view",
                            on: {
                              click: function($event) {
                                _vm.editData(service)
                                service.toggleComment = true
                              }
                            }
                          },
                          [
                            service.toggleComment
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.comment,
                                      expression: "service.comment"
                                    }
                                  ],
                                  staticClass: "form-control mb-3 d-block mt-3",
                                  attrs: {
                                    readonly: "",
                                    placeholder: "Add comments"
                                  },
                                  domProps: { value: service.comment },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        service,
                                        "comment",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        _c("span", { staticClass: "edit" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: service.comment,
                                expression: "service.comment"
                              }
                            ],
                            staticClass: "form-control mb-3 d-block mt-3",
                            attrs: { placeholder: "Add comments" },
                            domProps: { value: service.comment },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  service,
                                  "comment",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      class: {
                        createAfe: service != _vm.createdUnassignedServiceLine
                      },
                      style: {
                        display:
                          service != _vm.createdUnassignedServiceLine
                            ? "none"
                            : "table-row"
                      }
                    },
                    [
                      _c(
                        "td",
                        {
                          attrs: { colspan: "100%", id: "Service-table-child" }
                        },
                        [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", { attrs: { id: "Service-table-tr" } }, [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-size": "11px !important",
                                      "font-weight": "revert"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    AFE or Cost Center / Well\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-size": "11px !important",
                                      "font-weight": "revert"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Cost Code Chain\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-size": "11px !important",
                                      "font-weight": "revert"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Coding Percentage %\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-size": "11px !important",
                                      "font-weight": "revert"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    subtotal\n                  "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    service == _vm.createdUnassignedServiceLine
                                      ? _c("v-select", {
                                          staticStyle: { width: "325px" },
                                          attrs: {
                                            clearable: false,
                                            label: "afenum",
                                            options: _vm.AfeData,
                                            value: _vm.afenum,
                                            selectable: function(option) {
                                              return (
                                                option.afenum !=
                                                "========================================"
                                              )
                                            },
                                            id: "v-select1"
                                          },
                                          model: {
                                            value: _vm.createAfenum,
                                            callback: function($$v) {
                                              _vm.createAfenum = $$v
                                            },
                                            expression: "createAfenum"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c("v-select", {
                                      staticStyle: { width: "325px" },
                                      attrs: {
                                        clearable: false,
                                        options: _vm.paginated,
                                        label: "costcodechainid",
                                        filterable: false,
                                        selectable: function(option) {
                                          return ![-2, -3].includes(
                                            option.costcodechainid
                                          )
                                        },
                                        id: "v-select1"
                                      },
                                      on: {
                                        search: function(query) {
                                          return (_vm.search = query)
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "option",
                                            fn: function(option) {
                                              return [
                                                option.costcodechainid == -2
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "black",
                                                          padding: "5px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "=================================="
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                option.costcodechainid == -3
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "black",
                                                          padding: "5px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "=================================="
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                ![-2, -3].includes(
                                                  option.costcodechainid
                                                )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          option.costcode1
                                                        ) +
                                                          "-" +
                                                          _vm._s(
                                                            option.costcode2
                                                          ) +
                                                          "-" +
                                                          _vm._s(
                                                            option.costcode3
                                                          )
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "selected-option",
                                            fn: function(ref) {
                                              var costcode1 = ref.costcode1
                                              var costcode2 = ref.costcode2
                                              var costcode3 = ref.costcode3
                                              var option = ref.option
                                              var deselect = ref.deselect
                                              var disabled = ref.disabled
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(costcode1) +
                                                      "-" +
                                                      _vm._s(costcode2) +
                                                      "-" +
                                                      _vm._s(costcode3)
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.CC_data,
                                        callback: function($$v) {
                                          _vm.CC_data = $$v
                                        },
                                        expression: "CC_data"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.percentage,
                                        expression: "percentage"
                                      }
                                    ],
                                    staticClass: "rt-percent",
                                    attrs: { type: "number", step: "any" },
                                    domProps: { value: _vm.percentage },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.percentage = $event.target.value
                                      }
                                    }
                                  })
                                ]),
                                _c("td", [
                                  _c("input", {
                                    staticClass: "rt-percent form-control mr-2",
                                    attrs: { type: "text", readonly: "" },
                                    domProps: {
                                      value: _vm.formatPrice(
                                        Number(
                                          service.total.replaceAll(",", "")
                                        ) *
                                          (Number(_vm.percentage) / 100)
                                      )
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _c(
                            "div",
                            { attrs: { id: "afe-filter" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-3 ml-3",
                                  attrs: { variant: "success", size: "sm" },
                                  on: {
                                    mouseover: function($event) {
                                      return _vm.highlightService([service])
                                    },
                                    mouseleave: function($event) {
                                      return _vm.removeHighlightService()
                                    },
                                    click: function($event) {
                                      _vm.$route.meta.is_preset
                                        ? _vm.addPresetAfeUnassignedLine(
                                            service
                                          )
                                        : _vm.addAfeUnassignedLine(service)
                                    }
                                  }
                                },
                                [
                                  _vm._v("Add "),
                                  _c("i", {
                                    staticClass: "ml-2 fa fa-plus-circle"
                                  })
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-3",
                                  attrs: { variant: "success", size: "sm" },
                                  on: {
                                    mouseover: function($event) {
                                      return _vm.highlightService(
                                        _vm.serviceData
                                      )
                                    },
                                    mouseleave: function($event) {
                                      return _vm.removeHighlightService()
                                    },
                                    click: function($event) {
                                      _vm.$route.meta.is_preset
                                        ? _vm.addPresetAfeToAllInvoiceServices(
                                            false
                                          )
                                        : _vm.addAfeToAllInvoiceServices(false)
                                    }
                                  }
                                },
                                [
                                  _vm._v("Add To All"),
                                  _c("i", {
                                    staticClass: "fa fa-plus-circle ml-2"
                                  })
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  attrs: {
                                    variant: "danger",
                                    size: "sm",
                                    title: "AFE or Cost Center / Well Cancel"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelAfe()
                                    }
                                  }
                                },
                                [
                                  _vm._v("Cancel"),
                                  _c("i", {
                                    staticClass: "ml-2 fa fa-times-circle"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ])
              }),
              _vm._l(_vm.merged, function(afe, index) {
                return _c(
                  "tbody",
                  { staticStyle: { cursor: "pointer" } },
                  [
                    _vm._l(afe, function(service, serviceIndex) {
                      return _c(
                        "tr",
                        {
                          class: {
                            editing: service.vsid == _vm.editedLine.vsid,
                            isTop: service.isTop,
                            highlightService: _vm.highlightServices.includes(
                              service.vsid
                            )
                          },
                          staticStyle: {
                            color: "white !important",
                            "border-left": "1.02px solid red",
                            "border-right": "1.02px solid red"
                          },
                          style: {
                            backgroundColor:
                              service.serviceid.isflexible !== "N"
                                ? !_vm.afePercentage.includes(afe[0].vsid)
                                  ? "darkseagreen"
                                  : "dimgray"
                                : !_vm.afePercentage.includes(afe[0].vsid)
                                ? "dimgray"
                                : "lightcoral"
                          },
                          attrs: { id: "Service-table-tr" },
                          on: {
                            dragover: function($event) {
                              $event.preventDefault()
                              return _vm.dragOver(
                                $event,
                                service,
                                service.serviceid.isflexible
                              )
                            },
                            drop: function($event) {
                              return _vm.serviceOnDrop(service, null)
                            }
                          }
                        },
                        [
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c("div", { staticClass: "d-flex" }, [
                              _c("i", {
                                staticClass: "fas fa-minus-circle",
                                staticStyle: {
                                  "margin-right": "10px",
                                  color: "red",
                                  "border-color": "transparent",
                                  "border-radius": "5px",
                                  "font-size": "14px"
                                },
                                attrs: { disabled: _vm.disableEdits == 1 },
                                on: {
                                  click: function($event) {
                                    _vm.$route.meta.is_preset
                                      ? _vm.removePresetService(service)
                                      : _vm.deleteService(service, 1)
                                  }
                                }
                              }),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.editData(service)
                                      service.toggleComment = true
                                    }
                                  }
                                },
                                [_c("strong", [_vm._v(_vm._s(service.name))])]
                              )
                            ])
                          ]),
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(service.type))])]
                            ),
                            _c("div", { staticClass: "edit" }, [
                              _c("span", [_vm._v(_vm._s(service.type))])
                            ])
                          ]),
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        service.dates.start,
                                        "MM/DD/YYYY"
                                      )
                                    ) +
                                    " -\n              " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        service.dates.end,
                                        "MM/DD/YYYY"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "10px" } },
                                  [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          parseInt(
                                            _vm
                                              .$moment(service.dates.end)
                                              .diff(
                                                _vm.$moment(
                                                  service.dates.start
                                                ),
                                                "days"
                                              )
                                          ) + 1
                                        ) +
                                        "\n                Days)"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "edit" },
                              [
                                service.vsid == _vm.editedLine.vsid
                                  ? _c("v-date-picker", {
                                      staticClass: "vc-calendar",
                                      attrs: {
                                        formats: _vm.formats,
                                        mode: "range",
                                        "show-caps": "",
                                        "select-attribute": _vm.selectAttribute,
                                        "available-dates": {
                                          start: null,
                                          end: _vm.vendorInvoiceDate
                                        }
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.updateEditQuantity(service)
                                        }
                                      },
                                      model: {
                                        value: service.dates,
                                        callback: function($$v) {
                                          _vm.$set(service, "dates", $$v)
                                        },
                                        expression: "service.dates"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(service.uom[1]))])]
                            ),
                            _c("div", { staticClass: "edit" }, [
                              service.serviceid
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'"
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: service.uom,
                                          expression: "service.uom"
                                        }
                                      ],
                                      class: {
                                        "is-danger": _vm.errors.has(
                                          "service.uom"
                                        )
                                      },
                                      attrs: {
                                        name: "service.uom",
                                        placeholder: "Select"
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              service,
                                              "uom",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function($event) {
                                            return _vm.Pu_toggler(
                                              service,
                                              service.discount,
                                              service.uom[0],
                                              true
                                            )
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: {
                                            value: [
                                              service.serviceid.pu1,
                                              service.serviceid.uom1
                                            ]
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(service.serviceid.uom1) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      service.serviceid.pu2
                                        ? _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: [
                                                  service.serviceid.pu2,
                                                  service.serviceid.uom2
                                                ]
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    service.serviceid.uom2
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      service.serviceid.pu3
                                        ? _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: [
                                                  service.serviceid.pu3,
                                                  service.serviceid.uom3
                                                ]
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    service.serviceid.uom3
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]),
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "$" + _vm._s(_vm.formatPrice(service.pu))
                                  )
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "edit" }, [
                              _c("span", [
                                _vm._v(
                                  "$" + _vm._s(_vm.formatPrice(service.uom[0]))
                                )
                              ])
                            ])
                          ]),
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPrice(
                                        service.discounted_price_per_unit
                                      )
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "edit" }, [
                              _c("span", [
                                service.name
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            service.discounted_price_per_unit,
                                          expression:
                                            "service.discounted_price_per_unit"
                                        }
                                      ],
                                      domProps: {
                                        value: service.discounted_price_per_unit
                                      },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              service,
                                              "discounted_price_per_unit",
                                              $event.target.value
                                            )
                                          },
                                          function($event) {
                                            _vm.Discount(
                                              service,
                                              service.discounted_price_per_unit,
                                              _vm
                                                .formatPrice(service.uom[0])
                                                .replace(",", "")
                                            )
                                          }
                                        ]
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(parseFloat(service.quantity)))
                                ]),
                                /day|Day/.test(service.uom) &&
                                _vm
                                  .$moment(service.dates.end)
                                  .diff(
                                    _vm.$moment(service.dates.start),
                                    "days"
                                  ) +
                                  1 !=
                                  service.quantity
                                  ? _c("span", [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true }
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-exclamation-triangle",
                                        attrs: {
                                          title:
                                            "Quantity does not equal sum between start and end dates (" +
                                            (parseInt(
                                              _vm
                                                .$moment(service.dates.end)
                                                .diff(
                                                  _vm.$moment(
                                                    service.dates.start
                                                  ),
                                                  "days"
                                                )
                                            ) +
                                              1) +
                                            ")!"
                                        }
                                      })
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _c("div", { staticClass: "edit" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: service.quantity,
                                    expression: "service.quantity"
                                  }
                                ],
                                staticClass: "rt-round",
                                class: {
                                  warning:
                                    /day|Day/.test(service.uom) &&
                                    _vm
                                      .$moment(service.dates.end)
                                      .diff(
                                        _vm.$moment(service.dates.start),
                                        "days"
                                      ) +
                                      1 !=
                                      service.quantity
                                },
                                attrs: { step: "0.01" },
                                domProps: { value: service.quantity },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      service,
                                      "quantity",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              /day|Day/.test(service.uom) &&
                              _vm
                                .$moment(service.dates.end)
                                .diff(
                                  _vm.$moment(service.dates.start),
                                  "days"
                                ) +
                                1 !=
                                service.quantity
                                ? _c("span", [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true }
                                        }
                                      ],
                                      staticClass: "fa fa-exclamation-triangle",
                                      attrs: {
                                        title:
                                          "Quantity does not equal sum between start and end (" +
                                          (parseInt(
                                            _vm
                                              .$moment(service.dates.end)
                                              .diff(
                                                _vm.$moment(
                                                  service.dates.start
                                                ),
                                                "days"
                                              )
                                          ) +
                                            1) +
                                          ")!"
                                      }
                                    })
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatPrice(service.discount)) +
                                      "%"
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "edit" },
                              [
                                service.name
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: service.discount,
                                          expression: "service.discount"
                                        }
                                      ],
                                      staticClass: "rt-round",
                                      attrs: {
                                        type: "text",
                                        id:
                                          "" +
                                          (service.serviceid.ptn +
                                            service.uom[0])
                                      },
                                      domProps: { value: service.discount },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              service,
                                              "discount",
                                              $event.target.value
                                            )
                                          },
                                          function($event) {
                                            return _vm.discounted(
                                              service,
                                              service.discount,
                                              service.uom[0]
                                            )
                                          }
                                        ]
                                      }
                                    })
                                  : _vm._e(),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      delay: "60000",
                                      show:
                                        service.discount > 100 ||
                                        service.discount < 0,
                                      target:
                                        "" +
                                        (service.serviceid.ptn +
                                          service.uom[0]),
                                      title: "Warning"
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Discount should be between 0%-100%"
                                      )
                                    ]),
                                    _c("hr"),
                                    _c("span", [
                                      _vm._v(
                                        "Discounted price per unit should be between\n                  $0-price/unit"
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("td", { attrs: { id: "merged-test" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm.formatPrice(
                                          service.quantity * service.pu -
                                            (service.discount / 100) *
                                              service.quantity *
                                              service.pu
                                        )
                                      )
                                  )
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "edit" }, [
                              _c("span", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        service.quantity * service.pu -
                                          (service.discount / 100) *
                                            service.quantity *
                                            service.pu
                                      )
                                    )
                                )
                              ])
                            ])
                          ]),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass: "view",
                                on: {
                                  click: function($event) {
                                    _vm.editData(service)
                                    service.toggleComment = true
                                  }
                                }
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticStyle: { width: "max-content" },
                                    attrs: { size: "sm", variant: "success" }
                                  },
                                  [_c("i", { staticClass: "fas fa-edit" })]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "edit" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticStyle: { width: "max-content" },
                                    attrs: { size: "sm", variant: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openServiceTax(service, true)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Edit Taxes\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "view" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "add-coding",
                                    attrs: {
                                      variant: "success",
                                      size: "sm",
                                      disabled: _vm.disableEdits == 1
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addNewAfeLine(afe, service)
                                      }
                                    }
                                  },
                                  [_vm._v("Add Coding")]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "edit" }, [
                              service.quantity >= 0 &&
                              service.uom &&
                              service.discount >= 0 &&
                              service.discount <= 100
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "edit btn btn-sm btn-primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          _vm.$route.meta.is_preset
                                            ? _vm.editPreSetService(service)
                                            : _vm.editService(
                                                service,
                                                service.serviceid.isflexible
                                              )
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-edit" })]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    }),
                    _c(
                      "tr",
                      {
                        style: {
                          display: afe[0].toggleComment ? "contents" : "none"
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "border-left": "1.02px solid red",
                              "border-right": "1.02px solid red"
                            },
                            attrs: { colspan: "100%" }
                          },
                          [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: afe[0].comment,
                                  expression: "afe[0].comment"
                                }
                              ],
                              staticClass:
                                "form-control mb-3 d-block mt-3 view",
                              attrs: {
                                readonly: afe[0].vsid != _vm.editedLine.vsid,
                                placeholder: "Add comments"
                              },
                              domProps: { value: afe[0].comment },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    afe[0],
                                    "comment",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              afe[0].show_afe || afe === _vm.createdServiceLine,
                            expression:
                              "afe[0].show_afe || afe === createdServiceLine"
                          }
                        ]
                      },
                      [
                        _c(
                          "td",
                          {
                            attrs: {
                              colspan: "100%",
                              id: "Service-table-child"
                            }
                          },
                          [
                            _c(
                              "table",
                              { staticClass: "table no-bg afenum mb-0" },
                              [
                                _c(
                                  "thead",
                                  {
                                    staticStyle: {
                                      "border-left": "1.02px solid red",
                                      "border-right": "1.02px solid red"
                                    }
                                  },
                                  [
                                    _c("tr", [
                                      _c("th", { attrs: { id: "editAfe" } }, [
                                        _c("strong", [
                                          _vm._v("AFE or Cost Center / Well")
                                        ])
                                      ]),
                                      _c("th", { attrs: { id: "editAfe" } }, [
                                        _c("strong", [
                                          _vm._v(" Cost Code Chain ")
                                        ])
                                      ]),
                                      _c("th", { attrs: { id: "editAfe" } }, [
                                        _c("strong", [
                                          _vm._v(" Coding Percentage % ")
                                        ])
                                      ]),
                                      _c("th", { attrs: { id: "editAfe" } }, [
                                        _c("strong", [_vm._v("subtotal")])
                                      ]),
                                      _c("th", { attrs: { id: "editAfe" } }, [
                                        _c("i", {
                                          staticClass: "fas fa-minus-circle",
                                          staticStyle: {
                                            cursor: "pointer",
                                            "margin-left": "9px",
                                            "font-size": "medium",
                                            color: "red",
                                            "margin-top": "8px"
                                          },
                                          attrs: {
                                            disabled: _vm.disableEdits == 1
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.$route.meta.is_preset
                                                ? _vm.removeAllPresetAfe(afe[0])
                                                : _vm.removeAllAfe(afe[0])
                                            }
                                          }
                                        })
                                      ])
                                    ])
                                  ]
                                ),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(afe[0].afes, function(line, aIndex) {
                                      return _c(
                                        "tr",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: afe[0].show_afe,
                                              expression: "afe[0].show_afe"
                                            }
                                          ],
                                          class: {
                                            editingAfe:
                                              line == _vm.editedAfeLine,
                                            afeBorder:
                                              afe[0].show_afe &&
                                              aIndex === afe[0].afes.length - 1
                                          },
                                          staticStyle: {
                                            "border-left": "1.02px solid red",
                                            "border-right": "1.02px solid red"
                                          },
                                          attrs: {
                                            id: "AfeDragStart",
                                            draggable: ""
                                          },
                                          on: {
                                            dragover: function($event) {
                                              $event.preventDefault()
                                              return _vm.dragOver(afe[0], line)
                                            },
                                            drop: function($event) {
                                              return _vm.serviceOnDrop(
                                                afe[0],
                                                line
                                              )
                                            },
                                            dragstart: function($event) {
                                              return _vm.afeDragStart(
                                                line,
                                                afe[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "td",
                                            { attrs: { id: "editAfe" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "view",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editAfeLine(
                                                        line,
                                                        index,
                                                        aIndex,
                                                        _vm.afes,
                                                        afe
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(line.afenum) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "edit" },
                                                [
                                                  line == _vm.editedAfeLine
                                                    ? _c("v-select", {
                                                        staticStyle: {
                                                          width: "325px"
                                                        },
                                                        attrs: {
                                                          clearable: false,
                                                          label: "afenum",
                                                          options:
                                                            _vm.serviceChainData,
                                                          selectable: function(
                                                            option
                                                          ) {
                                                            return (
                                                              option.afenum !=
                                                              "========================================"
                                                            )
                                                          },
                                                          value: _vm.afenum,
                                                          id: "v-select1"
                                                        },
                                                        model: {
                                                          value: line.afenum,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              line,
                                                              "afenum",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "line.afenum"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { attrs: { id: "editAfe" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "view",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editAfeLine(
                                                        line,
                                                        index,
                                                        aIndex,
                                                        _vm.afes,
                                                        afe
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(line.ccone_code) +
                                                      " -- " +
                                                      _vm._s(line.cctwo_code) +
                                                      " --\n                      " +
                                                      _vm._s(
                                                        line.ccthree_code
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "edit" },
                                                [
                                                  line == _vm.editedAfeLine
                                                    ? _c("v-select", {
                                                        staticStyle: {
                                                          width: "325px"
                                                        },
                                                        attrs: {
                                                          clearable: false,
                                                          options:
                                                            _vm.afeAttachedCostCodes,
                                                          filterable: false,
                                                          label:
                                                            "costcodechainid",
                                                          selectable: function(
                                                            option
                                                          ) {
                                                            return ![
                                                              -2,
                                                              -3
                                                            ].includes(
                                                              option.costcodechainid
                                                            )
                                                          },
                                                          id: "v-select1"
                                                        },
                                                        on: {
                                                          search: function(
                                                            query
                                                          ) {
                                                            return (_vm.search = query)
                                                          }
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "option",
                                                              fn: function(
                                                                option
                                                              ) {
                                                                return [
                                                                  option.costcodechainid ==
                                                                  -2
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "black",
                                                                            padding:
                                                                              "5px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "=================================="
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  option.costcodechainid ==
                                                                  -3
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "black",
                                                                            padding:
                                                                              "5px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "=================================="
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  ![
                                                                    -2,
                                                                    -3
                                                                  ].includes(
                                                                    option.costcodechainid
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              option.costcode1
                                                                            ) +
                                                                              "-" +
                                                                              _vm._s(
                                                                                option.costcode2
                                                                              ) +
                                                                              "-" +
                                                                              _vm._s(
                                                                                option.costcode3
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              }
                                                            },
                                                            {
                                                              key:
                                                                "selected-option",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var costcode1 =
                                                                  ref.costcode1
                                                                var costcode2 =
                                                                  ref.costcode2
                                                                var costcode3 =
                                                                  ref.costcode3
                                                                var option =
                                                                  ref.option
                                                                var deselect =
                                                                  ref.deselect
                                                                var disabled =
                                                                  ref.disabled
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        costcode1
                                                                      ) +
                                                                        "-" +
                                                                        _vm._s(
                                                                          costcode2
                                                                        ) +
                                                                        "-" +
                                                                        _vm._s(
                                                                          costcode3
                                                                        )
                                                                    )
                                                                  ])
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value: _vm.CC_data,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.CC_data = $$v
                                                          },
                                                          expression: "CC_data"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { attrs: { id: "editAfe" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "view",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editAfeLine(
                                                        line,
                                                        index,
                                                        aIndex,
                                                        _vm.afes,
                                                        afe
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(line.percentage) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "edit" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.percentage,
                                                        expression: "percentage"
                                                      }
                                                    ],
                                                    staticClass: "rt-percent",
                                                    attrs: {
                                                      type: "number",
                                                      step: "any"
                                                    },
                                                    domProps: {
                                                      value: _vm.percentage
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.percentage =
                                                          $event.target.value
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { attrs: { id: "editAfe" } },
                                            [
                                              _c("input", {
                                                staticClass:
                                                  "rt-percent form-control view",
                                                attrs: {
                                                  type: "text",
                                                  readonly: ""
                                                },
                                                domProps: {
                                                  value: _vm.formatPrice(
                                                    Number(
                                                      afe[0].combined_total
                                                    ) *
                                                      (Number(line.percentage) /
                                                        100)
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeLine(
                                                      line,
                                                      index,
                                                      aIndex,
                                                      _vm.afes,
                                                      afe
                                                    )
                                                  }
                                                }
                                              }),
                                              _c("input", {
                                                staticClass:
                                                  "rt-percent form-control edit",
                                                attrs: {
                                                  type: "text",
                                                  readonly: ""
                                                },
                                                domProps: {
                                                  value: _vm.formatPrice(
                                                    Number(
                                                      afe[0].combined_total
                                                    ) *
                                                      (Number(_vm.percentage) /
                                                        100)
                                                  )
                                                }
                                              }),
                                              line == _vm.editedAfeLine
                                                ? _c(
                                                    "b-overlay",
                                                    {
                                                      staticClass:
                                                        "mt-3 d-flex justify-content-end",
                                                      attrs: {
                                                        show: _vm.editAfe == 0,
                                                        rounded: "sm"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "mr-3 edit",
                                                          staticStyle: {
                                                            color: "green",
                                                            "font-size":
                                                              "medium"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.editAfe = 0
                                                              _vm.$route.meta
                                                                .is_preset
                                                                ? _vm.editPresetAddAfeData(
                                                                    line,
                                                                    afe,
                                                                    index,
                                                                    aIndex,
                                                                    _vm.afes
                                                                  )
                                                                : _vm.editAddAfeData(
                                                                    line,
                                                                    afe,
                                                                    index,
                                                                    aIndex,
                                                                    _vm.afes
                                                                  )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("b", [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-check-circle",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "x-large"
                                                              }
                                                            })
                                                          ])
                                                        ]
                                                      ),
                                                      line == _vm.editedAfeLine
                                                        ? _c(
                                                            "span",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover",
                                                                  modifiers: {
                                                                    hover: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "edit",
                                                              staticStyle: {
                                                                color: "#f74f4f"
                                                              },
                                                              attrs: {
                                                                title:
                                                                  "AFE or Cost Center / Well Cancel"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.cancelEditAfe(
                                                                    line,
                                                                    afe,
                                                                    index,
                                                                    aIndex,
                                                                    _vm.afes
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-times-circle",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "x-large"
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "view",
                                              attrs: { id: "editAfe" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-minus-circle",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                  "margin-left": "9px",
                                                  "font-size": "medium",
                                                  color: "red",
                                                  "margin-top": "8px"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteServiceAfe(
                                                      line,
                                                      afe[0]
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _c(
                                      "tr",
                                      {
                                        class: {
                                          createAfe:
                                            afe != _vm.createdServiceLine
                                        },
                                        style: {
                                          display:
                                            afe != _vm.createdServiceLine
                                              ? "none"
                                              : "contents"
                                        }
                                      },
                                      [
                                        _c("td", { attrs: { id: "editAfe" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "show" },
                                            [
                                              afe == _vm.createdServiceLine
                                                ? _c("v-select", {
                                                    staticStyle: {
                                                      width: "325px"
                                                    },
                                                    attrs: {
                                                      clearable: false,
                                                      label: "afenum",
                                                      options: _vm.AfeData,
                                                      value: _vm.afenum,
                                                      id: "v-select1",
                                                      selectable: function(
                                                        option
                                                      ) {
                                                        return (
                                                          option.afenum !=
                                                          "========================================"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.createAfenum,
                                                      callback: function($$v) {
                                                        _vm.createAfenum = $$v
                                                      },
                                                      expression: "createAfenum"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("td", { attrs: { id: "editAfe" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "show" },
                                            [
                                              _c("v-select", {
                                                staticStyle: { width: "325px" },
                                                attrs: {
                                                  clearable: false,
                                                  options: _vm.paginated,
                                                  filterable: false,
                                                  label: "costcodechainid",
                                                  id: "v-select1",
                                                  selectable: function(option) {
                                                    return ![-2, -3].includes(
                                                      option.costcodechainid
                                                    )
                                                  }
                                                },
                                                on: {
                                                  search: function(query) {
                                                    return (_vm.search = query)
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "option",
                                                      fn: function(option) {
                                                        return [
                                                          option.costcodechainid ==
                                                          -2
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "black",
                                                                    padding:
                                                                      "5px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "=================================="
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          option.costcodechainid ==
                                                          -3
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "black",
                                                                    padding:
                                                                      "5px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "=================================="
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          ![-2, -3].includes(
                                                            option.costcodechainid
                                                          )
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    option.costcode1
                                                                  ) +
                                                                    "-" +
                                                                    _vm._s(
                                                                      option.costcode2
                                                                    ) +
                                                                    "-" +
                                                                    _vm._s(
                                                                      option.costcode3
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "selected-option",
                                                      fn: function(ref) {
                                                        var costcode1 =
                                                          ref.costcode1
                                                        var costcode2 =
                                                          ref.costcode2
                                                        var costcode3 =
                                                          ref.costcode3
                                                        var option = ref.option
                                                        var deselect =
                                                          ref.deselect
                                                        var disabled =
                                                          ref.disabled
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                costcode1
                                                              ) +
                                                                "-" +
                                                                _vm._s(
                                                                  costcode2
                                                                ) +
                                                                "-" +
                                                                _vm._s(
                                                                  costcode3
                                                                )
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.CC_data,
                                                  callback: function($$v) {
                                                    _vm.CC_data = $$v
                                                  },
                                                  expression: "CC_data"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("td", { attrs: { id: "editAfe" } }, [
                                          _c("div", { staticClass: "show" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.percentage,
                                                  expression: "percentage"
                                                }
                                              ],
                                              staticClass: "rt-percent",
                                              attrs: {
                                                type: "number",
                                                step: "any"
                                              },
                                              domProps: {
                                                value: _vm.percentage
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.percentage =
                                                    $event.target.value
                                                }
                                              }
                                            })
                                          ])
                                        ]),
                                        _c("td", { attrs: { id: "editAfe" } }, [
                                          _c("input", {
                                            staticClass:
                                              "rt-percent form-control view",
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: _vm.formatPrice(
                                                Number(
                                                  afe[0].total.replace(",", "")
                                                ) *
                                                  (Number(_vm.percentage) / 100)
                                              )
                                            }
                                          })
                                        ]),
                                        _c("td", { attrs: { id: "editAfe" } })
                                      ]
                                    ),
                                    _c(
                                      "tr",
                                      {
                                        class: {
                                          createAfe:
                                            afe != _vm.createdServiceLine
                                        },
                                        style: {
                                          display:
                                            afe != _vm.createdServiceLine
                                              ? "none"
                                              : "revert"
                                        }
                                      },
                                      [
                                        _c(
                                          "td",
                                          { attrs: { colspan: "100%" } },
                                          [
                                            _c(
                                              "div",
                                              { attrs: { id: "afe-filter" } },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      variant: "success",
                                                      size: "sm"
                                                    },
                                                    on: {
                                                      mouseover: function(
                                                        $event
                                                      ) {
                                                        return _vm.highlightService(
                                                          [afe[0]]
                                                        )
                                                      },
                                                      mouseleave: function(
                                                        $event
                                                      ) {
                                                        return _vm.removeHighlightService()
                                                      },
                                                      click: function($event) {
                                                        _vm.$route.meta
                                                          .is_preset
                                                          ? _vm.addPresetAfeLine(
                                                              afe,
                                                              _vm.line
                                                            )
                                                          : _vm.addAfeLine(
                                                              afe,
                                                              _vm.line
                                                            )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Add "),
                                                    _c("i", {
                                                      staticClass:
                                                        "ml-2 fa fa-plus-circle"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      variant: "success",
                                                      size: "sm"
                                                    },
                                                    on: {
                                                      mouseover: function(
                                                        $event
                                                      ) {
                                                        return _vm.highlightService(
                                                          _vm.serviceData
                                                        )
                                                      },
                                                      mouseleave: function(
                                                        $event
                                                      ) {
                                                        return _vm.removeHighlightService()
                                                      },
                                                      click: function($event) {
                                                        _vm.$route.meta
                                                          .is_preset
                                                          ? _vm.addPresetAfeToAllInvoiceServices(
                                                              false
                                                            )
                                                          : _vm.addAfeToAllInvoiceServices(
                                                              false
                                                            )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Add To All"),
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-plus-circle ml-2"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      variant: "warning",
                                                      size: "sm"
                                                    },
                                                    on: {
                                                      mouseover: function(
                                                        $event
                                                      ) {
                                                        _vm.highlightService(
                                                          _vm.serviceData.filter(
                                                            function(val) {
                                                              return afe[0].same_coding.includes(
                                                                val.vsid
                                                              )
                                                            }
                                                          )
                                                        )
                                                      },
                                                      mouseleave: function(
                                                        $event
                                                      ) {
                                                        return _vm.removeHighlightService()
                                                      },
                                                      click: function($event) {
                                                        _vm.$route.meta
                                                          .is_preset
                                                          ? _vm.addPresetAfeToAllInvoiceServices(
                                                              true,
                                                              afe[0]
                                                            )
                                                          : _vm.addAfeToAllInvoiceServices(
                                                              true,
                                                              afe[0]
                                                            )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Add To Group "),
                                                    _c("i", {
                                                      staticClass:
                                                        "mr-3 fa fa-plus-circle"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true
                                                        }
                                                      }
                                                    ],
                                                    attrs: {
                                                      variant: "danger",
                                                      size: "sm",
                                                      title:
                                                        "AFE or Cost Center / Well Cancel"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.cancelAfe()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Cancel"),
                                                    _c("i", {
                                                      staticClass:
                                                        "ml-2 fa fa-times-circle"
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm.unassigned.length > 0 || _vm.merged.length > 0 ? _c("hr") : _vm._e(),
      _vm.unassigned.length > 0 || _vm.merged.length > 0
        ? _c(
            "b-row",
            { attrs: { id: "total-row" } },
            [
              _c("b-col", { staticClass: "mt-3" }, [
                _c("table", { attrs: { id: "total-table" } }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Description")]),
                      _c("th", [_vm._v("Amount")])
                    ])
                  ]),
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("td", [_vm._v("Subtotal")]),
                        _c("td", [
                          _c("b", [
                            _vm._v(
                              "$ " + _vm._s(_vm.formatPrice(_vm.subtotal)) + " "
                            )
                          ])
                        ])
                      ]),
                      _vm._l(_vm.invoiceTaxes, function(val) {
                        return _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(val.tax_name) +
                                " (" +
                                _vm._s(val.percentage) +
                                ")%"
                            )
                          ]),
                          _c("td", [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                readonly: "",
                                placeholder: "Tax Value",
                                step: "any",
                                id: "program-number"
                              },
                              domProps: {
                                value: _vm.formatPrice(
                                  _vm.calculateTax(val.tax_name, false)
                                )
                              }
                            })
                          ])
                        ])
                      }),
                      _c("tr", [
                        _c("td", [_vm._v("TAX $")]),
                        _c("td", [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: "Enter Program Number",
                              readonly: "",
                              step: "any",
                              id: "program-number"
                            },
                            domProps: { value: _vm.formatPrice(_vm.tax) }
                          })
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Total")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              "$" + _vm._s(_vm.formatPrice(_vm.totalinvoice))
                            )
                          ])
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n                Quick Pay Discount %\n                "
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fa fa fa-question-circle",
                            attrs: {
                              title: "Discount given to get paid quicker"
                            }
                          })
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quickpay,
                                expression: "quickpay"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: "Add QuickPay Discount"
                            },
                            domProps: { value: _vm.quickpay },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.quickpay = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    ],
                    2
                  )
                ])
              ]),
              _vm.quickpay > 0
                ? _c("b-col", { staticClass: "mt-3" }, [
                    _c("table", { attrs: { id: "total-table" } }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Description")]),
                          _c("th", [_vm._v("QuickPay Amount")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("td", { staticStyle: { height: "40px" } }, [
                              _vm._v("Quick Pay Subtotal")
                            ]),
                            _c("td", { staticStyle: { height: "40px" } }, [
                              _vm._v(
                                "\n                $ " +
                                  _vm._s(
                                    _vm.formatPrice(_vm.quickpaysubtotal)
                                  ) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _vm._l(_vm.invoiceTaxes, function(val) {
                            return _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(val.tax_name) +
                                    " (" +
                                    _vm._s(val.percentage) +
                                    ")%"
                                )
                              ]),
                              _c("td", [
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    readonly: "",
                                    placeholder: "Tax Value",
                                    step: "any",
                                    id: "program-number"
                                  },
                                  domProps: {
                                    value: _vm.formatPrice(
                                      _vm.calculateTax(val.tax_name, true)
                                    )
                                  }
                                })
                              ])
                            ])
                          }),
                          _c("tr", [
                            _c("td", { staticStyle: { height: "40px" } }, [
                              _vm._v(
                                "\n                Quick Pay Net Days\n                "
                              ),
                              _c(
                                "i",
                                {
                                  staticStyle: {
                                    color: "red",
                                    "font-size": "large"
                                  }
                                },
                                [_vm._v("*")]
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "ml-2 fa fa fa-question-circle",
                                attrs: {
                                  title: "Days given for quickpay payment"
                                }
                              })
                            ]),
                            _c("td", { staticStyle: { height: "40px" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.days,
                                    expression: "days"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  placeholder: "Add Net Days",
                                  type: "number",
                                  value: "0",
                                  min: "0"
                                },
                                domProps: { value: _vm.days },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.days = $event.target.value
                                  }
                                }
                              })
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticStyle: { height: "35px" } }, [
                              _vm._v("Quick Pay Total")
                            ]),
                            _c("td", { staticStyle: { height: "35px" } }, [
                              _vm._v(
                                "\n                $ " +
                                  _vm._s(
                                    _vm.formatPrice(
                                      _vm.quickpaysubtotal + _vm.qpgstsubtotal
                                    )
                                  ) +
                                  "\n              "
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.unassigned.length > 0 || _vm.merged.length > 0
        ? _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                [
                  _c("h5", [
                    _vm._v("\n          Comments "),
                    _c(
                      "i",
                      { staticStyle: { color: "red", "font-size": "large" } },
                      [_c("b", [_vm._v("*")])]
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "ml-2 fa fa fa-question-circle",
                      attrs: {
                        title:
                          "Comments will be seen by all user levels and be attached on invoice"
                      }
                    })
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.comments,
                        expression: "comments"
                      }
                    ],
                    staticClass: "w-100 rounded border mt-2",
                    attrs: {
                      name: "comments",
                      placeholder: "Add Comments",
                      id: "",
                      rows: "3"
                    },
                    domProps: { value: _vm.comments },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.comments = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasComment,
                          expression: "hasComment"
                        }
                      ],
                      staticClass: "help is-danger mb-4 mt-1"
                    },
                    [
                      _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                      _vm._v("Comment is\n          required.")
                    ]
                  ),
                  !_vm.$route.meta.is_preset
                    ? _c("vue-dropzone", {
                        ref: "myVueDropzone",
                        staticClass: "mb-4 mt-4",
                        attrs: { id: "dropzone", options: _vm.dropzoneOptions },
                        on: {
                          "vdropzone-success": function($event) {
                            return _vm.redirectUser()
                          },
                          "vdropzone-sending": _vm.sendingEvent
                        }
                      })
                    : _vm._e(),
                  !_vm.$route.meta.is_preset
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.it_name,
                            expression: "it_name"
                          }
                        ],
                        staticClass: "form-control mb-3 d-block mt-3",
                        attrs: {
                          type: "text",
                          placeholder: "Fill Preset Name"
                        },
                        domProps: { value: _vm.it_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.it_name = $event.target.value
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.$route.meta.is_preset
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-info mr-4 mb-4",
                          staticStyle: { color: "white" },
                          on: {
                            click: function($event) {
                              return _vm.submitInvoice(1)
                            }
                          }
                        },
                        [
                          _vm._v("\n          Save As Draft "),
                          _c("i", { staticClass: "fa fa-save" })
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning mb-4 mr-4",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          return _vm.savePreset(0)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$route.meta.is_preset
                              ? "Save Preset"
                              : "Save as Preset"
                          ) +
                          "\n          "
                      ),
                      _c("i", { staticClass: "fa fa-save" })
                    ]
                  ),
                  !_vm.$route.meta.is_preset
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success mb-4",
                          attrs: { type: "submit" },
                          on: {
                            click: function($event) {
                              return _vm.submitInvoice(0)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Submit Invoice\n        ")
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.invoiceError,
                          expression: "invoiceError"
                        }
                      ],
                      staticClass: "d-block"
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.invoiceError,
                            expression: "invoiceError"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.invoiceError,
                              expression: "invoiceError"
                            }
                          ],
                          staticClass: "help"
                        },
                        [
                          _vm._v(
                            "Invoice Error. Please fix invoice or contact admin."
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show: _vm.hasComment,
                        variant: "danger",
                        dismissible: ""
                      }
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "\n            Your customer requires comments ( job description, customer\n            representative name....) about the job. Please add comments than\n            submit.\n          "
                        )
                      ])
                    ]
                  ),
                  _vm.serviceData.filter(function(val) {
                    return val.afes && val.afes.length === 0
                  }).length > 0
                    ? _c("p", { staticClass: "mt-2" }, [
                        _c("i", {
                          staticClass: "fa fa-info-circle",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(
                          " Your customer\n          will appreciate if you add coding to selected services.\n        "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }