<template>
  <b-card header-tag="header" footer-tag="footer">
    <template #header>
      <h4 class="d-block">
        {{ $route.meta.is_preset ? 'Create Preset' : 'Create Invoice' }}
        <i class="ml-2 fa fa fa-question-circle" v-tooltip="
          '<p>1. Select a company</p><p>2. Select a project</p><p>3. Select a well</p><p>4. Add your service line items</p><p>5. Assign AFE or Cost Center / Well and codes to each line items (This is optional for vendors)</p>'
        "></i>
      </h4>
    </template>
    <b-row id="border">
      <b-col style="margin-right: 10px">
        <h4 class="mb-3">Billing From :</h4>
        <div v-if="hasVendorData">
          <div class="mt-3 mb-3 d-flex">
            <img v-if="vendorAvatar" class="companylogo mr-3" v-bind:src="vendorAvatar" />
            <div>
              <span><i class="fa fa-building"></i>
                {{ vendorDetails.company_name }}</span>
              <br />
              <span> <i class="fa fa-user"></i> {{ usersName }} </span>
            </div>
          </div>
          <div>
            <div>
              <b>Address: </b>
              <span>{{ vendorDetails.addressline1 }},
                {{ vendorDetails.addressline2 }}</span>
            </div>
            <div>
              <b> City {{ '&' }} Province: </b>
              <span>{{ vendorDetails.city }}, {{ vendorDetails.province }}</span>
            </div>
            <div class="text-uppercase">
              <b> Postal Code: </b>{{ vendorDetails.postalcode }}
            </div>
            <div><b>Phone Num: </b>{{ vendorDetails.phone_num }}</div>
            <div><b>Fax Num: </b>{{ vendorDetails.fax_num }}</div>
            <div><b>GST: </b>{{ vendorDetails.gst }}</div>
            <div><b>RCN: </b>{{ vendorDetails.rcn }}</div>
            <div><b>Bank Account: </b>{{ vendorDetails.bankaccount }}</div>
          </div>
        </div>
        <div v-else class="mt-3 mb-3">
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
        </div>
      </b-col>
      <b-col>
        <h4 class="mb-3">Bill To :</h4>
        <div class="mt-3 mb-4" v-if="hasCompanyData">
          <img v-if="avatar" class="companylogo" v-bind:src="avatar" />
          <div class="mb-4 mt-2">
            <select :disabled="serviceData.length > 0" style="border-radius: 5px; border-color: lightgrey; height: 38px"
              class="rt-select w-75" v-model="companyId" @change="get_project_managers">
              <option value="0" disabled>Select Company</option>
              <option v-for="company in companyData" :value="company.companyid">
                {{ company.cn }}
              </option>
            </select>
          </div>
          <div v-if="!isNaN(Number(companyId))">
            <div>
              <b>Company Address: </b>{{ companyInfo.addressline1 }},
              {{ companyInfo.addressline2 }}
            </div>
            <div>
              <b> City {{ '&' }} Province: </b> {{ companyInfo.city }},
              {{ companyInfo.province }}
            </div>
            <div><b> Postal code: </b> {{ companyInfo.postalcode }}</div>
          </div>
        </div>
        <div v-else class="mt-3 mb-3">
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
          <b-skeleton animation="throb" width="55%"></b-skeleton>
        </div>
      </b-col>
    </b-row>
    <hr v-if="!isNaN(Number(companyId))" />
    <div class="d-flex justify-content-between" v-if="!isNaN(Number(companyId))">
      <h5 class="mb-3">
        Fill {{ $route.meta.is_preset ? 'Preset' : 'Invoice' }} Details:
      </h5>
      <div class="d-flex justify-content-end" style="width: 80%">
        <h5 style="margin-right: 15px">
          Date: {{ new Date() | moment('MM/DD/YYYY') }}
        </h5>
        <b-button variant="success" size="sm" :disabled="serviceData.length > 0" style="height: min-content"
          v-if="projectid && presetData.length > 0 && false" @click="showImportModal">Import Preset</b-button>
      </div>
    </div>
    <b-row v-if="!isNaN(Number(companyId))" class="mb-3">
      <b-col v-if="projectid && !$route.meta.is_preset">
        <label for="vwref_data"><b>Select Approver :</b></label>
        <multiselect v-model="invoiceTo" @input="selectPm()" placeholder="Select Customer" :hide-selected="false"
          tag-placeholder="Select Customer" :custom-label="customPmLable" track-by="email" :options="uniqueWtsData"
          :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false"
          label="email" :preselect-first="false" style="z-index: 50; margin-top: -2px">
        </multiselect>
      </b-col>
      <b-col v-if="projectid && $route.meta.is_preset">
        <label for="vwref_data"><b>Preset Template Name :
            <i style="color: red; font-size: large">*</i></b>
        </label>
        <input type="text" class="form-control" placeholder="Fill Preset Name" v-model="it_name"
          style="margin-top: 6px; height: 39px" />
      </b-col>

      <b-modal ref="import-modal" class="import-modal" hide-footer :title="'Import Preset: ' + preset.it_name">
        <select class="rt-select w-100" v-model="preset" style="border-radius: 5px">
          <option value="0" disabled>Select Preset</option>
          <option v-for="preset in presetData" :value="preset">
            {{ preset.it_name }}
          </option>
        </select>
        <b-form-checkbox v-model="changeDates" value="1" unchecked-value="0">
          Change All Dates During Import
        </b-form-checkbox>
        <div class="row my-3" v-if="changeDates == 1">
          <div class="col-md-12">
            <h5>Start - End</h5>
            <v-date-picker class="vc-calendar" :formats="formats" mode="range" v-model="preset_date" show-caps>
            </v-date-picker>
          </div>
        </div>
        <b-button class="mt-4" @click="importPreset" variant="success" block>Import</b-button>
      </b-modal>
      <b-col v-if="companyId">
        <label for="vwref_data"><b>Select Cost Centre/Well
            <i style="color: red; font-size: large">*</i></b></label>
        <v-select :clearable="false" v-if="unassigned.length === 0 && merged.length === 0" :options="filter_uwi"
          class="mt-2" :label="'uwi_4'" :filterable="true" v-model="vwref" @input="selectWell()">
          <template v-slot:option="option">
            <span>{{
                !option.uwi_2
                  ? option.uwi
                  : !option.uwi_3
                    ? option.uwi_2
                    : option.uwi_3
            }}-{{ option.projectname }}</span>
          </template>
          <template #selected-option="{
            uwi,
            uwi_2,
            uwi_3,
            projectname,
            option,
            deselect,
            multiple,
            disabled,
          }">
            <span>{{ !uwi_2 ? uwi : !uwi_3 ? uwi_2 : uwi_3 }}-{{
                projectname
            }}</span>
          </template>
        </v-select>
        <input type="text" v-else class="form-control" placeholder="Cost Centre/Well" readonly :value="vwref.uwi"
          style="margin-top: -3px; height: 41px" />
      </b-col>
    </b-row>
    <b-row v-if="!isNaN(Number(companyId))">
      <b-col>
        <b-alert variant="success" show class="mb-3"><b>(Site/Area/Project):</b> {{ projectname }}</b-alert>
        <b-alert variant="danger" show class="mb-3" v-if="projectHasPm.length > 0">
          <b> Warning: Below Customers not assigned to a selected project. </b><br />
          {{ projectHasPm.join(', ') }}
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="!isNaN(Number(companyId)) && !$route.meta.is_preset">
      <b-col class="form-group col-md-6 col-lg-4">
        <label for="service-order-number" class="font-weight-bold">Service Order Number</label>
        <input class="form-control" type="text" placeholder="Enter Service Order Number" v-model="serviceOrderNumber"
          id="service-order-number" />
        <span class="help is-danger mt-4" v-show="serviceOrderNumber.length > 45"><i
            class="fa fa-exclamation-triangle"></i>Max character is 45.</span>
      </b-col>

      <b-col class="form-group col-md-6 col-lg-4">
        <label for="program-number" class="font-weight-bold">Program Number</label>
        <input class="form-control" type="text" placeholder="Enter Program Number" v-model="programNumber"
          id="program-number" />
        <span class="help is-danger mt-4" v-show="programNumber.length > 45"><i
            class="fa fa-exclamation-triangle"></i>Max character is 45.</span>
      </b-col>
      <b-col class="form-group col-md-6 col-lg-4">
        <label for="vendor-invoice-date" class="font-weight-bold">Vendor Invoice Date</label>
        <v-date-picker style="z-index: 100" id="vendor-invoice-date" placeholder="Click to choose" :input-props="{
          class: 'form-control',
          placeholder: 'Choose a date or leave blank',
        }" :available-dates="{ start: null, end: new Date() }" v-model="vendorInvoiceDate" @input="CheckDates()"
          show-caps>
        </v-date-picker>
      </b-col>
    </b-row>

    <b-row v-if="!isNaN(Number(companyId)) && !$route.meta.is_preset">
      <b-col class="form-group col-6">
        <label for="vendor-invoice-number" class="font-weight-bold">Vendor Invoice Number <i
            style="color: red; font-size: large"> *</i>
        </label>
        <input class="form-control" type="text" placeholder="Enter Vendor Invoice Number" v-model="vendorInvoiceNumber"
          id="vendor-invoice-number" />
        <span class="help is-danger mt-4" v-show="vendorInvoiceNumber.length > 20"><i
            class="fa fa-exclamation-triangle"></i>Max character is 20.</span>
      </b-col>

      <b-col class="form-group col-6">
        <label for="treatment-type" class="font-weight-bold">Treatment Type</label>
        <input class="form-control" type="text" placeholder="Enter Treatment Type" v-model="treatmentType"
          id="treatment-type" />
        <span class="help is-danger mt-4" v-show="treatmentType.length > 45"><i
            class="fa fa-exclamation-triangle"></i>Max character is 45.</span>
      </b-col>

      <b-col class="form-group col-12">
        <label for="field-ticket-number" class="font-weight-bold">Field Ticket Number</label>
        <div style="
            background-color: lightgray;
            height: 83px;
            border-radius: 5px;
            overflow-y: auto;
          ">
          <span style="margin-left: 5px" v-if="fieldTicketNumber.length > 0" v-for="val in fieldTicketNumber.split(',')"
            :style="{ color: existedTickets.includes(val) ? 'red' : 'black' }">{{ val }}
          </span>
          <span v-else style="padding: 10px; color: grey; display: block">Add Field Ticket Numbers</span>
        </div>
        <span class="help is-danger mt-4" :style="{
          display: fieldTicketNumber.length > 300 ? 'block' : 'none',
        }"><i class="fa fa-exclamation-triangle"></i>Max character is 300.</span>
        <b-button size="sm" v-b-modal.addFieldTicket class="mt-3" variant="success"
          @click="addDeafultFieldTicketLines()">Add Field Ticket Numbers +</b-button>
        <b-button size="sm" v-b-modal.addFieldTicket class="mt-3 ml-3" variant="warning"
          v-if="fieldTicketNumber.length > 0" @click="EditFieldTickets()">Edit Field Ticket Numbers
        </b-button>

        <b-modal ref="field-ticket-modal" class="field-ticket-modal" title="Duplicate Field Ticket Number" ok-only>
          <span>Please remove duplicate field ticket numbers and resubmit.</span>
        </b-modal>
        <b-modal id="addFieldTicket" size="lg" title="Add Field Ticket Numbers" @ok="addFiledsValue()">
          <div v-for="(val, i) in fieldTicketArray" class="d-flex mt-3">
            <input class="form-control mb-2" type="text" placeholder="Enter Field Ticket Number" v-model="val.value"
              id="field-ticket-number" />
            <i style="
                margin-top: 3px;
                cursor: pointer;
                color: red;
                font-size: red;
              " @click="removeTicket(val.i)" variant="danger" class="fas fa-minus-circle ml-2"></i>
          </div>
          <b-button size="sm" class="mt-3" @click="addFieldtickets()" v-if="editTicket !== 1" variant="success">Add
            Lines +</b-button>
        </b-modal>
      </b-col>
    </b-row>
    <b-modal ref="service-modal" class="service-modal" centered size="lg" hide-footer
      :title="'Select Your Service From The Following'" id="services-modal">
      <v-client-table class="table vendor-service-select-table" :columns="columns" :data="vendorServices"
        :options="options" id="dataTable">
        <div slot="name" slot-scope="props">
          <span>
            <input class="mr-2" v-if="!vendoreService_toggler.includes(props.row.serviceid)" type="checkbox"
              @click="selectService(props.row)" />
            <i v-else @click="deselectService(props.row)" class="fa fa-minus-circle mr-2"
              style="cursor: pointer; font-size: medium; color: red"></i>
            {{ props.row.name }}
          </span>
        </div>

        <div slot="type" slot-scope="props">
          <span>{{ props.row.type }}</span>
        </div>

        <div slot="uom1" slot-scope="props">
          <span>{{
              props.row.uom1 !== null
                ? '$' + props.row.pu1 + '/' + props.row.uom1
                : '-'
          }}</span>
        </div>

        <div slot="uom2" slot-scope="props">
          <span>{{
              props.row.uom2 !== null
                ? '$' + props.row.pu2 + '/' + props.row.uom2
                : '-'
          }}</span>
        </div>

        <div slot="uom3" slot-scope="props">
          <span>{{
              props.row.uom3 !== null
                ? '$' + props.row.pu3 + '/' + props.row.uom3
                : '-'
          }}</span>
        </div>
      </v-client-table>
    </b-modal>
    <!-- <b-modal
      ref="invoice-tax-modal"
      class="invoice-tax-modal"
      centered
      size="lg"
      hide-footer
      :title="'Add Taxes'"
      id="invoice-tax-modal"
    >
      <div v-for="val in customTax">
        <div class="d-flex justify-content-between">
          <input
            type="text"
            v-model="val.tax_name"
            placeholder="Enter Tax Name"
            class="form-control mr-2"
          />
          <input
            type="number"
            v-model="val.percentage"
            class="form-control"
            placeholder="Enter Tax Percentage"
          />
        </div>
      </div>
    </b-modal> -->

    <b-modal ref="service-tax-modal" class="service-tax-modal" centered size="xl" hide-footer :title="'Edit Taxes'"
      id="service-tax-modal">
      <table class="tax-table">
        <thead>
          <tr>
            <th class="tax-action-th"></th>
            <th>Tax Name</th>
            <th>Tax Percentage</th>
            <th>Tax Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in selectedServiceTax.serviceTaxes">
            <td class="tax-action">
              <input type="checkbox" v-model="val.isApplied" :checked="val.isApplied" />
            </td>
            <td>
              <input type="text" :value="val.tax_name" readonly class="form-control" />
            </td>
            <td>
              <input type="number" readonly :value="val.percentage" class="form-control" />
            </td>
            <td>
              <input type="text" readonly :value="
                formatPrice(
                  Number(`${selectedServiceTax.total}`.replaceAll(',', '')) *
                  (Number(val.percentage) / 100)
                )
              " class="form-control" />
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <multiselect :disabled="serviceData.length > 0 || preServiceData.length > 0" v-model="invoiceTaxes"
      placeholder="Select Tax" :hide-selected="false" tag-placeholder="Select Tax" track-by="tax_name"
      :options="taxData" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
      :taggable="false" label="tax_name" :preselect-first="true" style="z-index: 50; margin-top: -2px" v-if="projectid">
      <template slot="option" slot-scope="props">
        <div>{{ props.option.tax_name }} ({{ props.option.percentage }})</div>
      </template>
    </multiselect>

    <div class="d-flex justify-content-between mt-3" v-if="projectid">
      <label for="field-ticket-number" class="font-weight-bold">Selected Taxes:</label>
      <span class="mt-3">
        <i class="fas fa-minus-circle danger" @click="toggleInvoiceTax = !toggleInvoiceTax" v-if="toggleInvoiceTax"></i>
        <i class="fas fa-plus-circle success" @click="toggleInvoiceTax = !toggleInvoiceTax" v-else></i>
      </span>
    </div>

    <b-collapse v-if="projectid" id="invoice-tax-collapse" :visible="toggleInvoiceTax" class="mt-2">
      <div v-for="(val, i) in invoiceTaxes">
        <div class="d-flex justify-content-between mt-2">
          <div class="d-flex justify-content-between" style="width: 100%; margin-right: 22px;">
            <b-form-input type="text" v-model="val.tax_name" readonly class="form-control mr-2"></b-form-input>
            <b-form-input :readonly="serviceData.length > 0" type="number" v-model="val.percentage"
              class="form-control"></b-form-input>
          </div>
          <!-- <i
            class="fas fa-minus-circle"
            @click="removeTaxes(i)"
            style="margin-top: 10px;"
          ></i> -->
        </div>
      </div>
    </b-collapse>

    <!-- <b-button
      v-if="projectid"
      size="sm"
      class="mt-3"
      variant="success"
      @click="addInvoiceTaxes()"
      >Add New Tax Line +</b-button
    > -->
    <hr v-if="projectid" />
    <h5 class="d-inline-block" v-if="projectid">Add Services</h5>
    <div id="date-picker-div">
      <v-date-picker v-if="preServiceData.length > 0" class="vc-calendar" :formats="formats" v-model="allService_dates"
        mode="range" show-caps v-b-tooltip.hover @input="setDates()" :input-props="{
          placeholder: 'Apply date to all services',
        }" :select-attribute="selectAttribute" :available-dates="{ start: null, end: vendorInvoiceDate }">
      </v-date-picker>
      <b-button :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" v-if="preServiceData.length > 0"
        @click="addServices(preServiceData)" class="btn-blue mb-2 btn-sm float-right" href="">
        Save All To Invoice <i class="fa fa-save"></i>
      </b-button>
    </div>
    <table id="Service-table" v-if="projectid">
      <thead>
        <tr style="color: white; font-weight: bolder" id="Service-table-tr">
          <th>Service</th>
          <th>Code</th>
          <th>Start - End Date</th>
          <th style="width: 90px">UOM</th>
          <th>Price/Unit</th>
          <th>Discounted Price/unit</th>
          <th style="width: 80px">Quantity</th>
          <th>Discount(%)</th>
          <th>Total</th>
          <th>Edit Taxes</th>
          <th>Comments</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="(service, i) in preServiceData" v-if="projectid">
        <tr id="Service-table-tr" v-bind:style="{
          backgroundColor:
            service.serviceid.isflexible !== 'N' ? 'cornsilk' : 'ghostwhite',
        }">
          <td>
            <i class="fas fa-minus-circle mr-2" :disabled="
              beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1
            " v-on:click="deletePreLineServices(service, i)" style="
                margin-right: 10px;
                border-color: transparent;
                border-radius: 5px;
                color: #f74f4f;
                font-size: 14px;
                cursor: pointer;
              "></i>
            {{ service.serviceid.name }}
          </td>
          <td>
            {{ service.serviceid.type }}
          </td>
          <td>
            <v-date-picker v-if="service.serviceid.name" class="vc-calendar" :formats="formats" mode="range"
              v-model="service.dates" show-caps v-b-tooltip.hover @input="setQuantity(i)"
              :select-attribute="selectAttribute" :available-dates="{ start: null, end: vendorInvoiceDate }">
            </v-date-picker>
          </td>
          <td>
            <select v-validate="'required'" style="width: 91%; height: 30px" @change="
              Pu_toggler(service, service.discount, service.uom[0], true)
            " @name="service.uom" v-if="
  service.serviceid.name && service.serviceid.isflexible == 'N'
" class="rt-round" v-model="service.uom" placeholder="Select">
              <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">
                {{ service.serviceid.uom1 }}
              </option>
              <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">
                {{ service.serviceid.uom2 }}
              </option>
              <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">
                {{ service.serviceid.uom3 }}
              </option>
            </select>
            <input v-else v-model="service.serviceid.uom1" placeholder="Fill uom1" style="width: 91%; height: 30px"
              class="rt-round" />
          </td>
          <td>
            <span v-if="
              service.serviceid.name && service.serviceid.isflexible == 'N'
            ">${{ formatPrice(service.uom[0]) }}</span>
            <input v-else v-model="service.serviceid.pu1" style="width: 91%; height: 30px" class="rt-round"
              placeholder="Fill price per unit" @input="
                Pu_toggler(
                  service,
                  service.discount,
                  service.serviceid.pu1,
                  true
                )
              " />
          </td>
          <td>
            <input v-if="
              service.serviceid.name && service.serviceid.isflexible == 'N'
            " style="width: 90%; height: 30px" class="rt-round" v-model="service.discounted_price_per_unit" @input="
  Discount(
    service,
    service.discounted_price_per_unit,
    service.uom[0]
  )
" />
            <input v-else v-model="service.discounted_price_per_unit" style="width: 90%; height: 30px" class="rt-round"
              @input="
                Discount(
                  service,
                  service.discounted_price_per_unit,
                  service.serviceid.pu1
                )
              " />
          </td>
          <td>
            <input v-if="service.serviceid.name" style="width: 90%; height: 30px" :class="{
              warning:
                /day|Day/.test(service.uom) &&
                $moment(service.dates.end).diff(
                  $moment(service.dates.start),
                  'days'
                ) +
                1 !=
                service.quantity,
            }" step="0.01" class="rt-round" v-model="service.quantity" />
            <span style="width: 91%; height: 30px" v-if="
              /day|Day/.test(service.uom) &&
              $moment(service.dates.end).diff(
                $moment(service.dates.start),
                'days'
              ) +
              1 !=
              service.quantity
            ">
              <i v-b-tooltip.hover :title="
                'Quantity does not equal sum between start and end (' +
                (parseInt(
                  $moment(service.dates.end).diff(
                    $moment(service.dates.start),
                    'days'
                  )
                ) +
                  1) +
                ')!'
              " class="fa fa-exclamation-triangle"></i>
            </span>
          </td>

          <td>
            <input type="text" style="width: 90%; height: 30px" class="rt-round" v-if="
              service.serviceid.name && service.serviceid.isflexible == 'N'
            " :id="`${service.serviceid.serviceid + service.uom[0]}`" v-model="service.discount"
              @input="discounted(service, service.discount, service.uom[0])" />
            <input type="text" v-else :id="`${service.serviceid.serviceid + service.serviceid.pu1}`"
              style="width: 90%; height: 30px" class="rt-round" v-model="service.discount" @input="
                discounted(service, service.discount, service.serviceid.pu1)
              " />

            <b-popover delay="1000000" :show="service.discount > 100 || service.discount < 0"
              :target="`${service.serviceid.serviceid + service.uom[0]}`" title="Warning">
              <span>Discount should be between 0%-100%</span>
              <hr />
              <span>Discounted price per unit should be between $0-price/unit</span>
            </b-popover>
          </td>
          <td>
            <span v-if="
              service.serviceid.name && service.serviceid.isflexible == 'N'
            ">${{ formatPrice(getTotal(service)) }}</span>
            <span v-else>${{ formatPrice(getTotal(service)) }}</span>
          </td>
          <td>
            <b-button size="sm" variant="success" style="width: max-content;" @click="openServiceTax(service, false)"><i
                class="fas fa-edit"></i>
            </b-button>
          </td>
          <td>
            <button class="btn btn-success" id="comment" v-if="!lineComments.includes(service.serviceid.name)"
              @click="addComments(service, i)">
              <i class="far fa-plus-circle"></i>
            </button>
            <button v-else id="comment" class="btn btn-danger" @click="removeComments(service)">
              <i class="far fa-minus-circle"></i>
            </button>
          </td>

          <td>
            <button style="
                background-color: #20a8d8;
                color: white;
                border-color: transparent;
                border-radius: 4px;
              " :disabled="
                beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1
              " @click="addServices([service])" v-if="
  service.quantity >= 0 &&
  service.uom &&
  service.discount >= 0 &&
  service.discount <= 100
" v-b-tooltip.hover title="Save Service To Invoice">
              <i class="fa fa-save"></i>
            </button>
          </td>
        </tr>
        <tr :style="{
          display: lineComments.includes(service.serviceid.name)
            ? 'contents'
            : 'none',
        }">
          <td colspan="100%">
            <textarea class="form-control mb-3 d-block mt-3" placeholder="Add comments"
              v-model="service.comment"></textarea>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-block">
      <b-button v-if="projectid" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1"
        @click="showServiceModal(preServiceData.length, 'name', 'flag')" variant="success" class="mt-3 btn-sm" href="">
        Add New Line<i class="fa fa-plus"></i>
      </b-button>
    </div>
    <div class="mb-3 mt-5 d-flex justify-content-between" v-if="unassigned.length > 0 || merged.length > 0">
      <h5>Selected Services</h5>
      <div class="d-flex justify-content-end">
        <button type="button" v-show="isSortingEnabled" class="btn btn-sm btn-warning mr-2" @click="disableSorting()"
          name="button">
          <strong>Disable Sorting</strong>
        </button>
        <button type="button" class="btn btn-sm btn-danger" @click="$route.meta.is_preset ? (afes = []) : removeAll()"
          name="button">
          <strong>Remove All Invoice Codings <i class="fa fa-times"></i></strong>
        </button>
      </div>
    </div>
    <!-- unAssigned Services -->
    <table id="Service-table" v-if="unassigned.length > 0 || merged.length > 0">
      <thead>
        <tr id="Service-table-tr">
          <th style="background-color: #464646; cursor: pointer">
            Service
            <i class="fas fa-arrow-up mr-1" @click="sortData('name', true)" :style="{
              color:
                sortedValue[0] === 'name' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('name', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'name' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            Code
            <i class="fas fa-arrow-up mr-1" @click="sortData('type', true)" :style="{
              color:
                sortedValue[0] === 'type' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('type', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'type' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            Start - End Date
            <i class="fas fa-arrow-up mr-1" @click="sortData('dates', true)" :style="{
              color:
                sortedValue[0] === 'dates' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('dates', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'dates' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            UOM
            <i class="fas fa-arrow-up mr-1" @click="sortData('uom', true)" :style="{
              color:
                sortedValue[0] === 'uom' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('uom', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'uom' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            Price/Unit
            <i class="fas fa-arrow-up mr-1" @click="sortData('pu', true)" :style="{
              color:
                sortedValue[0] === 'pu' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('pu', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'pu' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            Discounted Price/unit
            <i class="fas fa-arrow-up mr-1" @click="sortData('discounted_price_per_unit', true)" :style="{
              color:
                sortedValue[0] === 'discounted_price_per_unit' &&
                  sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('discounted_price_per_unit', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'discounted_price_per_unit' &&
                  !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            Quantity
            <i class="fas fa-arrow-up mr-1" @click="sortData('quantity', true)" :style="{
              color:
                sortedValue[0] === 'quantity' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('quantity', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'quantity' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            Discount(%)
            <i class="fas fa-arrow-up mr-1" @click="sortData('discount', true)" :style="{
              color:
                sortedValue[0] === 'discount' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('discount', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'discount' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            Total
            <i class="fas fa-arrow-up mr-1" @click="sortData('total', true)" :style="{
              color:
                sortedValue[0] === 'total' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('total', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'total' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646; cursor: pointer">
            Edit Taxes
            <i class="fas fa-arrow-up mr-1" @click="sortData('tax', true)" :style="{
              color:
                sortedValue[0] === 'tax' && sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
            <i @click="sortData('tax', false)" class="fas fa-arrow-down" :style="{
              color:
                sortedValue[0] === 'tax' && !sortedValue[1]
                  ? 'cornflowerblue !important'
                  : 'white',
            }"></i>
          </th>
          <th style="background-color: #464646">Action</th>
        </tr>
        <tr></tr>
      </thead>
      <tbody v-for="service in unassigned">
        <tr draggable @dragover.prevent="
          dragOver($event, service, service.serviceid.isflexible)
        " @drop="serviceOnDrop(service, null)" @dragstart="afeDragStart(null, service)" :class="{
  editing: service.vsid == editedLine.vsid,
  highlightService: highlightServices.includes(service.vsid),
}" id="Service-table-tr" v-bind:style="{
  backgroundColor:
    service.serviceid.isflexible !== 'N'
      ? 'cornsilk !important'
      : '#F8F8FF',
}" style="cursor: pointer">
          <td>
            <div class="view">
              <i class="fas fa-minus-circle" :disabled="
                beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1
              " v-on:click="
  $route.meta.is_preset
    ? removePresetService(service)
    : deleteService(service, 0)
" style="
                  margin-right: 10px;
                  border-color: transparent;
                  border-radius: 5px;
                  color: #f74f4f;
                  font-size: 14px;
                "></i>
              <span @click="
  editData(service);
service.toggleComment = true;
              ">{{ service.name }}</span>
            </div>
            <div class="edit">
              <span>{{ service.name }}</span>
            </div>
          </td>
          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ service.type }}</span>
            </div>
            <div class="edit">
              <span>{{ service.type }}</span>
            </div>
          </td>
          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              {{ service.dates.start | moment('MM/DD/YYYY') }} -
              {{ service.dates.end | moment('MM/DD/YYYY') }} <br /><span>({{
                  parseInt(
                    $moment(service.dates.end).diff(
                      $moment(service.dates.start),
                      'days'
                    )
                  ) + 1
              }}
                Days)</span>
            </div>
            <div class="edit">
              <v-date-picker class="vc-calendar" :formats="formats" mode="range" v-model="service.dates" show-caps
                @input="updateEditQuantity(service)" :select-attribute="selectAttribute"
                :available-dates="{ start: null, end: vendorInvoiceDate }">
              </v-date-picker>
            </div>
          </td>
          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ service.uom[1] }}</span>
            </div>
            <div class="edit">
              <select v-validate="'required'" @change="
                Pu_toggler(service, service.discount, service.uom[0], true)
              " name="service.uom" v-if="service.serviceid && service.serviceid.isflexible == 'N'"
                style="width: 91%; height: 30px" class="rt-round" v-model="service.uom" placeholder="Select">
                <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">
                  {{ service.serviceid.uom1 }}
                </option>
                <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">
                  {{ service.serviceid.uom2 }}
                </option>
                <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">
                  {{ service.serviceid.uom3 }}
                </option>
              </select>
              <input v-else v-model="service.serviceid.uom1" placeholder="Fill uom1" style="width: 91%; height: 30px"
                class="rt-round" />
            </div>
          </td>
          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>${{ formatPrice(service.pu) }}</span>
            </div>
            <div class="edit">
              <span v-if="service.serviceid.isflexible == 'N'">${{ formatPrice(service.uom[0]) }}</span>
              <span v-if="service.serviceid.isflexible !== 'N'">
                <input v-model="service.serviceid.pu1" placeholder="Fill uom1" style="width: 91%; height: 30px"
                  class="rt-round" />
              </span>
            </div>
          </td>
          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>${{ formatPrice(service.discounted_price_per_unit) }}</span>
            </div>

            <div class="edit">
              <span>
                <input v-if="service.name && service.serviceid.isflexible == 'N'"
                  v-model="service.discounted_price_per_unit" style="width: 91%; height: 30px" class="rt-round" @input="
                    Discount(
                      service,
                      service.discounted_price_per_unit,
                      service.uom[0]
                    )
                  " />
                <input v-else v-model="service.discounted_price_per_unit" style="width: 91%; height: 30px"
                  class="rt-round" @input="
                    Discount(
                      service,
                      service.discounted_price_per_unit,
                      service.serviceid.pu1
                    )
                  " />
              </span>
            </div>
          </td>
          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ parseFloat(service.quantity) }}</span>
              <span v-if="
                /day|Day/.test(service.uom) &&
                $moment(service.dates.end).diff(
                  $moment(service.dates.start),
                  'days'
                ) +
                1 !=
                service.quantity
              ">
                <i v-b-tooltip.hover :title="
                  'Quantity does not equal sum between start and end dates (' +
                  (parseInt(
                    $moment(service.dates.end).diff(
                      $moment(service.dates.start),
                      'days'
                    )
                  ) +
                    1) +
                  ')!'
                " class="fa fa-exclamation-triangle"></i>
              </span>
            </div>

            <div class="edit">
              <input style="width: 91%; height: 30px" :class="{
                warning:
                  /day|Day/.test(service.uom) &&
                  $moment(service.dates.end).diff(
                    $moment(service.dates.start),
                    'days'
                  ) +
                  1 !=
                  service.quantity,
              }" step="0.01" class="rt-round" v-model="service.quantity" />
              <span v-if="
                /day|Day/.test(service.uom) &&
                $moment(service.dates.end).diff(
                  $moment(service.dates.start),
                  'days'
                ) +
                1 !=
                service.quantity
              ">
                <i v-b-tooltip.hover :title="
                  'Quantity does not equal sum between start and end (' +
                  (parseInt(
                    $moment(service.dates.end).diff(
                      $moment(service.dates.start),
                      'days'
                    )
                  ) +
                    1) +
                  ')!'
                " class="fa fa-exclamation-triangle"></i>
              </span>
            </div>
          </td>

          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ formatPrice(service.discount) }}%</span>
            </div>
            <div class="edit">
              <input style="width: 91%; height: 30px" class="rt-round" type="text"
                :id="`${service.serviceid.ptn + service.uom[0]}`"
                v-if="service.name && service.serviceid.isflexible == 'N'" v-model="service.discount"
                @input="discounted(service, service.discount, service.uom[0])" />
              <input type="text" :id="`${service.serviceid.ptn + service.serviceid.pu1}`" v-else
                style="width: 91%; height: 30px" class="rt-round" v-model="service.discount" @input="
                  discounted(service, service.discount, service.serviceid.pu1)
                " />

              <b-popover delay="60000" :show="service.discount > 100 || service.discount < 0"
                :target="`${service.serviceid.ptn + service.uom[0]}`" title="Warning">
                <span>Discount should be between 0%-100%</span>
                <hr />
                <span>Discounted price per unit should be between
                  $0-price/unit</span>
              </b-popover>
            </div>
          </td>

          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>${{
                  formatPrice(
                    service.quantity * service.pu -
                    (service.discount / 100) * service.quantity * service.pu
                  )
              }}</span>
            </div>
            <div class="edit">
              <span v-if="service.name && service.serviceid.isflexible == 'N'">${{
                  formatPrice(
                    service.quantity * service.pu -
                    (service.discount / 100) * service.quantity * service.pu
                  )
              }}</span>
              <span v-else>${{
                  formatPrice(
                    service.quantity * service.serviceid.pu1 -
                    (service.discount / 100) *
                    service.quantity *
                    service.serviceid.pu1
                  )
              }}</span>
            </div>
          </td>
          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <b-button size="sm" variant="success" style="width: max-content;"><i class="fas fa-edit"></i>
              </b-button>
            </div>
            <div class="edit">
              <b-button size="sm" variant="success" style="width: max-content;" @click="openServiceTax(service, true)">
                Edit Taxes
              </b-button>
            </div>
          </td>
          <td style="width: 100px">
            <div class="view">
              <b-button variant="success" class="add-coding" size="sm" :disabled="disableEdits == 1"
                v-on:click="addNewUnassignedAfeLine(service, false)">Add Coding</b-button>
            </div>
            <div class="edit">
              <button style="width: 100%" v-if="
                service.quantity >= 0 &&
                service.uom &&
                service.discount >= 0 &&
                service.discount <= 100
              " class="edit btn btn-sm btn-primary" type="button" @click="
  $route.meta.is_preset
    ? editPreSetService(
      service,
      i,
      service.serviceid.isflexible
    )
    : editService(service, service.serviceid.isflexible)
">
                <i class="fa fa-edit"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr :style="{ display: service.toggleComment ? 'contents' : 'none' }"
          :class="{ editing: service.vsid == editedLine.vsid }">
          <td colspan="100%">
            <span class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <textarea class="form-control mb-3 d-block mt-3" v-if="service.toggleComment" readonly
                placeholder="Add comments" v-model="service.comment"></textarea>
            </span>
            <span class="edit">
              <textarea class="form-control mb-3 d-block mt-3" placeholder="Add comments"
                v-model="service.comment"></textarea>
            </span>
          </td>
        </tr>
        <tr :class="{ createAfe: service != createdUnassignedServiceLine }" :style="{
          display:
            service != createdUnassignedServiceLine ? 'none' : 'table-row',
        }">
          <td colspan="100%" id="Service-table-child">
            <table class="table">
              <thead>
                <tr id="Service-table-tr">
                  <th style="font-size: 11px !important; font-weight: revert">
                    AFE or Cost Center / Well
                  </th>
                  <th style="font-size: 11px !important; font-weight: revert">
                    Cost Code Chain
                  </th>
                  <th style="font-size: 11px !important; font-weight: revert">
                    Coding Percentage %
                  </th>
                  <th style="font-size: 11px !important; font-weight: revert">
                    subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="display: flex">
                    <v-select :clearable="false" style="width: 325px" v-if="service == createdUnassignedServiceLine"
                      v-model="createAfenum" :label="'afenum'" :options="AfeData" :value="afenum" :selectable="
                        (option) =>
                          option.afenum !=
                          '========================================'
                      " id="v-select1">
                    </v-select>
                  </td>
                  <td>
                    <v-select :clearable="false" style="width: 325px" :options="paginated" :label="'costcodechainid'"
                      :filterable="false" v-model="CC_data" @search="(query) => (search = query)" :selectable="
                        (option) => ![-2, -3].includes(option.costcodechainid)
                      " id="v-select1">
                      <template v-slot:option="option">
                        <span style="color: black; padding: 5px"
                          v-if="option.costcodechainid == -2">==================================</span>
                        <span style="color: black; padding: 5px"
                          v-if="option.costcodechainid == -3">==================================</span>
                        <span v-if="![-2, -3].includes(option.costcodechainid)">{{ option.costcode1 }}-{{
                            option.costcode2
                        }}-{{
    option.costcode3
}}</span>
                      </template>
                      <template #selected-option="{
                        costcode1,
                        costcode2,
                        costcode3,
                        option,
                        deselect,
                        disabled,
                      }">
                        <span>{{ costcode1 }}-{{ costcode2 }}-{{ costcode3 }}</span>
                      </template>
                    </v-select>
                  </td>
                  <td>
                    <input type="number" class="rt-percent" step="any" v-model="percentage" />
                  </td>
                  <td>
                    <input type="text" class="rt-percent form-control mr-2" readonly :value="
                      formatPrice(
                        Number(service.total.replaceAll(',', '')) *
                        (Number(percentage) / 100)
                      )
                    " />
                  </td>
                </tr>
              </tbody>
            </table>
            <div id="afe-filter">
              <b-button @mouseover="highlightService([service])" @mouseleave="removeHighlightService()"
                variant="success" size="sm" class="mr-3 ml-3" v-on:click="
                  $route.meta.is_preset
                    ? addPresetAfeUnassignedLine(service)
                    : addAfeUnassignedLine(service)
                ">Add <i class="ml-2 fa fa-plus-circle"></i></b-button>
              <b-button variant="success" size="sm" class="mr-3" @mouseover="highlightService(serviceData)"
                @mouseleave="removeHighlightService()" v-on:click="
                  $route.meta.is_preset
                    ? addPresetAfeToAllInvoiceServices(false)
                    : addAfeToAllInvoiceServices(false)
                ">Add To All<i class="fa fa-plus-circle ml-2"></i></b-button>
              <b-button variant="danger" size="sm" v-on:click="cancelAfe()" v-b-tooltip.hover
                title="AFE or Cost Center / Well Cancel">Cancel<i class="ml-2 fa fa-times-circle"></i></b-button>
            </div>
          </td>
        </tr>
      </tbody>
      <!-- Afe Part -->
      <tbody v-for="(afe, index) in merged" style="cursor: pointer">
        <tr v-for="(service, serviceIndex) in afe" :class="{
          editing: service.vsid == editedLine.vsid,
          isTop: service.isTop,
          highlightService: highlightServices.includes(service.vsid),
        }" id="Service-table-tr" @dragover.prevent="
  dragOver($event, service, service.serviceid.isflexible)
" @drop="serviceOnDrop(service, null)" style="
            color: white !important;
            border-left: 1.02px solid red;
            border-right: 1.02px solid red;
          " v-bind:style="{
            backgroundColor:
              service.serviceid.isflexible !== 'N'
                ? !afePercentage.includes(afe[0].vsid)
                  ? 'darkseagreen'
                  : 'dimgray'
                : !afePercentage.includes(afe[0].vsid)
                  ? 'dimgray'
                  : 'lightcoral',
          }">
          <td id="merged-test">
            <div class="d-flex">
              <i class="fas fa-minus-circle" :disabled="disableEdits == 1" v-on:click="
                $route.meta.is_preset
                  ? removePresetService(service)
                  : deleteService(service, 1)
              " style="
                  margin-right: 10px;
                  color: red;
                  border-color: transparent;
                  border-radius: 5px;
                  font-size: 14px;
                "></i>
              <span @click="
  editData(service);
service.toggleComment = true;
              "><strong>{{ service.name }}</strong>
              </span>
            </div>
          </td>
          <td id="merged-test">
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ service.type }}</span>
            </div>
            <div class="edit">
              <span>{{ service.type }}</span>
            </div>
          </td>
          <td id="merged-test">
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              {{ service.dates.start | moment('MM/DD/YYYY') }} -
              {{ service.dates.end | moment('MM/DD/YYYY') }} <br /><span style="font-size: 10px">({{
                  parseInt(
                    $moment(service.dates.end).diff(
                      $moment(service.dates.start),
                      'days'
                    )
                  ) + 1
              }}
                Days)</span>
            </div>
            <div class="edit">
              <v-date-picker v-if="service.vsid == editedLine.vsid" class="vc-calendar" :formats="formats"
                @input="updateEditQuantity(service)" mode="range" v-model="service.dates" show-caps
                :select-attribute="selectAttribute" :available-dates="{ start: null, end: vendorInvoiceDate }">
              </v-date-picker>
            </div>
          </td>
          <td id="merged-test">
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ service.uom[1] }}</span>
            </div>
            <div class="edit">
              <select v-validate="'required'" @change="
                Pu_toggler(service, service.discount, service.uom[0], true)
              " name="service.uom" v-if="service.serviceid" :class="{ 'is-danger': errors.has('service.uom') }"
                v-model="service.uom" placeholder="Select">
                <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">
                  {{ service.serviceid.uom1 }}
                </option>
                <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">
                  {{ service.serviceid.uom2 }}
                </option>
                <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">
                  {{ service.serviceid.uom3 }}
                </option>
              </select>
            </div>
          </td>
          <td id="merged-test">
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>${{ formatPrice(service.pu) }}</span>
            </div>
            <div class="edit">
              <span>${{ formatPrice(service.uom[0]) }}</span>
            </div>
          </td>

          <td id="merged-test">
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ formatPrice(service.discounted_price_per_unit) }}</span>
            </div>
            <div class="edit">
              <span><input v-if="service.name" v-model="service.discounted_price_per_unit" @input="
                Discount(
                  service,
                  service.discounted_price_per_unit,
                  formatPrice(service.uom[0]).replace(',', '')
                )
              " /></span>
            </div>
          </td>

          <td id="merged-test">
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ parseFloat(service.quantity) }}</span>
              <span v-if="
                /day|Day/.test(service.uom) &&
                $moment(service.dates.end).diff(
                  $moment(service.dates.start),
                  'days'
                ) +
                1 !=
                service.quantity
              ">
                <i v-b-tooltip.hover :title="
                  'Quantity does not equal sum between start and end dates (' +
                  (parseInt(
                    $moment(service.dates.end).diff(
                      $moment(service.dates.start),
                      'days'
                    )
                  ) +
                    1) +
                  ')!'
                " class="fa fa-exclamation-triangle"></i>
              </span>
            </div>
            <div class="edit">
              <input :class="{
                warning:
                  /day|Day/.test(service.uom) &&
                  $moment(service.dates.end).diff(
                    $moment(service.dates.start),
                    'days'
                  ) +
                  1 !=
                  service.quantity,
              }" step="0.01" class="rt-round" v-model="service.quantity" />
              <span v-if="
                /day|Day/.test(service.uom) &&
                $moment(service.dates.end).diff(
                  $moment(service.dates.start),
                  'days'
                ) +
                1 !=
                service.quantity
              ">
                <i v-b-tooltip.hover :title="
                  'Quantity does not equal sum between start and end (' +
                  (parseInt(
                    $moment(service.dates.end).diff(
                      $moment(service.dates.start),
                      'days'
                    )
                  ) +
                    1) +
                  ')!'
                " class="fa fa-exclamation-triangle"></i>
              </span>
            </div>
          </td>
          <td id="merged-test">
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>{{ formatPrice(service.discount) }}%</span>
            </div>
            <div class="edit">
              <input type="text" :id="`${service.serviceid.ptn + service.uom[0]}`" v-if="service.name" class="rt-round"
                v-model="service.discount" @input="discounted(service, service.discount, service.uom[0])" />
              <b-popover delay="60000" :show="service.discount > 100 || service.discount < 0"
                :target="`${service.serviceid.ptn + service.uom[0]}`" title="Warning">
                <span>Discount should be between 0%-100%</span>
                <hr />
                <span>Discounted price per unit should be between
                  $0-price/unit</span>
              </b-popover>
            </div>
          </td>
          <td id="merged-test">
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <span>${{
                  formatPrice(
                    service.quantity * service.pu -
                    (service.discount / 100) * service.quantity * service.pu
                  )
              }}</span>
            </div>
            <div class="edit">
              <span>${{
                  formatPrice(
                    service.quantity * service.pu -
                    (service.discount / 100) * service.quantity * service.pu
                  )
              }}</span>
            </div>
          </td>
          <td>
            <div class="view" @click="
  editData(service);
service.toggleComment = true;
            ">
              <b-button size="sm" variant="success" style="width: max-content;"><i class="fas fa-edit"></i>
              </b-button>
            </div>
            <div class="edit">
              <b-button size="sm" variant="success" style="width: max-content;" @click="openServiceTax(service, true)">
                Edit Taxes
              </b-button>
            </div>
          </td>
          <td>
            <div class="view">
              <b-button variant="success" class="add-coding" size="sm" v-on:click="addNewAfeLine(afe, service)"
                :disabled="disableEdits == 1">Add Coding</b-button>
            </div>
            <div class="edit">
              <button v-if="
                service.quantity >= 0 &&
                service.uom &&
                service.discount >= 0 &&
                service.discount <= 100
              " class="edit btn btn-sm btn-primary" type="button" @click="
  $route.meta.is_preset
    ? editPreSetService(service)
    : editService(service, service.serviceid.isflexible)
">
                <i class="fa fa-edit"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr :style="{ display: afe[0].toggleComment ? 'contents' : 'none' }">
          <td colspan="100%" style="
              border-left: 1.02px solid red;
              border-right: 1.02px solid red;
            ">
            <textarea class="form-control mb-3 d-block mt-3 view" :readonly="afe[0].vsid != editedLine.vsid"
              placeholder="Add comments" v-model="afe[0].comment"></textarea>
          </td>
        </tr>
        <tr v-show="afe[0].show_afe || afe === createdServiceLine">
          <td colspan="100%" id="Service-table-child">
            <table class="table no-bg afenum mb-0">
              <thead style="
                  border-left: 1.02px solid red;
                  border-right: 1.02px solid red;
                ">
                <tr>
                  <th id="editAfe">
                    <strong>AFE or Cost Center / Well</strong>
                  </th>
                  <th id="editAfe"><strong> Cost Code Chain </strong></th>
                  <th id="editAfe">
                    <strong> Coding Percentage % </strong>
                  </th>
                  <th id="editAfe"><strong>subtotal</strong></th>
                  <th id="editAfe">
                    <i :disabled="disableEdits == 1" v-on:click="
                      $route.meta.is_preset
                        ? removeAllPresetAfe(afe[0])
                        : removeAllAfe(afe[0])
                    " style="
                        cursor: pointer;
                        margin-left: 9px;
                        font-size: medium;
                        color: red;
                        margin-top: 8px;
                      " class="fas fa-minus-circle"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!--   -->
                <tr id="AfeDragStart" draggable @dragover.prevent="dragOver(afe[0], line)"
                  @drop="serviceOnDrop(afe[0], line)" @dragstart="afeDragStart(line, afe[0])"
                  v-for="(line, aIndex) in afe[0].afes" :class="{
                    editingAfe: line == editedAfeLine,
                    afeBorder:
                      afe[0].show_afe && aIndex === afe[0].afes.length - 1,
                  }" v-show="afe[0].show_afe" style="
                    border-left: 1.02px solid red;
                    border-right: 1.02px solid red;
                  ">
                  <td id="editAfe">
                    <div class="view" @click="editAfeLine(line, index, aIndex, afes, afe)">
                      {{ line.afenum }}
                    </div>
                    <div class="edit">
                      <v-select :clearable="false" v-if="line == editedAfeLine" v-model="line.afenum"
                        style="width: 325px" label="afenum" :options="serviceChainData" :selectable="
                          (option) =>
                            option.afenum !=
                            '========================================'
                        " :value="afenum" id="v-select1"></v-select>
                    </div>
                  </td>
                  <td id="editAfe">
                    <div class="view" @click="editAfeLine(line, index, aIndex, afes, afe)">
                      {{ line.ccone_code }} -- {{ line.cctwo_code }} --
                      {{ line.ccthree_code }}
                    </div>
                    <div class="edit">
                      <v-select :clearable="false" :options="afeAttachedCostCodes" v-if="line == editedAfeLine"
                        style="width: 325px" :filterable="false" :label="'costcodechainid'" v-model="CC_data"
                        @search="(query) => (search = query)" :selectable="
                          (option) => ![-2, -3].includes(option.costcodechainid)
                        " id="v-select1">
                        <template v-slot:option="option">
                          <span style="color: black; padding: 5px"
                            v-if="option.costcodechainid == -2">==================================</span>
                          <span style="color: black; padding: 5px"
                            v-if="option.costcodechainid == -3">==================================</span>
                          <span v-if="![-2, -3].includes(option.costcodechainid)">{{ option.costcode1 }}-{{
                              option.costcode2
                          }}-{{
    option.costcode3
}}</span>
                        </template>
                        <template #selected-option="{
                          costcode1,
                          costcode2,
                          costcode3,
                          option,
                          deselect,
                          disabled,
                        }">
                          <span>{{ costcode1 }}-{{ costcode2 }}-{{
                              costcode3
                          }}</span>
                        </template>
                      </v-select>
                    </div>
                  </td>
                  <td id="editAfe">
                    <div class="view" @click="editAfeLine(line, index, aIndex, afes, afe)">
                      {{ line.percentage }}
                    </div>
                    <div class="edit">
                      <input type="number" class="rt-percent" step="any" v-model="percentage" />
                    </div>
                  </td>
                  <td id="editAfe">
                    <input type="text" @click="editAfeLine(line, index, aIndex, afes, afe)"
                      class="rt-percent form-control view" readonly :value="
                        formatPrice(
                          Number(afe[0].combined_total) *
                          (Number(line.percentage) / 100)
                        )
                      " />
                    <input type="text" class="rt-percent form-control edit" readonly :value="
                      formatPrice(
                        Number(afe[0].combined_total) *
                        (Number(percentage) / 100)
                      )
                    " />
                    <b-overlay :show="editAfe == 0" v-if="line == editedAfeLine" rounded="sm"
                      class="mt-3 d-flex justify-content-end">
                      <span v-on:click="
  editAfe = 0;
$route.meta.is_preset
  ? editPresetAddAfeData(
    line,
    afe,
    index,
    aIndex,
    afes
  )
  : editAddAfeData(line, afe, index, aIndex, afes);
                      " class="mr-3 edit" style="color: green; font-size: medium">
                        <b><i style="font-size: x-large" class="fas fa-check-circle"></i></b>
                      </span>
                      <span style="color: #f74f4f" class="edit" v-if="line == editedAfeLine" v-on:click="
                        cancelEditAfe(line, afe, index, aIndex, afes)
                      " v-b-tooltip.hover title="AFE or Cost Center / Well Cancel"><i style="font-size: x-large"
                          class="fa fa-times-circle"></i></span>
                    </b-overlay>
                  </td>
                  <td class="view" id="editAfe">
                    <i @click="deleteServiceAfe(line, afe[0])" style="
                        cursor: pointer;
                        margin-left: 9px;
                        font-size: medium;
                        color: red;
                        margin-top: 8px;
                      " class="fas fa-minus-circle"></i>
                  </td>
                </tr>
                <tr :class="{ createAfe: afe != createdServiceLine }" :style="{
                  display: afe != createdServiceLine ? 'none' : 'contents',
                }">
                  <td id="editAfe">
                    <div class="show">
                      <v-select :clearable="false" v-if="afe == createdServiceLine" v-model="createAfenum"
                        style="width: 325px" label="afenum" :options="AfeData" :value="afenum" id="v-select1"
                        :selectable="
                          (option) =>
                            option.afenum !=
                            '========================================'
                        "></v-select>
                    </div>
                  </td>
                  <td id="editAfe">
                    <div class="show">
                      <v-select :clearable="false" :options="paginated" style="width: 325px" :filterable="false"
                        :label="'costcodechainid'" v-model="CC_data" @search="(query) => (search = query)"
                        id="v-select1" :selectable="
                          (option) => ![-2, -3].includes(option.costcodechainid)
                        ">
                        <template v-slot:option="option">
                          <span style="color: black; padding: 5px"
                            v-if="option.costcodechainid == -2">==================================</span>
                          <span style="color: black; padding: 5px"
                            v-if="option.costcodechainid == -3">==================================</span>
                          <span v-if="![-2, -3].includes(option.costcodechainid)">{{ option.costcode1 }}-{{
                              option.costcode2
                          }}-{{
    option.costcode3
}}</span>
                        </template>
                        <template #selected-option="{
                          costcode1,
                          costcode2,
                          costcode3,
                          option,
                          deselect,
                          disabled,
                        }">
                          <span>{{ costcode1 }}-{{ costcode2 }}-{{
                              costcode3
                          }}</span>
                        </template>
                      </v-select>
                    </div>
                  </td>
                  <td id="editAfe">
                    <div class="show">
                      <input type="number" class="rt-percent" step="any" v-model="percentage" />
                    </div>
                  </td>
                  <td id="editAfe">
                    <input type="text" class="rt-percent form-control view" readonly :value="
                      formatPrice(
                        Number(afe[0].total.replace(',', '')) *
                        (Number(percentage) / 100)
                      )
                    " />
                  </td>
                  <td id="editAfe"></td>
                </tr>
                <tr :class="{ createAfe: afe != createdServiceLine }" :style="{
                  display: afe != createdServiceLine ? 'none' : 'revert',
                }">
                  <td colspan="100%">
                    <div id="afe-filter">
                      <b-button @mouseover="highlightService([afe[0]])" @mouseleave="removeHighlightService()"
                        variant="success" size="sm" class="mr-3" v-on:click="
                          $route.meta.is_preset
                            ? addPresetAfeLine(afe, line)
                            : addAfeLine(afe, line)
                        ">Add <i class="ml-2 fa fa-plus-circle"></i></b-button>
                      <b-button variant="success" size="sm" class="mr-3" @mouseover="highlightService(serviceData)"
                        @mouseleave="removeHighlightService()" v-on:click="
                          $route.meta.is_preset
                            ? addPresetAfeToAllInvoiceServices(false)
                            : addAfeToAllInvoiceServices(false)
                        ">Add To All<i class="fa fa-plus-circle ml-2"></i></b-button>
                      <b-button @mouseover="
                        highlightService(
                          serviceData.filter((val) =>
                            afe[0].same_coding.includes(val.vsid)
                          )
                        )
                      " @mouseleave="removeHighlightService()" variant="warning" size="sm" class="mr-3" v-on:click="
  $route.meta.is_preset
    ? addPresetAfeToAllInvoiceServices(true, afe[0])
    : addAfeToAllInvoiceServices(true, afe[0])
">Add To Group <i class="mr-3 fa fa-plus-circle"></i></b-button>

                      <b-button variant="danger" size="sm" v-on:click="cancelAfe()" v-b-tooltip.hover
                        title="AFE or Cost Center / Well Cancel">Cancel<i class="ml-2 fa fa-times-circle"></i>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <hr v-if="unassigned.length > 0 || merged.length > 0" />
    <b-row v-if="unassigned.length > 0 || merged.length > 0" id="total-row">
      <b-col class="mt-3">
        <table id="total-table">
          <thead>
            <tr>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Subtotal</td>
              <td>
                <b>$ {{ formatPrice(subtotal) }} </b>
              </td>
            </tr>
            <tr v-for="val in invoiceTaxes">
              <td>{{ val.tax_name }} ({{ val.percentage }})%</td>
              <td>
                <input class="form-control" type="text" readonly placeholder="Tax Value"
                  :value="formatPrice(calculateTax(val.tax_name, false))" step="any" id="program-number" />
              </td>
            </tr>
            <tr>
              <td>TAX $</td>
              <td>
                <input class="form-control" type="text" placeholder="Enter Program Number" :value="formatPrice(tax)"
                  readonly step="any" id="program-number" />
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td>
                <strong>${{ formatPrice(totalinvoice) }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                Quick Pay Discount %
                <i class="fa fa fa-question-circle" v-b-tooltip.hover title="Discount given to get paid quicker"></i>
              </td>
              <td>
                <input type="text" class="form-control" placeholder="Add QuickPay Discount" v-model="quickpay" />
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col v-if="quickpay > 0" class="mt-3">
        <table id="total-table">
          <thead>
            <tr>
              <th>Description</th>
              <th>QuickPay Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="height: 40px">Quick Pay Subtotal</td>
              <td style="height: 40px">
                $ {{ formatPrice(quickpaysubtotal) }}
              </td>
            </tr>
            <tr v-for="val in invoiceTaxes">
              <td>{{ val.tax_name }} ({{ val.percentage }})%</td>
              <td>
                <input class="form-control" type="text" readonly placeholder="Tax Value"
                  :value="formatPrice(calculateTax(val.tax_name, true))" step="any" id="program-number" />
              </td>
            </tr>
            <tr>
              <td style="height: 40px">
                Quick Pay Net Days
                <i style="color: red; font-size: large">*</i>
                <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Days given for quickpay payment"></i>
              </td>
              <td style="height: 40px">
                <input class="form-control" placeholder="Add Net Days" type="number" value="0" min="0" v-model="days" />
              </td>
            </tr>
            <tr>
              <td style="height: 35px">Quick Pay Total</td>
              <td style="height: 35px">
                $ {{ formatPrice(quickpaysubtotal + qpgstsubtotal) }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>

    <b-row class="mt-3" v-if="unassigned.length > 0 || merged.length > 0">
      <b-col>
        <h5>
          Comments <i style="color: red; font-size: large"><b>*</b></i>
          <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover
            title="Comments will be seen by all user levels and be attached on invoice"></i>
        </h5>
        <textarea name="comments" placeholder="Add Comments" v-model="comments" id="" class="w-100 rounded border mt-2"
          rows="3"></textarea>
        <span class="help is-danger mb-4 mt-1" v-show="hasComment"><i class="fa fa-exclamation-triangle"></i>Comment is
          required.</span>
        <vue-dropzone v-if="!$route.meta.is_preset" v-on:vdropzone-success="redirectUser()" class="mb-4 mt-4"
          ref="myVueDropzone" v-on:vdropzone-sending="sendingEvent" id="dropzone" :options="dropzoneOptions">
        </vue-dropzone>
        <!-- Below div for accountant -->
        <!-- <div class="mb-4" v-if="$session.get('user') == 'accountant' && selectedProject">
          <select v-if="selectedProject[0].acc_project == 1" v-model="directRegular">
            <option value="false">Regular Submission (PM)</option>
            <option value="true">Manager 1</option>
            <option value="true">Manager 2</option>
            <option value="true">Manager 3</option>
            <option value="true">Manager 4</option>
            <option value="true">Manager 5</option>
          </select>
        </div> -->

        <input type="text" class="form-control mb-3 d-block mt-3" v-if="!$route.meta.is_preset"
          placeholder="Fill Preset Name" v-model="it_name" />
        <!-- will use Add Previous Coding feature in next release.  -->
        <!-- <button
          class="btn btn-info mr-4 mb-4"
          size="sm"
          style="color: white; backgroundcolor: darkcyan"
          v-if="merged.length === 0"
          @click="addPreviousCoding()"
        >
          Add Previous Coding<i class="fa fa-plus ml-2"></i>
        </button> -->
        <button class="btn btn-info mr-4 mb-4" style="color: white" v-if="!$route.meta.is_preset"
          @click="submitInvoice(1)">
          Save As Draft <i class="fa fa-save"></i>
        </button>
        <button type="submit" @click="savePreset(0)" class="btn btn-warning mb-4 mr-4">
          {{ $route.meta.is_preset ? 'Save Preset' : 'Save as Preset' }}
          <i class="fa fa-save"></i>
        </button>
        <button type="submit" @click="submitInvoice(0)" v-if="!$route.meta.is_preset" class="btn btn-success mb-4">
          <i class="fa fa-check"></i> Submit Invoice
        </button>
        <div v-show="invoiceError" class="d-block">
          <i v-show="invoiceError" class="fa fa-exclamation-triangle"></i><span v-show="invoiceError"
            class="help">Invoice Error. Please fix invoice or contact admin.</span>
        </div>
        <b-alert :show="hasComment" variant="danger" dismissible>
          <p>
            Your customer requires comments ( job description, customer
            representative name....) about the job. Please add comments than
            submit.
          </p>
        </b-alert>
        <p v-if="
          serviceData.filter((val) => val.afes && val.afes.length === 0)
            .length > 0
        " class="mt-2">
          <i class="fa fa-info-circle" aria-hidden="true"></i> Your customer
          will appreciate if you add coding to selected services.
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script defer>
import Vue from 'vue';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import { Circle4 } from 'vue-loading-spinner';
import { RotateSquare2 } from 'vue-loading-spinner';
import Multiselect from 'vue-multiselect';
import { ClientTable } from 'vue-tables-2';
import * as jwt_decode from 'jwt-decode';
import { backend_url } from '../../config';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { percent } from '@amcharts/amcharts4/core';
Vue.use(ClientTable);

export default {
  name: 'VendorCreateInvoice',
  components: {
    vSelect,
    Datepicker,
    RotateSquare2,
    ClientTable,
    Circle4,
    Multiselect,
    vueDropzone: vue2Dropzone,
  },

  props: ['fake_vendor'],

  data() {
    return {
      allService_dates: '',
      avatar: '',
      vendorAvatar: '',
      serviceColumns: [
        'name',
        'code',
        'date',
        'uom',
        'pu',
        'Dpu',
        'quantity',
        'discount',
        'total',
      ],
      serviceOptions: {
        headings: {
          name: 'Service',
          type: 'Code',
          uom: 'Unit Of Measurment',
          date: 'Date',
          pu: 'Price/Unit',
          Dpu: 'Discounted Price/Unit',
          quantity: 'Quantity',
          discount: 'Discount',
          total: 'Total',
        },
      },
      columns: ['name', 'type', 'uom1', 'uom2', 'uom3'],
      options: {
        headings: {
          name: 'Service Name',
          type: 'Code',
          uom1: 'Uom1',
          umo2: 'Uom2',
          uom3: 'Uom3',
        },
        sortable: ['name', 'type'],
        filterable: ['name', 'type'],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-up',
          down: 'fa-sort-down',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll',
        },
      },
      modalShow: false,
      applyDates_all: false,
      vendoreService_toggler: [],
      dropzoneOptions: {
        url: `${backend_url}/attachment/upload`,
        // https://rtnest.org/attachment/upload
        thumbnailWidth: 150,
        autoProcessQueue: false,
        acceptedFiles:
          'application/pdf, application/vnd.ms-excel, application/msword, image/jpeg, image/png',
        duplicateCheck: true,
        maxFilesize: 9.9, // MB
        maxFiles: 3,
        addRemoveLinks: true,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i> Upload Invoice Attachments",
        headers: {
          Authorization: 'Bearer ' + Vue.prototype.$session.get('jwt'),
        },
      },
      showCode: false,
      serviceSelectInModal: [],
      serviceModalIndex: 0,
      editedLine: { vsid: 0 },
      editedAfeLine: null,
      editMode: false,
      gst: 5,
      tax: 0,
      preServiceData: [],
      serviceData: [],
      companyInfo: [],
      today: new Date(),
      uom: null,
      companyData: [],
      companyId: '-',
      projectid: 0,
      projectData: [],
      vendorDetails: [],
      name: 0,
      vwref: 1,
      start_date: '',
      end_date: '',
      quantity: 0,
      price: 0,
      loading: false,
      discount: 0,
      currency: 'CAD',
      pu: 0,
      afenum: '',
      ccone_code: '',
      cctwo_code: '',
      ccthree_code: '',
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: [],
      percent: 0,
      afes: [],
      quickpay: 0,
      services: [],
      vendorServices: [],
      i: null,
      uomText: '',
      serviceText: '',
      comments: '',
      vsid: 0,
      days: '',
      addToInvoice: 1,
      chainServices: [],
      invoiceError: false,
      serviceChainData: [],
      afesSorted: [],
      uwiData: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      fieldTicketNumber: '',
      vendorInvoiceNumber: '',
      serviceOrderNumber: '',
      programNumber: '',
      treatmentType: '',
      vendorInvoiceDate: new Date(),
      cc1: [],
      cc2: [],
      cc3: [],
      loadingCompany: false,
      servicenameSort: '',
      preIndex: 0,
      line: '',
      lineAfe: '',
      beforeEditCache: [],
      beforeEditCacheCatch: 0,
      beforeEditCacheIndex: 0,
      beforeEditAfeCache: null,
      beforeEditAfeCacheCatch: 0,
      beforeEditAfeCacheIndex: 0,
      preset: 0,
      editAfe: 0,
      presetData: [],
      changeDates: 0,
      preset_date: { start: new Date(), end: new Date() },
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'], // Only for `v-date-picker`
        dayPopover: 'L', // Only for `v-date-picker`
      },
      discounted_price_per_unit: 0,
      percentage: 0,
      createdServiceLine: 0,
      createAfenum: [],
      createCC1Percentage: 100,
      createCC1Code: [],
      createCC2Code: [],
      createCC3Code: [],
      createAfeData: [],
      cc1CreateData: [],
      cc2CreateData: [],
      cc3CreateData: [],
      createAfe: 0,
      disableEdits: 0,
      afes: [],
      order: '',
      ascDesc: 'asc',
      toggle: false,
      createdUnassignedServiceLine: [],
      createdServiceLineItem: [],
      editAfeChainLine: [],
      loading: false,
      lineLoading: false,
      disableImport: false,
      directRegular: false,
      selectedProject: [],
      invoiceid: 0,
      usersName: '',
      price_per_unit: 0,
      projectTypeData: [
        { projecttype: 'Operation Project' },
        { projecttype: 'Afe Project' },
      ],
      projectType: '',
      CC_data: '',
      search_cc: 'costcode1',
      vendorId: 0,
      projectname: '',
      vwref_list: [],
      vwrefs: '',
      afePercentage: [],
      allInvoices: [],
      existedTickets: [],
      search: '',
      oldCcData: '',
      hasComment: false,
      selectAttribute: {
        dot: true,
      },
      hasVendorData: false,
      hasCompanyData: false,
      wssData: [],
      invoiceTo: [],
      filter_uwi: [],
      uniqueWtsData: [],
      projectHasPm: [],
      it_name: '',
      isAddComments: false,
      lineComments: [],
      createComment: '',
      AfeData: [],
      combinedPmData: [],
      dragStartService: {},
      fieldTicketArray: [],
      editTicket: 0,
      highlightServices: [],
      isSortingEnabled: false,
      sortedValue: [],
      invoiceTaxes: [
        {
          tax_name: 'GST',
          percentage: 5,
        },
      ],
      taxData: [
        {
          tax_name: 'GST',
          percentage: 5,
        },
        {
          tax_name: 'PST',
          percentage: 7,
        },
        {
          tax_name: 'HST',
          percentage: 7,
        },
      ],
      toggleInvoiceTax: true,
      selectedServiceTax: {},
    };
  },
  watch: {
    invoiceid: function (val) {
      if (val > 0) {
        this.$refs.myVueDropzone.processQueue();
      }
    },
  },
  methods: {
    validateTaxes() {
      // will use to validate custome taxes
      const taxes = this.invoiceTaxes.filter(
        (val) => val.tax_name.trim() !== '' && val.percentage !== ''
      );
      return taxes;
    },
    // addInvoiceTaxes() {
    //   this.invoiceTaxes.push({
    //     tax_name: '',
    //     percentage: '',
    //   });
    // },
    // setDeafultTaxes() {
    //   this.invoiceTaxes = [
    //     {
    //       tax_name: 'GST',
    //       percentage: 5,
    //     },
    //   ];
    // },
    // removeTaxes(i) {
    //   this.invoiceTaxes.splice(i, 1);
    // },
    openServiceTax(service, isSaved) {
      if (!isSaved) {
        // is service saved to db.
        service.total =
          service.quantity * service.pu -
          (service.discount / 100) * service.quantity * service.pu;
      }
      this.selectedServiceTax = service;
      this.$refs['service-tax-modal'].show();
    },
    disableSorting() {
      this.isSortingEnabled = false;
      this.sortedValue = [];
      this.serviceData.sort((a, b) => a.vsid - b.vsid);
    },
    sortData(value, order) {
      this.isSortingEnabled = true;
      this.sortedValue = [value, order];
      if (['name', 'type'].includes(value)) {
        this.serviceData.sort((a, b) => {
          return order
            ? a[value] > b[value]
              ? 1
              : -1
            : a[value] < b[value]
              ? 1
              : -1;
        });
      } else if (
        [
          'pu',
          'discounted_price_per_unit',
          'discount',
          'quantity',
          'total',
        ].includes(value)
      ) {
        this.serviceData.sort((a, b) => {
          const check =
            Number(`${a[value]}`.replaceAll(',', '')) -
            Number(`${b[value]}`.replaceAll(',', ''));
          return order ? -check : check;
        });
      } else if (value === 'uom') {
        this.serviceData.sort((a, b) => {
          return order
            ? a[value][1] > b[value][1]
              ? 1
              : -1
            : a[value][1] < b[value][1]
              ? 1
              : -1;
        });
      } else if (value === 'dates') {
        this.serviceData.sort((a, b) => {
          const check = this.$moment(a.dates.start).isSameOrAfter(b.dates.start)
            ? 1
            : -1;
          return order ? -check : check;
        });
      } else {
        this.serviceData.sort((a, b) => {
          const check = Number(a.tax) - Number(b.tax);
          return order ? -check : check;
        });
      }
    },
    addDeafultFieldTicketLines() {
      this.fieldTicketArray = [];
      [1, 2, 3, 4, 5].forEach(() => this.addFieldtickets());
    },
    addFiledsValue() {
      if (this.editTicket == 0) {
        const getCurrentTickets = this.existedTicketsNumbers();
        this.fieldTicketArray.unshift(...getCurrentTickets);
      }
      const getString = this.fieldTicketArray.filter(
        (val) => val.value.replace(/\s/g, '').length > 0
      );
      this.fieldTicketNumber = getString.map((val) => `${val.value}`).join(',');
      this.editTicket = 0;
    },

    removeTicket(i) {
      this.fieldTicketArray = this.fieldTicketArray.filter(
        (val) => val.i !== i
      );
    },
    addFieldtickets() {
      this.fieldTicketArray.push({
        i: Math.random() + this.fieldTicketArray.length,
        value: '',
      });
    },
    EditFieldTickets() {
      this.editTicket = 1;
      this.fieldTicketArray = [];
      this.fieldTicketArray = this.existedTicketsNumbers();
    },
    existedTicketsNumbers() {
      return this.fieldTicketNumber.split(',').map((val) => {
        return {
          i: Math.random() + this.fieldTicketArray.length,
          value: val,
        };
      });
    },
    //for price per unit and discounted price per unit value;
    // toggles the discount price per unit value;
    Pu_toggler(service, discount, pu, isFlexibleUom) {
      pu = !isFlexibleUom ? 0 : pu;

      if (!discount) {
        service.discounted_price_per_unit = Number(pu).toFixed(2);

        return service.discounted_price_per_unit;
      } else {
        service.discounted_price_per_unit =
          Number(pu) * (1 - discount / 100).toFixed(2);

        return service.discounted_price_per_unit;
      }
    },
    Discount(service, discountedPrice, pu) {
      service.discount = 100 * (1 - discountedPrice / pu);
      return service.discount;
    },

    discounted(service, discount, pu) {
      // pu- price per unit
      // d is discount;
      if (discount <= 100 && discount >= 0) {
        service.discounted_price_per_unit = pu * (1 - discount / 100);
        return service.discounted_price_per_unit;
      } else {
        service.discounted_price_per_unit = NaN;
        return service.discounted_price_per_unit;
      }
    },
    EditComment(service) {
      service.comment = '';
    },
    addComments(service, i) {
      this.lineComments.push(service.serviceid.name);
    },
    removeComments(service) {
      this.lineComments = this.lineComments.filter(
        (val) => val !== service.serviceid.name
      );
    },
    customPmLable({ fname, lname }) {
      return `${fname} ${lname}`;
    },
    async checkWssPM() {
      if (this.invoiceTo.length > 0) {
        let selected_data = this.combinedPmData.filter((val) =>
          this.invoiceTo.some((el) => val.email === el.email)
        );
        selected_data = selected_data
          .filter((val) => val.projectid === this.vwref.projectid)
          .map((val) => val.email);
        this.projectHasPm = this.invoiceTo
          .filter((val) => !selected_data.includes(val.email))
          .map((val) => `${val.fname} ${val.lname}`);
      }
    },
    async selectPm() {
      if (this.invoiceTo.length > 0) {
        if (this.invoiceTo.filter((val) => val.projectid === 0).length === 0) {
          const selected_wss = this.wssData.filter((val) =>
            this.invoiceTo.some((el) => val.email === el.email)
          );
          const projectIds = [...selected_wss.map((val) => val.projectid)];
          this.filter_uwi = this.uwiData.filter((val) =>
            projectIds.includes(val.projectid)
          );
        } else {
          this.invoiceTo = this.uniqueWtsData.filter(
            (val) => val.wtsid !== 0 || val.wssid !== 0
          );
          this.filter_uwi = this.uwiData;
        }
      } else {
        this.filter_uwi = this.uwiData;
      }
      this.vwref =
        this.vwref != 1
          ? this.filter_uwi.filter((val) => val.pwid === this.vwref.pwid)[0]
          : this.filter_uwi[0];
      this.projectid = this.vwref.projectid;
      this.projectname = this.vwref.projectname;
      this.checkWssPM();
    },
    async get_project_managers() {
      try {
        await this.$axios
          .get('/vendor/get/project_manager/' + this.companyId)
          .then((response) => {
            this.wssData = response.data.allowed_wss;
            this.combinedPmData = this.wssData;
            this.uniqueWtsData = this.lodash.uniqBy(
              [
                {
                  fname: 'All',
                  lname: 'Project Managers',
                  projectid: 0,
                  wtsid: 0,
                  wssid: 0,
                },
              ].concat(this.wssData),
              'email'
            );
            this.get_projects();
          });
      } catch (err) {
        this.$toasted.show('Error getting project managers', {
          type: 'error',
          duration: '3000',
        });
      }
    },
    redirectUser() {
      if (!this.fake_vendor) {
        this.$router.push('/vendor');
      } else {
        this.$router.push('/accountant/view-invoices');
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append('invoiceid', this.invoiceid);
      formData.append('companyid', this.companyId);
      formData.append('projectid', this.projectid);
    },
    deselectService(service, pu) {
      this.serviceSelectInModal = service;

      let index = this.preServiceData.findIndex(
        (services) => services.serviceid.serviceid == service.serviceid
      );
      let index1 = this.vendoreService_toggler.findIndex(
        (services) => services == service.serviceid
      );
      this.preServiceData.splice(index, 1);
      this.vendoreService_toggler.splice(index1, 1);
    },
    selectService(service, pu) {
      this.serviceSelectInModal = service;
      if (this.serviceSelectInModal.length == 0) {
        this.$refs['service-modal'].hide();
        return null;
      }

      const serviceTaxes = this.invoiceTaxes.map((val) => {
        return {
          tax_name: val.tax_name,
          isApplied: true,
          percentage: val.percentage,
        };
      });

      this.preServiceData.push(
        Vue.util.extend(
          {},
          {
            index: 0,
            serviceid: [],
            dates: {
              start:
                this.preServiceData.length > 0
                  ? this.preServiceData[this.preServiceData.length - 1].dates
                    .start
                  : this.vendorInvoiceDate,
              end:
                this.preServiceData.length > 0
                  ? this.preServiceData[this.preServiceData.length - 1].dates
                    .end
                  : this.vendorInvoiceDate,
            },
            uom: [],
            quantity: 0,
            discount: 0.0,
            vsid: 0,
            id: 0,
            percentage: 0,
            pu: 0,
            total: 0,
            comment: '',
            tax: true,
            toggleComment: false,
            serviceTaxes: serviceTaxes,
          }
        )
      );

      this.preServiceData[this.preServiceData.length - 1].uom = [
        this.serviceSelectInModal.pu1,
        this.serviceSelectInModal.uom1,
      ];
      this.preServiceData[
        this.preServiceData.length - 1
      ].serviceid = this.serviceSelectInModal;
      this.preServiceData[this.preServiceData.length - 1].selected = true;

      this.preServiceData[
        this.preServiceData.length - 1
      ].discounted_price_per_unit = this.serviceSelectInModal.pu1.toFixed(2);
      this.preServiceData[
        this.preServiceData.length - 1
      ].id = this.preServiceData.length;
      this.preServiceData[this.preServiceData.length - 1].index =
        this.preServiceData[this.preServiceData.length - 1].id - 1;
      this.vendoreService_toggler.push(service.serviceid);
    },
    setDates() {
      this.preServiceData.map((value, index) => {
        this.preServiceData[index].dates = this.allService_dates;
        this.setQuantity(index);
      });
    },
    setQuantity(i) {
      if (/day|Day/.test(this.preServiceData[i].uom)) {
        var dates = this.preServiceData[i].dates;
        var days = this.$moment(dates.end).diff(
          this.$moment(dates.start),
          'days'
        );
        var update = this.preServiceData[i];
        update.quantity = days + 1;

        Vue.set(this.preServiceData, i, update);
      }
    },
    gsttotal() {
      this.tax = this.tax_total.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      this.totalinvoice =
        Number(this.lineSubtotal) +
        Number(this.lineSubtotal * (this.tax_total / 100));
    },
    // taxtotal() {
    //   if (this.tax != 0 && this.totalinvoice != 0) {
    //     this.gst = (this.tax / this.lineSubtotal) * 100;
    //   } else {
    //     this.gst = 5;
    //   }
    // },
    getTotal(service) {
      const pu =
        service.serviceid.isflexible == 'N'
          ? service.uom[0]
          : service.serviceid.pu1;
      const total =
        service.quantity * pu -
        (service.discount / 100) * service.quantity * pu;
      service.total = total;
      return total;
    },
    calculateTax(tax_name, isQuickPay) {
      const taxes = this.serviceData.reduce((acc, item) => {
        const required_tax = item.serviceTaxes.filter(
          (el) => el.isApplied && el.tax_name === tax_name
        );
        const total =
          item.quantity * item.pu -
          (item.discount / 100) * item.quantity * item.pu;
        const percentage =
          required_tax.length > 0 ? Number(required_tax[0].percentage) : 0;
        let quickpay = 1;
        if (isQuickPay) {
          quickpay = 1 - this.quickpay / 100;
        }
        const check = (percentage / 100) * total * quickpay;
        return (acc += check);
      }, 0);
      return Number(taxes);
    },
    handleBlur() {
      this.tax = this.tax.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    },
    updateEditQuantity(service) {
      var index = this.serviceData.findIndex((x) => x.vsid == service.vsid);
      if (service.dates === null) service.dates = this.serviceData[index].dates;
      if (/day|DAY|Day/.test(this.serviceData[index].uom)) {
        var dates = this.serviceData[index].dates;
        var days = this.$moment(dates.end).diff(
          this.$moment(dates.start),
          'days'
        );
        this.serviceData[index].quantity = days + 1;
      }
    },
    async importPreset() {
      this.preServiceData = [];
      this.disableImport = true;
      this.preset.sids = JSON.parse(this.preset.sids);
      var vwrefIndex = this.uwiData.findIndex(
        (x) => x.uwi == this.preset.sids[0].uwi
      );
      this.vwref = this.uwiData[vwrefIndex];

      this.gst = this.preset.tax_percentage;
      this.comments = this.preset.vendor_note;
      this.quickpay = this.preset.discount;
      this.days = this.preset.sids[0].endtday;
      for (var i = 0; i < this.preset.sids.length; i++) {
        var match = this.vendorServices.filter(
          (y) => y.serviceid == this.preset.sids[i].serviceid
        );
        var service = {
          discounted_price_per_unit:
            this.preset.sids[i].pu * (1 - this.preset.sids[i].discount / 100),
          discount: this.preset.sids[i].discount,
          pu: this.preset.sids[i].pu,
          type: this.preset.sids[i].service_type,
          name: this.preset.sids[i].service_name,
          quantity: this.preset.sids[i].quantity,
          serviceid: match[0],
          id: this.preset.sids[i].serviceid,
          dates: {
            start: this.$moment(this.preset.sids[i].start_date),
            end: this.$moment(this.preset.sids[i].end_date),
          },
          uom: [this.preset.sids[i].pu, this.preset.sids[i].uom],
          afes: this.preset.sids[i].afes,
          comment:
            this.preset.sids[i].comment === undefined ||
              this.preset.sids[i].comment.replace(/\s/g, '').length === 0
              ? ''
              : this.preset.sids[i].comment,
          tax:
            this.preset.sids[i].tax === undefined
              ? true
              : this.preset.sids[i].tax,
          serviceTaxes: JSON.parse(this.preset.sids[i].applied_taxes),
          toggleComment:
            this.preset.sids[i].comment === undefined ||
              this.preset.sids[i].comment.replace(/\s/g, '').length === 0
              ? false
              : true,
        };
        if (this.changeDates == 1) {
          service['dates'] = {
            start: this.$moment(this.preset_date.start),
            end: this.$moment(this.preset_date.end),
          };
        }
        const vsid = await this.importPreService(
          service,
          i,
          this.preset.sids[i].serviceid.isflexible
        );
        for (var x = 0; x < this.preset.sids[i].afes.length; x++) {
          service['vsid'] = vsid;
          this.percentage = this.preset.sids[i].afes[x].percentage;
          this.CC_data = {
            afenum: this.preset.sids[i].afes[x].afenum,
            costcode1: this.preset.sids[i].afes[x].ccone,
            costcode2: this.preset.sids[i].afes[x].cctwo,
            costcode3: this.preset.sids[i].afes[x].ccthree,
          };
          this.createAfenum = {
            afenum: this.preset.sids[i].afes[x].afenum,
            items: [
              {
                pwcaid: this.preset.sids[i].afes[x].pwcaid,
                afenum: this.preset.sids[i].afes[x].afenum,
              },
            ],
          };
          await this.addAfeUnassignedLine(service);
        }
      }
      this.gsttotal();
      this.$refs['import-modal'].hide();
    },
    importPreService(service, i) {
      if (service.serviceid && service.uom) {
        let data = {
          serviceid: service.serviceid.serviceid,
          start_date: this.$moment(service.dates.start).format('YYYY-MM-DD'),
          end_date: this.$moment(service.dates.end).format('YYYY-MM-DD'),
          // discounted_price_per_unit: service.discounted_price_per_unit,
          discount: service.discount,
          projectid: this.projectid,
          quantity: service.quantity,
          uom: service.uom[1],
          pu: service.uom[0],
          currency: service.serviceid.currency,
          tax: service.tax,
          comment:
            this.preset.sids[i].comment === undefined ||
              this.preset.sids[i].comment.replace(/\s/g, '').length === 0
              ? ''
              : service.comment,
          applied_taxes: JSON.stringify(service.serviceTaxes),
        };

        if (this.fake_vendor) {
          data.fake_vid = this.fake_vendor.vid;
        }

        return this.$axios
          .post('/vendor/create/service/update?update=false', data)
          .then((response) => {
            var total = this.formatPrice(
              service.quantity * service.uom[0] -
              (service.discount / 100) * service.quantity * service.uom[0]
            );

            var uom = [service.uom[0], service.uom[1]];
            var number = 0;
            if (this.serviceData.length != 0) {
              number = this.serviceData[this.serviceData.length - 1].number + 1;
            }
            this.serviceData.push({
              vsid: response.data.vsid,
              id: service.serviceid.serviceid,
              serviceid: service.serviceid,
              name: service.serviceid.name,
              type: service.serviceid.type,
              dates: {
                start: service.dates.start.toDate(),
                end: service.dates.end.toDate(),
              },
              discounted_price_per_unit:
                service.uom[0] * (1 - service.discount / 100),
              discount: service.discount,
              percentage: 0,
              quantity: service.quantity,
              uom: uom,
              pu: service.uom[0],
              total: total,
              tax: service.tax,
              serviceTaxes: service.serviceTaxes,
              comment:
                service.comment.replace(/\s/g, '').length === 0
                  ? ''
                  : service.comment,
              toggleComment:
                service.comment.replace(/\s/g, '').length === 0 ? false : true,
            });
            if (i) {
              Vue.delete(this.preServiceData, i);
            }
            var vsid = response.data.vsid;

            return vsid;
          })
          .catch((error) => { });
      }
    },
    showImportModal() {
      this.$refs['import-modal'].show();
      this.disableImport = false;
    },
    showServiceModal(i, type, flag) {
      if (flag == 'flag') {
        this.serviceModalIndex = i;
        this.$refs['service-modal'].show();
      }
    },

    editData(line) {
      if (this.beforeEditCacheCatch == 1 || this.beforeEditAfeCacheCatch == 1) {
        this.$toasted.show('Please save edit first', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      this.beforeEditCache = Object.assign({}, line);
      this.editedLine = line;
      this.beforeEditCacheCatch = JSON.parse(JSON.stringify(1));
    },
    cancelEdit(index) {
      this.serviceData[this.beforeEditCacheIndex] = this.beforeEditCache;
    },
    cancelAfe: function () {
      this.beforeEditAfeCacheCatch = 0;
      this.addToInvoice = 1;
      this.addAfeButtons = 1;
      this.addAfeShow = 0;
      this.serviceChainData = [];
      this.search = '';
    },
    getAllInvoices() {
      this.$axios
        .get('/vendor/get/invoices/' + this.vendorId)
        .then((response) => {
          this.allInvoices = response.data.result;
        })
        .catch((error) => { });
    },
    async submitInvoice(draft) {
      this.afePercentage = [];
      if (this.merged.length > 0) {
        this.merged.forEach((val, i) => {
          let sum = 0;
          val[0].afes.forEach((data, j) => {
            sum = sum + Number(data.percentage);
            if (j === val[0].afes.length - 1 && sum !== 100) {
              this.afePercentage.push(val[0].vsid);
            }
          });
        });
        if (this.afePercentage.length > 0) {
          this.$toasted.show(
            'Coding percentage is not 100%, please review coding percentage.',
            { type: 'error', duration: '3000' }
          );
          return;
        }
      }

      if (this.vwref === 1) {
        this.$toasted.show('Please select well', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      if (this.fieldTicketNumber) {
        const tickets = `${this.fieldTicketNumber}`
          .replaceAll('/', ',')
          .trim()
          .split(',')
          .map((val) => val.trim());
        const existed_data = this.allInvoices
          .flatMap((val) =>
            `${val.ticketnum}`
              .replaceAll('/', ',')
              .trim()
              .split(',')
          )
          .map((val) => val.trim());
        this.existedTickets = tickets.filter((val) =>
          existed_data.includes(val)
        );
        if (this.existedTickets.length > 0) {
          this.$refs['field-ticket-modal'].show();
          return;
        }
      }
      if (this.fieldTicketNumber.length > 300) {
        this.$toasted.show('Field Ticket Number Exceeds Character Limit.', {
          type: 'error',
          duration: '3000',
        });

        return;
      }
      if (this.vendorInvoiceNumber) {
        if (
          this.allInvoices.filter((val) =>
            this.vendorInvoiceNumber
              .split(',')
              .includes(val.vendor_invoice_number)
          ).length > 0
        ) {
          this.$toasted.show('Duplicate Vendor Invoice Number.', {
            type: 'error',
            duration: '3000',
          });
          return;
        } else if (this.vendorInvoiceNumber.length > 20) {
          this.$toasted.show('Vendor Invoice Number Exceeds Character Limit.', {
            type: 'error',
            duration: '3000',
          });
          return;
        }
      }

      if (this.vendorInvoiceNumber.trim().length === 0) {
        this.$toasted.show('Vendor Invoice Number is required.', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      if (this.serviceOrderNumber.length > 45) {
        this.$toasted.show('Service Order Number Exceeds Character Limit.', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      if (this.programNumber.length > 45) {
        this.$toasted.show('Programme Number Exceeds Character Limit.', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      if (this.treatmentType.length > 45) {
        this.$toasted.show('Treatment Type Exceeds Character Limit.', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      if (this.serviceData.length === 0) {
        this.$toasted.show('Please add services', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      if (this.beforeEditCacheCatch == 1) {
        this.$toasted.show('Please save service', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      var comments =
        this.usersName + ' ($' + this.formatPrice(this.totalinvoice) + ')';
      if (this.comments.replace(/\s/g, '') != '') {
        comments += ': ' + this.comments;
        this.hasComment = false;
      } else {
        this.hasComment = true;
        return;
      }

      var question = 'Are you sure you want to submit this invoice?';

      if (draft == 1) {
        question = 'Are you sure you want to save as draft?';
      }

      if (confirm(question)) {
        if (draft == 0 && this.fake_vendor) {
          for (var i = 0; i < this.serviceData.length; i++) {
            var temp = this.afes.filter(
              (x) => x.vsid == this.serviceData[i].vsid
            );

            if (temp.length == 0) {
              this.$toasted.show(
                'AFE or Cost Center / Well Table Not Complete. ' +
                this.serviceData[i].name +
                ' does not have AFE or Cost Center / Well assigned.',
                { type: 'error', duration: '3000' }
              );
              return;
            }
          }
        }

        var qp = 0;
        if (this.quickpay > 0) {
          qp = 1;
          if (this.days === '') {
            this.$toasted.show('Please add net days', {
              type: 'error',
              duration: '3000',
            });
            return;
          }
        }
        var endtday = this.$moment(new Date(), 'YYYY-MM-DD').add(
          this.days,
          'days'
        );
        var vsIds = [];

        var arrayLength = this.serviceData.length;

        for (var i = 0; i < arrayLength; i++) {
          vsIds.push(this.serviceData[i].vsid);
        }

        let data = {
          services: vsIds,
          draft: draft,
          companyid: this.companyId,
          projectid: this.projectid,
          tax_percentage: this.total_tax_percentage,
          vwref: this.vwref.uwi,
          vendor_note: comments,
          quickpay_total: this.quickpaysubtotal + this.qpgstsubtotal,
          qpgstsubtotal: this.qpgstsubtotal,
          discount: this.quickpay,
          quickpay: qp,
          endtday: endtday.format('YYYY-MM-DD'),
          field_ticket_number: this.fieldTicketNumber || null,
          vendor_invoice_number: this.vendorInvoiceNumber || null,
          service_order_number: this.serviceOrderNumber || null,
          program_number: this.programNumber || null,
          treatment_type: this.treatmentType || null,
          vendor_invoice_date: this.vendorInvoiceDate
            ? this.$moment(this.vendorInvoiceDate).toISOString()
            : null,
          invoiceTo:
            this.invoiceTo.length > 0
              ? this.invoiceTo.map((val) => val.email).join(',')
              : null,
          invoice_taxes: JSON.stringify(this.invoiceTaxes),
        };

        if (this.fake_vendor) {
          data.fake_vid = this.fake_vendor.vid;
          data.force_inv_passed = 6;
          data.direct = this.directRegular;
        }

        if (!this.fake_vendor) {
          this.$axios
            .post('/vendor/create/invoice', data)
            .then(async (response) => {
              if (!draft) {
                const history_data = {
                  role: 'Vendor',
                  role_id: this.vendorId,
                  userName: this.usersName,
                  comments: this.comments,
                  action_desc: 'Submitted',
                  action_status: 2, //2 for submitted
                  invoiceData: {
                    invoiceid: response.data.invoiceid,
                    companyid: this.companyId,
                    projectid: this.projectid,
                    vid: this.vendorId,
                    projectname: this.vwref.projectname,
                    msg: 'First Submission',
                    InvoiceStatus: 0,
                  },
                };
                await this.createInvoiceHistory(history_data);
              }
              this.$toasted.show('Invoice Created Successfully', {
                type: 'success',
                duration: '3000',
              });
              var files = this.$refs.myVueDropzone.getQueuedFiles();
              if (files.length == 0) {
                this.$router.push('/vendor');
              } else {
                this.invoiceid = response.data.invoiceid;
                this.$refs.myVueDropzone.processQueue();
              }
            })
            .catch((error) => {
              console.log(error, ':::error');
              this.invoiceError = true;
            });
        } else {
          this.$axios
            .post('/vendor/create/invoice/fake', data)
            .then((response) => {
              console.log(response.data);
              this.$toasted.show('Invoice Created Successfully', {
                type: 'success',
                duration: '3000',
              });
              this.invoiceError = false;

              this.invoiceid = response.data.invoiceid;
              var files = this.$refs.myVueDropzone.getQueuedFiles();
              console.log(files);
              if (files.length == 0) {
                this.$router.push('/accountant/view-invoices');
              }
            })
            .catch((error) => {
              this.invoiceError = true;
            });
        }
      }
    },
    savePreset(draft) {
      if (this.vwref === 1) {
        this.$toasted.show('Please select well', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      if (this.it_name === '') {
        this.$toasted.show('Please add preset name', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      if (this.serviceData.length === 0) {
        this.$toasted.show('Please add services', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      if (this.beforeEditCacheCatch == 1) {
        this.$toasted.show('Please save service', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      var qp = 0;
      if (this.quickpay > 0) {
        qp = 1;
        if (this.days === '') {
          this.$toasted.show('Please add net days', {
            type: 'error',
            duration: '3000',
          });
          return;
        }
      }
      var sids = [];
      for (var i = 0; i < this.serviceData.length; i++) {
        var temp = {
          serviceid: this.serviceData[i].serviceid.serviceid,
          start_date: this.serviceData[i].dates.start,
          end_date: this.serviceData[i].dates.end,
          quantity: this.serviceData[i].quantity,
          uom: this.serviceData[i].uom[1],
          pu: this.serviceData[i].pu,
          vsid: this.serviceData[i].vsid,
          discount: this.serviceData[i].discount,
          service_name: this.serviceData[i].serviceid.name,
          service_type: this.serviceData[i].serviceid.type,
          endtday: this.days,
          ln: this.vwref.ln,
          uwi: this.vwref.uwi,
          lsdsurface: this.vwref.lsdsurface,
          lsdbottom: this.vwref.lsdbottom,
          isflexible: this.serviceData[i].serviceid.isflexible,
          comment:
            this.serviceData[i].comment.replace(/\s/g, '').length === 0
              ? ''
              : this.serviceData[i].comment,
          tax: this.serviceData[i].tax,
          applied_taxes: JSON.stringify(this.serviceData[i].serviceTaxes),
        };
        var afeTemp = this.afes.filter(
          (x) => x.vsid == this.serviceData[i].vsid
        );
        var afes = [];
        for (var x = 0; x < afeTemp.length; x++) {
          afes.push({
            percentage: afeTemp[x].percentage,
            service_ccid: afeTemp[x].service_ccid,
            category: afeTemp[x].category,
            afenum: afeTemp[x].afenum,
            ccone: afeTemp[x].ccone_code,
            cctwo: afeTemp[x].cctwo_code,
            ccthree: afeTemp[x].ccthree_code,
            vsid: this.serviceData[i].vsid,
            pwcaid: afeTemp[x].pwcaid,
          });
        }
        temp['afes'] = afes;
        sids.push(temp);
      }

      let data = JSON.stringify({
        it_name: this.it_name,
        companyid: this.companyId,
        projectid: this.projectid,
        tax_percentage: this.total_tax_percentage,
        vendor_note: this.comments,
        currency: this.serviceData[0].serviceid.currency,
        project_name: this.vwref.projectname,
        company_name: this.companyInfo.company_name,
        ptn: this.vwref.ptn,
        vwref: this.vwref.uwi,
        discount: this.quickpay,
        quickpay: qp,
        endtday: this.$moment(new Date()),
        sids: sids,
      });

      this.$axios
        .post('/vendor/create/invoice/template', data)
        .then((response) => {
          this.$toasted.show('Preset Created Successfully', {
            type: 'success',
            duration: '3000',
          });
          this.invoiceError = false;
          this.$session.set('preset', '');
          if (this.$route.meta.is_preset) {
            this.$router.push('/vendor/presets');
          }
        })
        .catch((error) => {
          this.invoiceError = true;
        });
    },
    editPreSetService(service, i, isFlexible) {
      this.search = '';
      if (service.serviceid) {
        this.lineLoading = true;
        if (isFlexible === 'Y') {
          if (service.serviceid.uom1 == null) {
            this.$toasted.show(`Please add Uom for ${service.serviceid.name}`, {
              type: 'error',
              duration: '3000',
            });
            this.loading = false;
            return;
          } else if (service.serviceid.pu1 == 0) {
            this.$toasted.show(
              `Please add Price per unit for ${service.serviceid.name}`,
              { type: 'error', duration: '3000' }
            );
            this.loading = false;
            return;
          }
          service.uom = [Number(service.serviceid.pu1), service.serviceid.uom1];
        }
        var total = this.formatPrice(
          service.quantity * service.uom[0] -
          (service.discount / 100) * service.quantity * service.uom[0]
        );

        var uom = [service.uom[0], service.uom[1]];
        this.vsid = service.vsid;
        this.removePresetService(i, 1);
        var index = this.serviceData.findIndex((x) => x.vsid == service.vsid);
        Vue.set(this.serviceData, index, {
          vsid: service.vsid,
          id: service.serviceid.serviceid,
          serviceid: service.serviceid,
          dates: service.dates,
          discounted_price_per_unit:
            service.uom[0] * (1 - service.discount / 100),
          discount: service.discount,
          percentage: 0,
          quantity: service.quantity,
          uom: uom,
          pu: service.uom[0],
          total: total,
          name: service.serviceid.name,
          type: service.serviceid.type,
          comment:
            service.comment.replace(/\s/g, '').length === 0
              ? ''
              : service.comment,
          tax: service.tax,
          serviceTaxes: service.serviceTaxes,
          toggleComment:
            service.comment.replace(/\s/g, '').length === 0 ? false : true,
        });

        this.gsttotal();
        this.editedLine = { vsid: 0 };
        this.beforeEditCacheCatch = 0;
        this.loading = false;
      }
    },
    editService(service, isFlexible) {
      this.search = '';
      if (service.serviceid) {
        this.lineLoading = true;
        if (isFlexible == 'Y') {
          service.uom = [Number(service.serviceid.pu1), service.serviceid.uom1];
        }
        let data = {
          serviceid: service.serviceid.serviceid,
          start_date: this.$moment(service.dates.start).format('YYYY-MM-DD'),
          end_date: this.$moment(service.dates.end).format('YYYY-MM-DD'),
          discount: service.discount,
          projectid: this.projectid,
          type: service.type,
          quantity: service.quantity,
          uom: service.uom[1],
          pu: service.uom[0],
          comment:
            service.comment.replace(/\s/g, '').length === 0
              ? ''
              : service.comment,
          tax: service.tax,
          currency: service.serviceid.currency,
          vsid: service.vsid,
          vid: this.vendorId,
          applied_taxes: JSON.stringify(service.serviceTaxes),
        };

        this.$axios
          .post('/vendor/create/service/update/?update=true', data)
          .then(() => {
            var total = this.formatPrice(
              service.quantity * service.uom[0] -
              (service.discount / 100) * service.quantity * service.uom[0]
            );
            var uom = [service.uom[0], service.uom[1]];
            this.vsid = service.vsid;
            var index = this.serviceData.findIndex(
              (x) => x.vsid == service.vsid
            );
            Vue.set(this.serviceData, index, {
              vsid: service.vsid,
              id: service.serviceid.serviceid,
              serviceid: service.serviceid,
              dates: service.dates,
              discounted_price_per_unit:
                service.uom[0] * (1 - service.discount / 100),
              discount: service.discount,
              percentage: 0,
              quantity: service.quantity,
              uom: uom,
              pu: service.uom[0],
              total: total,
              name: service.serviceid.name,
              type: service.serviceid.type,
              comment:
                service.comment.replace(/\s/g, '').length === 0
                  ? ''
                  : service.comment,
              toggleComment:
                service.comment.replace(/\s/g, '').length === 0 ? false : true,
              tax: service.tax,
              serviceTaxes: service.serviceTaxes,
            });

            this.gsttotal();
            this.editedLine = { vsid: 0 };
            this.beforeEditCacheCatch = 0;
            this.lineLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    CheckDates() {
      const vendor_invoice_date = this.$moment(this.vendorInvoiceDate).format(
        'YYYY-MM-DD'
      );
      this.preServiceData.forEach((service, i) => {
        const enddate = this.$moment(service.dates.end).format('YYYY-MM-DD');
        if (!this.$moment(enddate).isSameOrBefore(vendor_invoice_date)) {
          this.$toasted.show(
            `Vendor service date exceedes vendor invoice date for ${service.serviceid.name}`,
            { type: 'error', duration: '3000' }
          );
          this.$toasted.show(
            `Vendor service date updated for ${service.serviceid.name}`,
            { type: 'info', duration: '3000' }
          );
          this.preServiceData[i].dates = {
            start: this.vendorInvoiceDate,
            end: this.vendorInvoiceDate,
          };
          this.setQuantity(i);
          return;
        }
      });
    },
    async addServices(services) {
      let data = [];
      for (
        var i = 0;
        i <= services.length - 1 && !this.$route.meta.is_preset;
        i++
      ) {
        if (services[i].serviceid.isflexible !== 'N') {
          if (services[i].serviceid.uom1 == null) {
            this.$toasted.show(
              `Please add Uom for ${services[i].serviceid.name}`,
              {
                type: 'error',
                duration: '3000',
              }
            );
            continue;
          } else if (services[i].serviceid.pu1 == 0) {
            this.$toasted.show(
              `Please add Price per unit for ${services[i].serviceid.name}`,
              { type: 'error', duration: '3000' }
            );
            continue;
          }
          services[i].uom = [
            Number(services[i].serviceid.pu1),
            services[i].serviceid.uom1,
          ];
        }
        data.push({
          serviceid: services[i].serviceid.serviceid,
          start_date: this.$moment(services[i].dates.start).format(
            'YYYY-MM-DD'
          ),
          end_date: this.$moment(services[i].dates.end).format('YYYY-MM-DD'),
          discount: services[i].discount,
          projectid: this.projectid,
          quantity: services[i].quantity,
          uom: services[i].uom[1],
          pu: services[i].uom[0],
          currency: services[i].serviceid.currency,
          comment:
            services[i].comment.replace(/\s/g, '').length === 0
              ? ''
              : services[i].comment,
          tax: services[i].tax,
          vid: this.vendorId,
          companyid: this.companyId,
          applied_taxes: JSON.stringify(services[i].serviceTaxes),
        });
      }
      try {
        let responseData = [];

        if (this.$route.meta.is_preset) {
          responseData = services;
        } else {
          const response = await this.$axios.post(
            '/vendor/create/service',
            data
          );
          // console.log(data, '<<<<<<>>>');
          responseData = response.data.service_data;
        }

        responseData.forEach((val, i) => {
          const service = this.$route.meta.is_preset
            ? val
            : services.filter(
              (el) => el.serviceid.serviceid === val.serviceid
            )[0];
          var total = this.formatPrice(
            service.quantity * service.uom[0] -
            (service.discount / 100) * service.quantity * service.uom[0]
          );
          var uom = [service.uom[0], service.uom[1]];
          this.serviceData.push({
            vsid: this.$route.meta.is_preset
              ? this.serviceData.length +
              service.serviceid.serviceid +
              Math.random() * 10
              : val.vsid,
            id: this.$route.meta.is_preset
              ? val.serviceid.serviceid
              : val.serviceid,
            name: service.serviceid.name,
            serviceid: service.serviceid,
            type: service.serviceid.type,
            dates: service.dates,
            discounted_price_per_unit:
              service.uom[0] * (1 - service.discount / 100),
            discount: service.discount,
            percentage: 0,
            quantity: service.quantity,
            uom: uom,
            pu: service.uom[0],
            total: total,
            comment:
              service.comment.replace(/\s/g, '').length === 0
                ? ''
                : service.comment,
            toggleComment:
              service.comment.replace(/\s/g, '').length === 0 ? false : true,
            tax: service.tax,
            serviceTaxes: service.serviceTaxes,
          });

          this.gsttotal();
          let index = this.preServiceData.findIndex(
            (services) =>
              services.serviceid.serviceid == service.serviceid.serviceid
          );
          let index1 = this.vendoreService_toggler.findIndex(
            (services) => services == service.serviceid.serviceid
          );
          this.preServiceData.splice(index, 1);
          this.vendoreService_toggler.splice(index1, 1);
          this.removeComments(service);
        });
      } catch (err) {
        console.log(err, ':::err');
        this.$toasted.show('Error Adding Service', {
          type: 'error',
          duration: '3000',
        });
      }
    },
    dragOver(e) { },
    async serviceOnDrop(service, line) {
      if ('afenum' in this.createAfenum && 'afenum' in this.CC_data) {
        this.addAfeUnassignedLine(service);
      } else {
        if (line) {
          this.attachAfes(line);
          this.addAfeUnassignedLine(this.dragStartService);
        } else {
          if (service.afes.length === 0) return;
          this.groupAssigneAfes(this.dragStartService, service.afes);
        }
      }
    },
    afeDragStart(line, service) {
      if (line) {
        this.attachAfes(line);
      } else {
        this.createAfenum = {};
        this.CC_data = {};
        this.dragStartService = service;
      }
    },
    attachAfes(line) {
      this.percentage = line.percentage;
      this.createAfenum = {
        afenum: line.afenum,
        items: [{ pwcaid: line.pwcaid, afenum: line.afenum }],
      };
      this.CC_data = {
        afenum: line.afenum,
        companyid: this.companyId,
        costcode1: line.ccone_code,
        costcode2: line.cctwo_code,
        costcode3: line.ccthree_code,
      };
    },
    deletePreLineServices(service, i) {
      let index1 = this.vendoreService_toggler.findIndex(
        (services) => services == this.preServiceData[i].serviceid.serviceid
      );
      this.vendoreService_toggler.splice(index1, 1);
      Vue.delete(this.preServiceData, i);
      this.lineComments = this.lineComments.filter(
        (val) => val !== service.serviceid.name
      );
    },
    removePresetService(index, edit, fullDelete) {
      var changed = 1;

      if (this.serviceData[index]) {
        var id = this.serviceData[index].id;
        var afesToRemove = this.afes.filter((x) => x.id === id);

        for (var i = 0; i < afesToRemove.length; i++) {
          if (
            this.beforeEditCache.serviceid &&
            this.serviceData[index].serviceid.serviceid ==
            this.beforeEditCache.serviceid.serviceid &&
            fullDelete != 1
          ) {
            changed = 0;
            this.removePresetAfe(
              0,
              afesToRemove[i].apid,
              afesToRemove[i],
              changed
            );
          } else {
            changed = 1;
            this.removePresetAfe(
              0,
              afesToRemove[i].apid,
              afesToRemove[i],
              changed
            );
          }
        }
      }
      if (edit != 1) {
        this.serviceData.splice(index, 1);
      }
      // this.gsttotal();
    },
    deleteServiceAfe(line, service) {
      const service_ccid = this.afes
        .filter(
          (val) =>
            val.pwcaid === line.pwcaid &&
            val.vsid === service.vsid &&
            val.ccone_code === line.ccone_code &&
            val.cctwo_code === line.cctwo_code &&
            val.ccthree_code === line.ccthree_code
        )
        .map((val) => val.service_ccid)[0];
      this.$axios
        .delete('/vendor/delete/service_ap/service_ccid/' + service_ccid)
        .then(() => {
          this.afes = this.afes.filter(
            (val) => val.service_ccid !== service_ccid
          );
          this.$toasted.show('Coding Removed', {
            type: 'success',
            duration: '3000',
          });
        })
        .catch(() => {
          this.$toasted.show('Error Removing Coding', {
            type: 'error',
            duration: '3000',
          });
        });
    },
    deleteService(service, flag) {
      // flag = 0 for services without afe;
      // falg = 1 for services with afe;
      const index = this.serviceData.findIndex((x) => x.vsid == service.vsid);
      if (flag === 0) {
        this.$axios
          .delete('/vendor/create/service/' + service.vsid)
          .then(() => {
            this.serviceData.splice(index, 1);
            this.$toasted.show('Service Removed', {
              type: 'success',
              duration: '3000',
            });
          })
          .catch(() => {
            this.$toasted.show('Error Removing Services', {
              type: 'error',
              duration: '3000',
            });
          });
      } else {
        this.$axios
          .delete(
            '/vendor/delete/service_ap/projectid/' +
            this.projectid +
            '/vsid/' +
            service.vsid
          )
          .then(() => {
            this.afes = this.afes.filter((val) => val.vsid !== service.vsid);
            this.serviceData.splice(index, 1);
            this.$toasted.show('Service Removed with Coding', {
              type: 'success',
              duration: '3000',
            });
          })
          .catch(() => {
            this.$toasted.show('Error Removing Services', {
              type: 'error',
              duration: '3000',
            });
          });
      }
      if (this.serviceData.length == 0) {
        this.gst = 5;
      }
      this.beforeEditCacheCatch = 0;
      this.beforeEditAfeCacheCatch = 0;
      this.gsttotal();
    },
    addAfeToInvoice: function (afe) {
      if (this.percent > 100 || this.percent <= 0) {
        this.$toasted.show('AFE or Cost Code or coding Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      var cc_num = [];
      if (this.cc3 !== undefined && this.cc3 !== null) {
        cc_num = this.cc3.items[0];
      } else {
        cc_num = this.cc2.items[0];
      }
      let data = {
        id: this.vsid,
        projectid: this.projectid,
        service_ccid: cc_num.service_ccid,
        percentage: this.percent,
      };

      if (this.fake_vendor) {
        data.fake_vid = this.fake_vendor.vid;
      }

      this.$axios
        .post('/vendor/create/service_ap', data)
        .then((response) => {
          this.afes.push({
            service: this.servicenameSort,
            afe: cc_num.afenum,
            id: cc_num.serviceid,
            percentage: this.percent,
            apid: response.data.apid,
            service_ccid: cc_num.service_ccid,
            vsid: this.vsid,
            ccone_code: cc_num.ccone_code,
            cctwo_code: cc_num.cctwo_code,
            ccthree_code: cc_num.ccthree_code,
            pwcaid: this.createAfenum.items[0].pwcaid,
          });

          this.afesSorted = this.lodash
            .chain(this.afes)
            .groupBy('service')
            .map((service, code) => ({ service, code }))
            .sortBy('code')
            .value();

          this.addAfeShow = 0;
          this.afe = [];
          this.addAfeButtons = 1;
          this.beforeEditAfeCacheCatch = 0;
        })
        .catch((error) => {
          this.$toasted.show('AFE or Cost Center / Well Duplicate', {
            type: 'error',
            duration: '3000',
          });
        });
    },
    removePresetAfe(index, apid, na, changed) {
      var find = this.afes.findIndex(function (item, i) {
        return item.apid === apid;
      });

      if (changed == 0) {
        this.afes[find] = {
          service: this.afes[find].service,
          afe: this.afes[find].afe,
          percentage: this.afes[find].percentage,
          service_ccid: this.afes[find].service_ccid,
          ccone_code: this.afes[find].ccone_code,
          cctwo_code: this.afes[find].cctwo_code,
          ccthree_code: this.afes[find].ccthree_code,
        };

        this.afesSorted = this.lodash
          .chain(this.afes)
          .groupBy('service')
          .map((service, code) => ({ service, code }))
          .sortBy('code')
          .value();
      } else {
        Vue.delete(this.afes, find);
        this.afesSorted = this.lodash
          .chain(this.afes)
          .groupBy('service')
          .map((service, code) => ({ service, code }))
          .sortBy('code')
          .value();
      }
    },
    async get_projects() {
      this.vendorServices = [];
      this.loadingCompany = true;
      this.get_company();
      // this.setDeafultTaxes();
      if (this.fake_vendor) {
        await this.$axios
          .get(
            '/vendor/get/allowed_projects/' +
            this.companyId +
            '/' +
            this.fake_vendor.vid
          )
          .then((response) => {
            if (response.status === 200) {
              this.projectData = response.data.result;
              this.price_per_unit = response.data.result[0].pu1.toFixed(2);
              if (this.projectData.length > 0) {
                this.getUwis();
              }
              this.loadingCompany = false;
            }
          });
      } else {
        await this.$axios
          .get('/vendor/get/allowed_projects/' + this.companyId)
          .then((response) => {
            if (response.status === 200) {
              this.projectData = response.data.result;

              this.price_per_unit = response.data.result[0].pu1.toFixed(2);

              if (this.projectData.length > 0) {
                this.getUwis();
              }
              this.loadingCompany = false;
            }
          });
      }
    },
    async get_services() {
      this.selectedProject = this.projectData.filter(
        (x) => x.projectid == this.projectid
      );
      if (!this.fake_vendor) {
        await this.$axios
          .get(
            '/vendor/get/allowed_services/' +
            this.companyId +
            '/' +
            this.projectid
          )
          .then((response) => {
            if (response.status === 200) {
              this.vendorServices = response.data.result;
              if (this.vendorServices.length > 0) {
                this.preServiceData = [];
              }
            }
          })
          .catch((error) => { });
      } else {
        await this.$axios
          .get(
            '/vendor/get/allowed_services/' +
            this.companyId +
            '/' +
            this.projectid +
            '/' +
            this.fake_vendor.vid
          )
          .then((response) => {
            if (response.status === 200) {
              this.vendorServices = response.data.result;
              if (this.vendorServices.length > 0) {
                this.preServiceData = [];
              }
            }
          })
          .catch((error) => { });
      }
    },
    selectWell() {
      this.projectid = this.vwref.projectid;
      this.projectname = this.vwref.projectname;
      this.checkWssPM();
      this.get_services();
      this.get_presets();
    },
    async getUwis(services) {
      if (!this.fake_vendor) {
        this.$axios
          .get('/vendor/get/service_chains/' + this.companyId)
          .then((response) => {
            this.uwiData = response.data.result.filter((x) =>
              this.projectData.some((y) => x.projectid == y.projectid)
            );
            this.uwiData.forEach(
              (val) =>
              (val.uwi_4 = `${!val.uwi_2 ? val.uwi : !val.uwi_3 ? val.uwi_2 : val.uwi_3
                }--${val.projectname}`)
            );
            this.selectPm();
            this.get_services();
            this.get_presets();
          })
          .catch((error) => { });
      } else {
        for (var i = 0; i < services.length; i++) {
          this.$axios
            .get(
              '/vendor/get/service_chains/' +
              this.companyId +
              '/' +
              this.projectid +
              '/' +
              services[i].serviceid +
              '/' +
              this.fake_vendor.vid
            )
            .then((response) => {
              this.uwiData = this.uwiData.concat(response.data.result);
              if (i === services.length) {
                var temp = this.lodash.uniqBy(this.uwiData, 'uwi');
                this.uwiData = temp.filter(
                  (x) => x.projectid === this.projectid
                );
              }
            })
            .catch((error) => { });
        }
      }
    },
    get_presets() {
      this.$axios
        .get(
          '/vendor/get/invoice/templates/' +
          this.companyId +
          '/' +
          this.projectid
        )
        .then((response) => {
          this.presetData = response.data.result;
          // console.log(this.presetData,"preset data");
        })
        .catch((error) => { });
    },
    get_company() {
      this.$http
        .get('/get/company/avatar/' + this.companyId)
        .then((response) => {
          if (response.data.avatar) {
            this.avatar = response.data.avatar;
          }
        })
        .catch((error) => {
          //console.log("Error getting avatar " + error);
        });
      if (this.fake_vendor) {
        this.$axios
          .get(
            '/vendor/get/company_address/v2/' +
            this.companyId +
            '/' +
            this.fake_vendor.vid
          )
          .then((response) => {
            this.companyInfo = response.data;
          })
          .catch((error) => { });
      } else {
        this.$axios
          .get('/vendor/get/company_address/' + this.companyId)
          .then((response) => {
            this.companyInfo = response.data;
          })
          .catch((error) => { });
      }
    },
    removeAll() {
      this.loading = true;
      const data = {
        service_ccid: this.afes.map((val) => val.service_ccid),
        projectid: this.vwref.projectid,
        vid: this.vendorId,
      };
      this.$axios
        .post('/vendor/delete/service_ap', data)
        .then((response) => {
          this.afes = [];
          this.createdServiceLine = [];
          this.createdUnassignedServiceLine = [];
          this.disableEdits = 0;
          this.loading = false;
        })
        .catch((error) => { });
    },
    async editAfeLine(afe, serviceIndex, afeIndex, afes, service) {
      if (
        this.disableEdits == 1 ||
        this.beforeEditCacheCatch == 1 ||
        this.beforeEditAfeCacheCatch == 1
      ) {
        this.$toasted.show('Please save edit first.', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      this.editAfeChainLine = {
        afe_num: afe.afenum,
        ccone_code: afe.ccone_code,
        cctwo_code: afe.cctwo_code,
        ccthree_code: afe.ccthree_code,
        percentage: afe.percentage,
        pwcaid: afe.pwcaid,
      };
      this.editedAfeLine = afe;

      if (!this.fake_vendor) {
        this.$axios
          .get(
            '/vendor/get/service_chains/' +
            this.companyId +
            '/' +
            this.projectid +
            '/' +
            service[0].id +
            '/' +
            1 +
            '/?vid=' +
            this.vendorId
          )
          .then((response) => {
            if (response.data.result.length > 0) {
              this.percentage = afe.percentage;
              this.disableEdits = 1;
              var result = this.lodash.groupBy(response.data.result, 'afenum');
              this.serviceChainData = Object.keys(result).map((key) => ({
                afenum: key,
                items: result[key],
              }));
              var currentAfe = this.serviceChainData.findIndex(
                (x) => x.afenum == afe.afenum
              );
              this.merged[serviceIndex][0].afes[
                afeIndex
              ].afenum = this.serviceChainData[currentAfe];
            } else {
              this.createdServiceLine = 0;
              this.$toasted.show('No AFE or Cost Center / WellS Found', {
                type: 'error',
                duration: '3000',
              });
              return;
            }
            this.search = '';
            this.cc1Data = response.data.cost_code_chain_data;
            var cc1Index = this.cc1Data.findIndex(
              (x) =>
                x.costcode2 == afe.cctwo_code &&
                x.costcode3 == this.editAfeChainLine.ccthree_code
            );
            this.CC_data = this.cc1Data[cc1Index];
            this.oldCcData = this.cc1Data[cc1Index];
            this.editAfe = 1;
          })
          .catch((err) => { });
      }
    },
    cancelEditAfe(line, service, serviceIndex, afeIndex, afes) {
      const {
        ccthree_code,
        ccone_code,
        afe_num,
        cctwo_code,
        pwcaid,
      } = this.editAfeChainLine;
      service.forEach((val) => {
        let find = this.afes.findIndex(
          (x) =>
            x.vsid == val.vsid &&
            x.ccthree_code == ccthree_code &&
            x.ccone_code == ccone_code &&
            x.cctwo_code == cctwo_code &&
            x.afenum == afe_num
        );
        Vue.set(this.afes, find, {
          afenum: afe_num,
          ccone_code: ccone_code,
          cctwo_code: cctwo_code,
          ccthree_code: ccthree_code,
          percentage: this.percentage,
          service_ccid: this.afes[find].service_ccid,
          apid: this.afes[find].apid,
          vsid: val.vsid,
          pwcaid: pwcaid,
        });
      });
      this.createdUnassignedServiceLine = 0;
      this.disableEdits = 0;
      this.editAfe = 0;
    },
    async editAddAfeData(line, service, serviceIndex, afeIndex, afes) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE or Cost Code or coding Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      let cc_num = this.CC_data;
      let isDuplicate = false;
      service.forEach((val) => {
        for (let [i, v] of Object.entries(val.afes)) {
          if (i != afeIndex) {
            if (
              v.afenum == line.afenum.afenum &&
              v.ccone_code == cc_num.costcode1 &&
              v.cctwo_code == cc_num.costcode2 &&
              v.ccthree_code == cc_num.costcode3
            ) {
              isDuplicate = true;
            }
          }
        }
      });
      if (isDuplicate) {
        this.$toasted.show('Duplicates Chain', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      const {
        ccthree_code,
        ccone_code,
        afe_num,
        cctwo_code,
      } = this.editAfeChainLine;
      const vsid_data = service[0].same_coding;
      const ccid_data = this.afes
        .filter(
          (v) =>
            v.afenum == afe_num &&
            v.ccone_code == ccone_code &&
            v.cctwo_code == cctwo_code &&
            v.ccthree_code == ccthree_code &&
            vsid_data.includes(v.vsid)
        )
        .map((val) => val.service_ccid);

      let send = JSON.stringify({
        service_ccid: ccid_data,
        projectid: this.projectid,
        pwcaid: line.afenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        companyid: this.companyId,
        vid: this.vendorId,
        percentage: this.percentage,
      });
      this.$http
        .put('/wts/create/costcode_and_assign_service/update/service_cc', send)
        .then((res) => {
          vsid_data.forEach((vsid) => {
            let find = this.afes.findIndex(
              (x) =>
                x.vsid == vsid &&
                x.ccthree_code == ccthree_code &&
                x.ccone_code == ccone_code &&
                x.cctwo_code == cctwo_code &&
                x.afenum == afe_num
            );
            Vue.set(this.afes, find, {
              afenum: line.afenum.afenum,
              ccone_code: cc_num.costcode1,
              cctwo_code: cc_num.costcode2,
              ccthree_code: cc_num.costcode3,
              percentage: this.percentage,
              service_ccid: this.afes[find].service_ccid,
              apid: this.afes[find].apid,
              vsid: vsid,
              pwcaid: line.afenum.items[0].pwcaid,
            });
          });
          this.createdUnassignedServiceLine = 0;
          this.disableEdits = 0;
        })
        .catch((err) => { });
    },
    async editPresetAddAfeData(line, service, serviceIndex, afeIndex, afes) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      let cc_num = this.CC_data;
      let isDuplicate = false;
      service.forEach((val) => {
        for (let [i, v] of Object.entries(val.afes)) {
          if (i != afeIndex) {
            if (
              v.afenum == line.afenum.afenum &&
              v.ccone_code == cc_num.costcode1 &&
              v.cctwo_code == cc_num.costcode2 &&
              v.ccthree_code == cc_num.costcode3
            ) {
              isDuplicate = true;
            }
          }
        }
      });
      if (isDuplicate) {
        this.$toasted.show('Duplicates Chain', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      const {
        ccthree_code,
        ccone_code,
        afe_num,
        cctwo_code,
        pwcaid,
      } = this.editAfeChainLine;
      const vsid_data = service[0].same_coding;

      vsid_data.forEach((vsid) => {
        var find = this.afes.findIndex(
          (x) =>
            x.afenum == afe_num &&
            x.ccone_code == ccone_code &&
            x.cctwo_code == cctwo_code &&
            x.ccthree_code == ccthree_code &&
            x.vsid == vsid
        );
        Vue.set(this.afes, find, {
          afenum: line.afenum.afenum,
          ccone_code: cc_num.costcode1,
          cctwo_code: cc_num.costcode2,
          ccthree_code: cc_num.costcode3,
          percentage: this.percentage,
          service_ccid: cc_num.service_ccid,
          vsid: vsid,
          pwcaid: pwcaid,
        });

        this.disableEdits = 0;
        this.editedAfeLine = [];
      });
    },
    addNewUnassignedAfeLine(afe, isPreviousCoding) {
      this.search = '';
      if (!this.fake_vendor) {
        this.$axios
          .get(
            '/vendor/get/service_chains/' +
            this.companyId +
            '/' +
            this.projectid +
            '/' +
            afe.id +
            '/' +
            1 +
            '/?vid=' +
            this.vendorId
          )
          .then((response) => {
            if (response.data.result.length > 0) {
              this.percentage = 100;
              if (!isPreviousCoding) {
                this.createdUnassignedServiceLine = afe;
                var result = this.lodash.groupBy(
                  response.data.result,
                  'afenum'
                );
                this.createAfeData = Object.keys(result).map((key) => ({
                  afenum: key,
                  items: result[key],
                }));
                this.recentAfeDays = 0;
                this.AfeData = this.createAfeData;
                this.createAfenum = this.createAfeData[0];
                this.createAfe = 1;
                this.disableEdits = 1;
                this.createAfeData[0];
                this.cc1CreateData = response.data.cost_code_chain_data;
                this.CC_data = this.cc1CreateData[0];
                this.oldCcData = this.cc1CreateData[0];
                this.createAfe = 1;
              } else {
                // Adding Previous afes and cost codes to all services before invoice submission;
                const cost_codes = response.data.cost_code_chain_data[0];
                const line = response.data.result[0];
                this.createAfenum = {
                  afenum: line.afenum,
                  items: [{ pwcaid: line.pwcaid, afenum: line.afenum }],
                };
                this.CC_data = {
                  afenum: line.afenum,
                  companyid: this.companyId,
                  costcode1: cost_codes.costcode1,
                  costcode2: cost_codes.costcode2,
                  costcode3: cost_codes.costcode3,
                };
                this.addAfeToAllInvoiceServices(false);
              }
            } else {
              this.createdServiceLine = 0;
              this.$toasted.show('No AFE or Cost Center / WellS Found', {
                type: 'error',
                duration: '3000',
              });
              return;
            }
          })
          .catch((err) => { });
      }
    },
    addNewAfeLine(afe, service) {
      if (!this.fake_vendor) {
        this.$axios
          .get(
            '/vendor/get/service_chains/' +
            this.companyId +
            '/' +
            this.projectid +
            '/' +
            service.id +
            '/' +
            1 +
            '/?vid=' +
            this.vendorId
          )
          .then((response) => {
            if (response.data.result.length > 0) {
              this.createdServiceLine = afe;
              this.createdServiceLineItem = service;
              this.percentage = 100;
              var result = this.lodash.groupBy(response.data.result, 'afenum');
              this.createAfeData = Object.keys(result).map((key) => ({
                afenum: key,
                items: result[key],
              }));
              this.recentAfeDays = 0;
              this.AfeData = this.createAfeData;
              this.createAfenum = this.createAfeData[0];
              this.createAfe = 1;
              this.disableEdits = 1;
            } else {
              this.createdServiceLine = 0;
              this.$toasted.show('No AFE or Cost Center / WellS Found', {
                type: 'error',
                duration: '3000',
              });
              return;
            }
            this.cc1CreateData = response.data.cost_code_chain_data;
            this.CC_data = this.cc1CreateData[0];
            this.oldCcData = this.cc1CreateData[0];
            this.createAfe = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    addPresetAfeUnassignedLine(service, line) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      var cc_num = [];
      cc_num = this.CC_data;
      cc_num['afenum'] =
        line == undefined ? this.createAfenum.afenum : line.afenum.afenum;
      if (
        this.afes.filter(
          (e) =>
            e.afenum === cc_num.afenum &&
            service.id === e.serviceid &&
            e.ccone_code === cc_num.costcode1 &&
            e.cctwo_code === cc_num.costcode2 &&
            e.ccthree_code === cc_num.costcode3
        ).length > 0
      ) {
        this.$toasted.show(
          'AFE or Cost Center / Well Duplicate For a line item',
          { type: 'error', duration: '3000' }
        );
        return;
      }
      this.afes.push({
        serviceid: service.id,
        vsid: service.vsid,
        afenum: cc_num.afenum,
        ccone_code: cc_num.costcode1,
        cctwo_code: cc_num.costcode2,
        ccthree_code: cc_num.costcode3,
        percentage: this.percentage,
        service_ccid: service.vsid,
        pwcaid: this.createAfenum.items[0].pwcaid,
      });
      this.createdUnassignedServiceLine = [];
      this.disableEdits = 0;
    },
    groupAssigneAfes(service, line) {
      let send = JSON.stringify({
        serviceid: service.id,
        projectid: this.projectid,
        afe_data: line,
        companyid: this.companyId,
        vid: this.vendorId,
        vsid: service.vsid,
      });

      this.$http
        .post('/wts/create/costcode_and_assign_service/group_afes/', send)
        .then((response) => {
          if (response.status === 200) {
            this.$toasted.show('Successful Import.', {
              type: 'success',
              duration: '3000',
            });
            this.afes.push(...response.data.addedServices);
          }
        })
        .catch((error) => {
          this.$toasted.show('Error Adding cost Code', {
            type: 'error',
            duration: '3000',
          });
        });
    },
    removeHighlightService() {
      this.highlightServices = [];
    },
    highlightService(service) {
      this.highlightServices = [];
      this.highlightServices = service.map((val) => val.vsid);
    },
    addAfeUnassignedLine(service, line, find) {
      this.search = '';
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE or Cost Code or coding Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      var cc_num = [];
      cc_num = this.CC_data;
      let isDuplicate = false;
      cc_num['afenum'] = this.createAfenum.afenum;
      if (service.afes.length > 0) {
        service.afes.forEach((v) => {
          if (
            v.afenum == this.createAfenum.afenum &&
            v.ccone_code == cc_num.costcode1 &&
            v.cctwo_code == cc_num.costcode2 &&
            v.ccthree_code == cc_num.costcode3
          ) {
            isDuplicate = true;
          }
        });
      }
      if (isDuplicate) {
        this.$toasted.show('Duplicates Chain', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      let service_data = this.serviceData.filter(
        (x, i) => this.serviceData[i].vsid == service.vsid
      );
      let send = JSON.stringify({
        serviceids: service_data,
        projectid: this.projectid,
        percentage: this.percentage,
        pwcaid: this.createAfenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        companyid: this.companyId,
        projectType: 1,
        vid: this.vendorId,
        afenum: this.createAfenum.afenum,
      });

      this.AddServiceToAFe(send);
    },
    addPresetAfeLine(afes, line) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      var cc_num = [];
      const service = afes[0];
      cc_num = this.CC_data;
      cc_num['afenum'] = this.createAfenum.afenum;
      if (
        service.afes.filter(
          (e) =>
            e.afenum === cc_num.afenum &&
            e.ccone_code === cc_num.costcode1 &&
            e.cctwo_code === cc_num.costcode2 &&
            e.ccthree_code === cc_num.costcode3
        ).length > 0
      ) {
        this.$toasted.show(
          'AFE or Cost Center / Well Duplicate For a line item',
          { type: 'error', duration: '3000' }
        );
        return;
      }
      this.afes.push({
        serviceid: service.id,
        vsid: this.createdServiceLineItem.vsid,
        afenum: cc_num.afenum,
        ccone_code: cc_num.costcode1,
        cctwo_code: cc_num.costcode2,
        ccthree_code: cc_num.costcode3,
        percentage: this.percentage,
        service_ccid: this.createdServiceLineItem.vsid,
        pwcaid: this.createAfenum.items[0].pwcaid,
      });
      this.createdServiceLine = 0;
      this.disableEdits = 0;
    },
    addAfeLine(afes, line) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE or Cost Code or coding Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }
      this.addAfeUnassignedLine(afes[0]);
    },
    AddServiceToAFe(send) {
      this.$http
        .post('/wts/create/costcode_and_assign_service', send)
        .then((response) => {
          if (response.status === 200) {
            this.$toasted.show('Successful Import.', {
              type: 'success',
              duration: '3000',
            });
            this.afes.push(...response.data.addedServices);
            this.createdUnassignedServiceLine = 0;
            this.disableEdits = 0;
          }
        })
        .catch((error) => {
          this.$toasted.show('Error Adding cost Code', {
            type: 'error',
            duration: '3000',
          });
        });
    },
    async addPresetAfeToAllInvoiceServices(isGroup, groupService) {
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      let cc_num = this.CC_data;
      cc_num['afenum'] = this.createAfenum.afenum;
      if (
        this.afes.filter(
          (e) =>
            e.afenum === cc_num.afenum &&
            e.ccone_code === cc_num.costcode1 &&
            e.cctwo_code === cc_num.costcode2 &&
            e.ccthree_code === cc_num.costcode3
        ).length > 0
      ) {
        this.$toasted.show(
          'AFE or Cost Center / Well Duplicate For a line item',
          { type: 'error', duration: '3000' }
        );
        return;
      }

      this.createdServiceLine = 0;
      this.disableEdits = 0;
      this.lineLoading = true;
      const selected_services = isGroup
        ? this.serviceData.filter((val) =>
          groupService.same_coding.includes(val.vsid)
        )
        : this.serviceData;
      for (var i = 0; i < selected_services.length; i++) {
        var service = selected_services[i];
        this.afes.push({
          vsid: service.vsid,
          afenum: cc_num.afenum,
          ccone_code: cc_num.costcode1,
          cctwo_code: cc_num.costcode2,
          ccthree_code: cc_num.costcode3,
          percentage: this.percentage,
          service_ccid: service.vsid,
          pwcaid: this.createAfenum.items[0].pwcaid,
        });
      }
      this.disableEdits = 0;
      this.lineLoading = false;
    },
    // addPreviousCoding() {
    //   this.addNewUnassignedAfeLine(this.serviceData[0], true);
    // },
    async addAfeToAllInvoiceServices(isGroup, groupService) {
      this.search = '';
      if (this.percentage > 100 || this.percentage <= 0) {
        this.$toasted.show('AFE or Cost Code or coding Percentage Error', {
          type: 'error',
          duration: '3000',
        });
        return;
      }

      let cc_num = this.CC_data;
      cc_num['afenum'] = this.createAfenum.afenum;
      if (this.afes.filter((e) => e.afenum === cc_num.afenum).length > 0) {
        this.$toasted.show(
          'AFE or Cost Center / Well Duplicate For a line item',
          { type: 'error', duration: '3000' }
        );
        return;
      }
      let send = JSON.stringify({
        serviceids: isGroup
          ? this.serviceData.filter((val) =>
            groupService.same_coding.includes(val.vsid)
          )
          : this.serviceData,
        projectid: this.projectid,
        pwcaid: this.createAfenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        companyid: this.companyId,
        percentage: this.percentage,
        projectType: 1,
        afenum: this.createAfenum.afenum,
        vid: this.vendorId,
      });
      await this.AddServiceToAFe(send);

      this.createdServiceLine = 0;
      this.disableEdits = 0;
      this.lineLoading = false;
    },
    removeAllPresetAfe: function (service) {
      this.afes = this.afes.filter((x) => x.vsid !== service.vsid);
    },
    removeAllAfe: function (service) {
      const data = {
        service_ccid: this.afes
          .filter((val) => val.vsid === service.vsid)
          .map((val) => val.service_ccid),
        projectid: this.vwref.projectid,
        vid: this.vendorId,
      };
      this.$axios
        .post('/vendor/delete/service_ap', data)
        .then(() => {
          this.afes = this.afes.filter((x) => x.vsid !== service.vsid);
          this.editAfe = 0;
          this.createAfe = 0;
          this.disableEdits = 0;
          this.createdServiceLine = [];
          this.createdUnassignedServiceLine = [];
        })
        .catch(() => { });
    },
    cancelAfe: function () {
      this.editAfe = 0;
      this.createAfe = 0;
      this.disableEdits = 0;
      this.createdServiceLine = [];
      this.createdUnassignedServiceLine = [];
    },
  },
  computed: {
    filtered() {
      if (
        this.CC_data &&
        this.oldCcData &&
        this.oldCcData.costcodechainid !== this.CC_data.costcodechainid
      ) {
        this.search = '';
        this.oldCcData = this.CC_data;
      }
      if (this.search) {
        this.search = this.search.toUpperCase();
        const query = new RegExp(`^${this.search}`);
        const combined_data = this.cc1CreateData.filter((x) =>
          query.test(
            `${x.costcode1}-${x.costcode2}-${x.costcode3}`.toUpperCase()
          )
        );
        const cc1 = this.cc1CreateData.filter((x) =>
          query.test(`${x.costcode1}`.toUpperCase())
        );
        const cc2 = this.cc1CreateData.filter((x) =>
          query.test(`${x.costcode2}`.toUpperCase())
        );
        const cc3 = this.cc1CreateData.filter((x) =>
          query.test(`${x.costcode3}`.toUpperCase())
        );
        if (combined_data.length > 0) return combined_data;
        if (cc1.length > 0) return cc1;
        if (cc2.length > 0) return cc2;
        if (cc3.length > 0) return cc3;
      } else {
        return this.cc1CreateData;
      }
    },
    paginated() {
      return this.filtered;
      // return this.filtered;
    },
    hasNextPage() {
      return true;
    },
    filterAfeAttachedCostCodes() {
      if (this.oldCcData.costcodechainid !== this.CC_data.costcodechainid) {
        this.search = '';
        this.oldCcData = this.CC_data;
      }
      if (this.search) {
        this.search = this.search.toUpperCase();
        const query = new RegExp(`^${this.search}`);
        const combined_data = this.cc1Data.filter((x) =>
          query.test(
            `${x.costcode1}-${x.costcode2}-${x.costcode3}`.toUpperCase()
          )
        );
        const cc1 = this.cc1Data.filter((x) =>
          query.test(`${x.costcode1}`.toUpperCase())
        );
        const cc2 = this.cc1Data.filter((x) =>
          query.test(`${x.costcode2}`.toUpperCase())
        );
        const cc3 = this.cc1Data.filter((x) =>
          query.test(`${x.costcode3}`.toUpperCase())
        );
        if (combined_data.length > 0) return combined_data;
        if (cc1.length > 0) return cc1;
        if (cc2.length > 0) return cc2;
        if (cc3.length > 0) return cc3;
      } else {
        return this.cc1Data;
      }
    },
    afeAttachedCostCodes() {
      return this.filterAfeAttachedCostCodes;
    },
    subtotal: function () {
      return this.serviceData.reduce(function (total, item) {
        return (
          total +
          item.quantity * item.pu -
          (item.discount / 100) * item.quantity * item.pu
        );
      }, 0);
    },
    total_tax_percentage: function () {
      return this.invoiceTaxes
        .reduce((acc, val) => {
          return (acc +=
            (this.calculateTax(val.tax_name, false) * 100) / this.lineSubtotal);
        }, 0)
        .toFixed(6);
    },
    tax_total: function () {
      return this.lineSubtotal * Number(this.total_tax_percentage / 100);
    },
    qptax_total: function () {
      return (
        this.quickpaylinesubtotal * Number(this.total_tax_percentage / 100)
      );
    },
    quickpaysubtotal: function () {
      return this.subtotal - (this.quickpay / 100) * this.subtotal;
    },
    quickpaylinesubtotal: function () {
      return this.lineSubtotal - (this.quickpay / 100) * this.lineSubtotal;
    },
    qpgstsubtotal: function () {
      return this.qptax_total;
    },
    qpdiscount: function () {
      return (this.quickpay / 100) * this.subtotal;
    },
    lineSubtotal: function () {
      const subtotal = this.serviceData.reduce((total, item) => {
        if (item.tax) {
          return (
            total +
            item.quantity * item.pu -
            (item.discount / 100) * item.quantity * item.pu
          );
        } else {
          return total + 0;
        }
      }, 0);
      return subtotal;
    },
    totalinvoice: {
      get: function () {
        return Number(this.subtotal) + Number(this.tax_total);
      },
      set: function () {
        return Number(this.subtotal) + Number(this.tax_total);
      },
    },
    qptotalinvoice: function () {
      return this.quickpaysubtotal + this.qpgstsubtotal;
    },
    unassigned: function () {
      var unassigned = [];
      if (this.isSortingEnabled) {
        this.sortData(this.sortedValue[0], this.sortedValue[1]);
      }
      for (var i = 0; i < this.serviceData.length; i++) {
        var filtered = this.afes.filter(
          (x) => x.vsid == this.serviceData[i].vsid
        );
        if (filtered.length == 0) {
          unassigned.push(this.serviceData[i]);
        }
      }
      return unassigned;
    },
    merged: function () {
      this.highlightServices = [];
      var dict = {};
      this.serviceData.forEach((service) => {
        var filtered = this.afes.filter((x) => x.vsid == service.vsid);
        service.afes = [];
        filtered.forEach((afe) => {
          service.afes.push({
            afenum: afe.afenum,
            ccone_code: afe.ccone_code,
            cctwo_code: afe.cctwo_code,
            ccthree_code: afe.ccthree_code,
            percentage: afe.percentage,
            pwcaid: afe.pwcaid,
          });
        });
      });

      this.serviceData.forEach((service) => {
        if (service.afes.length > 0) {
          dict[JSON.stringify(service)] = [];
        }
      });

      this.serviceData.forEach((service) => {
        service.afes.forEach((afe) => {
          dict[JSON.stringify(service)] = dict[JSON.stringify(service)].concat(
            afe
          );
        });
      });

      var dictAfe = {};

      for (var service in dict) {
        let key = JSON.stringify(dict[service]);
        if (!(key in dictAfe)) {
          dictAfe[key] = [JSON.parse(service)];
        } else {
          dictAfe[key] = dictAfe[key].concat(JSON.parse(service));
        }
      }

      var temp = [];
      for (var service in dictAfe) {
        dictAfe[service].forEach((val, i) => {
          val['show_afe'] = false;
          if (i === 0) val['isTop'] = true;
          val['same_coding'] = dictAfe[service].map((val) => val.vsid);
          if (i === dictAfe[service].length - 1) {
            const combined_total = dictAfe[service].reduce((acc, val) => {
              return acc + Number(val.total.replace(',', ''));
            }, 0);
            val['combined_total'] = combined_total;
            val['show_afe'] = true;
            val['isTop'] = i === 0 ? true : false;
          }
        });
        temp.push(dictAfe[service]);
      }
      return temp.flat(Infinity).map((val) => [val]);
    },
  },
  mounted: async function () {
    let token = this.$session.get('jwt');
    var decoded = jwt_decode(token);
    this.preServiceData.length = 0;
    if (!this.fake_vendor) {
      await this.$axios.get('/vendor/get/profile').then((response) => {
        this.vendorDetails = response.data;
        this.usersName = response.data.fname + ' ' + response.data.lname;
        this.hasVendorData = true;
      });
      this.vendorId = decoded.id;
      await this.$http
        .get('/get/vendor/avatar/' + decoded.id)
        .then((response) => {
          this.vendorAvatar = response.data.avatar;
        })
        .catch((error) => {
          console.log('Error getting avatar ' + error);
        });

      await this.$axios
        .get('/vendor/get/allowed_companies')
        .then((response) => {
          this.companyData = response.data.result;
          this.companyId = this.companyData[0];
          this.hasCompanyData = true;
        });
    } else {
      await this.$axios
        .get('/vendor/get/profile/' + this.fake_vendor.vid)
        .then((response) => {
          this.vendorDetails = response.data;
        });

      await this.$http
        .get('/get/vendor/avatar/' + this.fake_vendor.vid)
        .then((response) => {
          this.vendorAvatar = response.data.avatar;
        })
        .catch((error) => {
          console.log('Error getting avatar ' + error);
        });

      await this.$axios
        .get('/vendor/get/allowed_companies/' + this.fake_vendor.vid)
        .then((response) => {
          this.companyData = response.data.result;
        });
    }
    this.getAllInvoices();
  },
};
</script>

<style>
.highlightService {
  background-color: #1e7e34 !important;
  opacity: 0.6;
  color: white !important;
}

.afeBorder {
  border-bottom: 2px solid red;
}

.isTop {
  border-top: 1.02px solid red;
}

#v-select1 .vs__actions {
  align-items: center;
}

.show-comment {
  font-size: 12px;
  margin-top: 3px;
  background-color: sandybrown;
  width: 90px;
  border-radius: 4px;
  color: white;
}

.add-coding {
  border-radius: 4px;
  color: white;
  border-color: transparent;
  width: 90px;
}

.add-coding:hover {
  border-radius: 4px;
  color: #1e7e34;
  background-color: white;
  border-color: #1e7e34;
}

.container {
  cursor: grab;
  overflow: auto;
}

.loader {
  text-align: center;
  color: #bbbbbb;
}

#editAfe {
  cursor: pointer;
  padding: 0.75rem 0.2rem 0.75rem 0.2rem !important;
}

#afe-filter {
  display: flex;
  justify-content: end;
  padding: 10px;
}

#comment {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: white;
  font-size: small;
  font-weight: bold;
  border-color: transparent;
}

#comment {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: white;
  font-size: small;
  font-weight: bold;
  border-color: transparent;
}

#comment:hover {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: #1e7e34;
  font-size: small;
  background-color: white;
  font-weight: bold;
  border-color: #1e7e34;
}

#total-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#total-table td {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: smaller;
  font-size: medium;
  font-family: 'proxima-nova';
  font-weight: 700;
}

#total-table th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  background-color: #4b9955;
  color: white;
}

@media only screen and (max-width: 980px) {
  #total-row {
    display: block;
  }
}

#Service-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#Service-table #Service-table-tr td,
#Service-table #Service-table-tr th {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: smaller;
}

#Service-table #Service-table-tr th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: dimgrey;
  color: white;
}

#Service-table-child {
  font-size: inherit;
  background-color: white;
  /* padding: 10px; */
}

#Service-table-child th {
  font-size: smaller;
}

@media only screen and (max-width: 1200px) {

  #Service-table table,
  #Service-table thead,
  #Service-table tbody,
  #Service-table th,
  #Service-table td,
  #Service-table tr {
    display: block;
  }

  #Service-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #Service-table tr {
    border: 1px solid #ccc;
  }

  #Service-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  #Service-table td:before {
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bolder;
  }

  #Service-table-child td:nth-of-type(1):before {
    content: 'AFE or Cost Center / Well: ';
  }

  #Service-table-child td:nth-of-type(2):before {
    content: 'Cost Code Chain: ';
  }

  #Service-table-child td:nth-of-type(3):before {
    content: 'Coding Percentage %: ';
  }

  #Service-table-child td:nth-of-type(4):before {
    content: 'Action';
  }

  #Service-table-tr td:nth-of-type(1):before {
    content: 'Service Name: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(2):before {
    content: 'Service Code: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(3):before {
    content: 'Start- End date: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(4):before {
    content: 'UOM: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(5):before {
    content: 'Price/Unit: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(6):before {
    content: 'Discounted Price/Unit: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(7):before {
    content: 'Quantity: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(8):before {
    content: 'Discount %: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(9):before {
    content: 'Total: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(10):before {
    content: 'Tax: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }

  #Service-table-tr td:nth-of-type(11):before {
    content: 'Action: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
}

.vue-dropzone>.dz-preview .dz-error-message {
  opacity: 100;
  left: 5% !important;
  top: 135px;
  width: 90%;
}

.vue-dropzone>.dz-preview .dz-remove {
  opacity: 100;
  width: 85%;
}

#services-modal #dataTable thead tr {
  background-color: #464646;
  color: white;
}

#services-modal #dataTable thead tr th:nth-child(2) {
  width: 50%;
}

#services-modal #dataTable thead tr th:nth-child(3) {
  width: 21%;
}

#services-modal #dataTable thead tr th:nth-child(4) {
  width: 1%;
}

#services-modal #dataTable thead tr th:nth-child(5) {
  width: 1%;
}

#service-th th {
  background-color: #464646;
  color: white;
}

#date-picker-div {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 10px 0px;
  width: 100% !important;
}

#date-picker-div #date-picker {
  margin-right: 15px;
}

#date-picker-div span {
  margin-right: 10px;
  font-weight: bold;
}

#date-picker-div #date-picker input {
  font-size: 15.5px;
}

#date-picker-div #date-picker button {
  font-size: 12.5px;
}

.tax-table {
  width: 100%;
  border-spacing: 7px;
  border-collapse: separate;
}

.tax-action-th {
  width: 30px;
}
</style>
